import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DARK_MODE_OPTIONS } from 'angular-dark-mode';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ResizableModule } from 'angular-resizable-element';
import { FacebookModule } from 'ngx-facebook';
import * as Hammer from 'hammerjs';

import { SortPipe } from './pipes/sort.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { IframePipe } from './pipes/iframe.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';

import { ApiService } from './backoffice/services/api.service';
import { PagesService } from './backoffice/services/pages.service';
import { MessagingService } from './services/messaging.service';
import { AuthService } from './services/auth.service';

import { LazyLoadingImagesDirective } from './directives/lazy-loading-images/lazy-loading-images.directive';
import { StopPropagationDirective } from './directives/stop-propagation/stop-propagation.directive';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { CartComponent } from './components/cart/cart.component';
import { PopinComponent } from './components/popin/popin.component';
import { GridComponent } from './components/grid/grid.component';
import { CookiesComponent } from './components/cookies/cookies.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { FastCartComponent } from './components/fast-cart/fast-cart.component';
import { DisplayComponent } from './components/display/display.component';
import { QuickviewComponent } from './components/quickview/quickview.component';
import { MoreinformationComponent } from './components/moreinformation/moreinformation.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { PageComponent } from './components/pages/page.component';
import { VideoComponent } from './components/video/video.component';
import { TileActionComponent } from './components/tile-action/tile-action.component';
import { NewtileComponent } from './components/newtile/newtile.component';
import { JwplayerComponent } from './components/jwplayer/jwplayer.component';
import { DemoPopinComponent } from './components/demo-popin/demo-popin.component';
import { SocialshareComponent } from './components/socialshare/socialshare.component';
import { VideoNodeComponent } from './components/videonode/videonode.component';
import { VideoContainerComponent } from './components/videocontainer/videocontainer.component';
import { VideoheaderComponent } from './components/videoheader/videoheader.component';
import { AdblockerTurnOffComponent } from './components/adblocker-turn-off/adblocker-turn-off.component';
import { FooterComponent } from './components/footer/footer.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { DarkModeToggle } from './components/dark-mode-toggle/dark-mode-toggle.component';
import { CptrTuileComponent } from './components/cptr-tuile/cptr-tuile.component';
import { BoxComponent } from './components/box/box.component';
import { ChiffreComponent } from './components/chiffre/chiffre.component';
import { CptrComponent } from './components/cptr/cptr.component';
import { MenuComponent } from './components/menu/menu.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { LoginComponent } from './components/login/login.component';
import { PasswordComponent } from './components/password/password.component';
import { PasswordForgotComponent } from './components/password-forgot/password-forgot.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { EmailConfirmationComponent } from './components/email-confirmation/email-confirmation.component';
import { environment } from '../environments/environment';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
    press: { time: 200 }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FeedbackComponent,
    CartComponent,
    PopinComponent,
    GridComponent,
    CookiesComponent,
    NotificationsComponent,
    FastCartComponent,
    SafeHtmlPipe,
    SafeUrlPipe,
    IframePipe,
    DisplayComponent,
    QuickviewComponent,
    MoreinformationComponent,
    DemoPopinComponent,
    CarouselComponent,
    VideoComponent,
    PageComponent,
    NewtileComponent,
    SafePipe,
    TileActionComponent,
    SortPipe,
    ReplacePipe,
    JwplayerComponent,
    SocialshareComponent,
    VideoContainerComponent,
    VideoNodeComponent,
    VideoheaderComponent,
    AdblockerTurnOffComponent,
    FooterComponent,
    UserMenuComponent,
    StopPropagationDirective,
    DarkModeToggle,
    CptrTuileComponent,
    SanitizeHtmlPipe,
    BoxComponent,
    ChiffreComponent,
    CptrComponent,
    MenuComponent,
    SidebarComponent,
    RegistrationComponent,
    LoginComponent,
    PasswordComponent,
    LazyLoadingImagesDirective,
    PasswordForgotComponent,
    PasswordResetComponent,
    EmailConfirmationComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    MatSliderModule,
    BrowserAnimationsModule,
    IvyCarouselModule,
    ClipboardModule,
    DragDropModule,
    ResizableModule,
    BrowserModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    HammerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    // FacebookModule.forRoot()
  ],
  providers: [
    PagesService,
    MessagingService,
    ApiService,
    AuthService,
    {
      provide: [HAMMER_GESTURE_CONFIG, DARK_MODE_OPTIONS],
      useValue: {
        darkModeClass: 'my-dark-mode',
        lightModeClass: 'my-light-mode'
      },
      useClass: MyHammerConfig,
    },
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
