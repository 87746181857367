import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config: Config;

  isOffline() {
    return this.config.offline;
  }

  get endpoint(): string {
    return this.config.endpoint;
  }

  extension(path: string) {
    if (this.config.offline) {
      path += this.config.extension ? this.config.extension : '';
    }
    return path;
  }

  constructor() {
    this.config = new Config(environment.endpoint);
  }
}
