<div class="btn-edges" [@openClose]="isOpen ? 'open' : 'closed'">
  <div class="logos">
    <div class="div_close_feedback" (click)="feedback()">
      <p class="feedback_title">Feedback</p>
      <img src="../../assets/img/close_feedback.svg" alt="close_feedback" class="close_feedback">
      <!-- <img src="../../assets/img/close_button.svg" alt="close_button" class="close_button"> -->
    </div>
  </div>
  <div class="full-width" [formGroup]="formGroup">
    <mat-form-field class="full-width">
      <p>Faites nous part de vos impressions, suggestions et questions sur cette version beta. Nous vous en remercions d’avance.
        <br>Ou utilisez l'E-mail hello[at]mediashow.fr
        </p>
      
      <textarea matInput name="" [formControl]="feedbackField" [formControl]="feedbackField" id="text" cols="50"
        rows="10" required></textarea>
      <!-- <mat-error *ngIf="feedbackField.invalid">{{ getErrorMessage(feedbackField) }}</mat-error> -->
    </mat-form-field>
    <p *ngIf="texAreaLen < 10" class="msg-error">Votre message doit contenir 10 caractères minimum.</p>
    <p style="color:  #39c4d8 ;text-align: center;" *ngIf="feedbackSent">Feedback envoyé avec succès ! Merci pour votre retour.</p>
  </div>
  <button class="btn-false" *ngIf="!feedbackField.valid && feedbackSent != true">Envoyer</button>
  <button class="btn-true" (click)="writefile(feedbackField.value)" *ngIf="feedbackField.valid && feedbackSent != true">Envoyer</button>
</div>
