import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { interval, Subscription, Observable } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class KPI {
  constructor(origin: string) {
    this.origin = origin;
    this.nbClick = 0;
    this.timePassed = 0;
    this.actions = [];
    this.tabOnDisplay = false;
    this.popInClosed = false;
    this.popInOkIsDone = false;
    this.popInHowTo = false;
    this.popInAccessVideo = false;

    this.closedBefore10 = false;
    this.desactivatedAdblocker = false;
    this.tutoOkisDone = false;
    this.tutoHowto = false;
    this.isWindowInactive = false;
    this.nbTimesUserCome = 0;
    this.actualPage = "";
    this.actualPageID = "";
  }

    public origin!: string;
    public tabOnDisplay!: boolean;
    public nbClick!: number;
    public timePassed!: number;
    public actions!: string[];
    public popInClosed!: boolean;
	public popInOkIsDone!: boolean;
	public popInHowTo!: boolean;
	public popInAccessVideo!: boolean;


    public closedBefore10 !: boolean; // users leave the page in less than 10 seconds
    public desactivatedAdblocker !: boolean; // users that desactivate their adblockers.
    public tutoOkisDone !: boolean; //Clicking in tuto "Ok is Done" button
    public tutoHowto !: boolean; //Clicking on tuto "How to" button
    public isWindowInactive !: boolean; //if page is inactive.
    public nbTimesUserCome !: number; // Number of time the same user come in a week.
    public actualPage !: string; // String or Id of the actual page.
    public actualPageID !: string // ID of the actual page.
}

@Injectable({
  providedIn: 'root'
})

export class KPIService {
  constructor(private httpClient: HttpClient) {}

    private endpoint = environment.endpoint + '/kpi';
    private options = {
      headers: {
        accept: 'application/json'
      }
    };

    public addKPIData(kpi: KPI) {
      return this.httpClient.put<KPI>(this.endpoint, kpi, this.options);
    }

    public getKPIData(): Observable<KPI[]> {
      return this.httpClient.get<KPI[]>(this.endpoint, this.options);
    }
}
