export class Config {
  url: string;
  port?: number ;
  path?: string ;
  production: boolean;
  offline?: boolean;
  extension?: string | undefined;
  get endpoint(): string {
    return `${this.url}${this.path ? '/' + this.path : ''}`;
  }

  constructor(endpoint: string, production?: boolean, path?: string | undefined, extension?: string | undefined) {
    this.url = endpoint;
    this.path = path;
    this.extension = extension;
    this.production = production || false;
  }
}
