import { Component, EventEmitter, Input, OnInit, Output, HostListener } from '@angular/core';
import { faEnvelope as icon } from '@fortawesome/free-regular-svg-icons';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Feedback, FeedbackService } from 'src/app/services/feedback.service';
import { timer, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          visibility: 'visible',
          marginLeft: '11%'
        })
      ),
      state(
        'closed',
        style({
          marginRight: '-120%',
          visibility: 'hidden'
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')])
    ])
  ]
})

export class FeedbackComponent implements OnInit {
  icon = icon;
  @Input() isOpen;
  @Output() isClose = new EventEmitter<any>();
  feedbackSent: boolean;
  name = 'Feedback';
  formGroup: FormGroup;
  public FeedbackData: Feedback = new Feedback(window.location.hostname);
  private initialTimeInSec: number;
  public texAreaLen;
  @Input() pagetitle: string | undefined;

  constructor(
    private feedbackservice: FeedbackService
  ) {
    this.feedbackSent = false;
    this.formGroup = new FormGroup({
      feedbackField: new FormControl('', [
        Validators.required,
        Validators.minLength(10)
      ])
    })
  }

  ngOnInit(): void {
    this.countTime();
    this.texAreaLen = 0;
  }

  public countTime(): void {
    const startDate = new Date();

    timer(1000, 1000).pipe(map((x: number) => {
      const newDate = new Date(startDate.getTime());
      newDate.setSeconds(newDate.getSeconds() + x);
      return newDate;
    })
    )
      .subscribe(t => {
        if (this.FeedbackData.timepassed === 0) {
          this.initialTimeInSec = t.getTime() / 1000;
        }
        this.FeedbackData.timepassed = t.getTime() / 1000;
      });
  }

  public PushFeedbackData(): void {
    const FeedbackObservables: Observable<{ [f: string]: any }>[] = [];
    FeedbackObservables.push(this.feedbackservice.addFeedbackData(this.FeedbackData));
    forkJoin(FeedbackObservables).subscribe(resp => {
      this.feedbackSent = true;
    },
    _err => {
      alert('Une erreur s\'est produite veuillez réessayer dans quelque instants');
    });
  }

  writefile(textfile: string) {
    this.FeedbackData.timepassed = this.FeedbackData.timepassed - this.initialTimeInSec;
    this.FeedbackData.message = textfile;
    this.FeedbackData.actualpage = this.pagetitle;

    this.PushFeedbackData();
  }

  getErrorMessage(control: AbstractControl): string {
    // Don't say anything if control doesn't exist, or is valid
    if (!control || control.valid) {
      return 'fdfsdf';
    }

    // Required always comes first
    if (control.hasError('required')) {
      return 'Votre message doit contenir 10 caractères minimum.';
    }

    // Default general error message
    return 'Votre message doit contenir 10 caractères minimum.';
  }

  get feedbackField(): AbstractControl {
    return this.formGroup.get('feedbackField');
  }

  feedback(): void {
    this.isOpen = !this.isOpen;
    this.isClose.emit(this.isOpen);
  }
  
  @HostListener('window:keyup', ['$event'])
  keyEventOnError(event: KeyboardEvent) {
    this.texAreaLen = document.getElementById('text') as HTMLInputElement;
    this.texAreaLen = this.texAreaLen.value.length;
  }
}
