<div *ngIf="tile.backgroundBlurred" class="backgroundImage">
</div>

<!-- embed -->
<ng-container *ngIf="isEmbedded()">
  <ng-container *ngIf="!isSocialNetworkEmbed(); else socialNetworkEmbed">
    <div class="embedded {{embeddedType}} advertizer tile-size" [innerHtml]="selfEmbedded | safe:'html'"></div>
  </ng-container>

  <ng-template #socialNetworkEmbed>
    <figure class="embedded {{embeddedType}} advertizer tile-size">
      <iframe [srcdoc]="selfEmbedded | safe:'html'" class="nested-tile-size">
      </iframe>
    </figure>
  </ng-template>
</ng-container>

<!-- Edit -->
<div *ngIf="tile.type === TileType.EDIT" [id]="'Main' + tile.customID" class="edit">
  <div class="datahtml" *ngIf="tile.type === TileType.EDIT"></div>
</div>

<!-- Surcouche -->
<ng-container #boxes>

</ng-container>

<!-- iframe -->
<div class="ov"
  *ngIf="tile.type === TileType.IFRAME || tile.type === TileType.EMBEDDED ||( tile.type === TileType.ADVERTIZER && !tile.thFileName)"
  style="top: 66%; width: 100%; height: 15%;">

</div>
<div class="ov"
  *ngIf="tile.type === TileType.IFRAME || tile.type === TileType.EMBEDDED ||( tile.type === TileType.ADVERTIZER && !tile.thFileName)"
  style="margin-top:10%;margin-bottom:10%; height: 70%; left: 66%; width: 33%;">
</div>
<div class="ov"
  *ngIf="tile.type === TileType.IFRAME || tile.type === TileType.EMBEDDED || ( tile.type === TileType.ADVERTIZER && !tile.thFileName)"
  style="margin-top:10%; bottom: 90%; width: 100%; height: 10%;">
</div>

<iframe
  *ngIf="tile.type === TileType.IFRAME"
  [src]="tile.url | safe:'resourceUrl'"
  class="content tile-size"
  (load)="IframeLoad()"
  style="overflow: hidden;"
  frameborder="0"
></iframe>


<!-- image -->
<div
  #backgroundTile
  (click)="openTarget(tile)"
  [style.display]="tile.type === TileType.IMAGE ? 'flex' : 'none'"
  [ngClass]="{'image': true, 'contain': backgroundBlurred, 'cover': !backgroundBlurred}"
></div>

<!-- video -->
<!-- Pour ajouter la lecture avec expand (à débugger), uncomment les mouseevent qui appellent playInTile -->
<div
  *ngIf="tile.type === TileType.VIDEO"
  (mouseleave)="playInTile(false)"
  class="video"
>
  <!-- overlay : bouton sur vidéo -->
  <div
    *ngIf="showPreview || external"
    (mouseenter)="playInTile(true)"
    class="ov"
  >
    <div
      *ngIf="(!external && showPreview) && !quickviewOpened && this.context != PlaybackContext.CART"
      [ngClass]="playButtonStyle"
      (mouseleave)="playInTile(true)"
      (mouseenter)="playInTile(false)"
      (click)="play()"
      class="bg-play2"
    >
      <i class="fas fa-play"></i>
    </div>
  </div>

  <!-- <video id="vi" #videoplayer *ngIf="!tile.useJWPlayer; else jwplayer" id="{{tile.customID | replace:'-' : '_'}}-video"  [width]="tile.size!.width" [height]="tile.size!.height" controls [src]="tile.fileName | safe:'url'"></video> -->
  <!-- <ng-template #jwplayer> -->
  <app-jwplayer #videoplayer *ngIf="context != PlaybackContext.CART" (action)="action($event)" [play]="playVideo"
    [width]="tile.size!.width" id="{{tile.customID | replace:'-' : '_'}}-video"
    [playerId]="tile.customID | replace:'-' : '_'" [height]="tile.size!.height" controls [thumb]="tile.thFileName"
    [src]="tile.fileName" [tile]="tile" [lockInTile]="inTilePlaying" (endedInTile)="cancelExpand(true)"
    appStopPropagation></app-jwplayer>
  <!-- </ng-template> -->
</div>

<!-- Afficher le nom -->
<div class="details">
  <span>{{tile.title}}</span>
</div>

<div *ngIf="tile.type === TileType.ADVERTIZER && tile.isYoutubeEmbed" style="height:100%;width:100%;position:relative">
  <img [src]="tile.thFileName" style="position:absolute;height: 100%;width: 100%;" />
  <div style="position:absolute;height: 100%;width: 100%;display: flex;justify-content: center;align-items: center;">
    <div style="padding: 1rem;background:white;border-radius:100%;cursor:pointer;"  class="play">
      <i class="fas fa-play"></i>
    </div>
  </div>
  <!-- <img src="../../assets/img/play.png" alt="play" class="play" (click)="play()" *ngIf="tile.type === TileType.ADVERTIZER && tile.thFileName && tile.fileName"> -->
</div>

