import { AuthService } from './../../services/auth.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  @Output() reset = new EventEmitter<void>();
  public email:string = "";
  public password:string = "";
  public remember:boolean = false;
  public error : string;
  constructor(private authService:AuthService) {}

  ngOnInit(): void {}
  login(){
    this.authService
      .login({
        Email: this.email,
        Password: this.password,
        remember: this.remember,
      })
      .then((user) => {
        if(!user) return;
        this.error = '';
        this.close.emit();
      })
      .catch((err) => {
        console.log({ err });
        this.error =
          err.error.status == 404
            ? 'Adresse ou mot de passe incorrecte'
            : "une erreur s'est produite veuillez reesayer plus tard";
      });
  }
  resetOpen(){
    console.log("reset open")
    this.close.emit()
    this.reset.emit()

  }
}
