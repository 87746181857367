import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Notification } from './../types';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public subNotifications = new Subject<Notification[]>();
  public notifications: Notification[] = [];

  constructor() {}

  addNotification(notif: Notification): void {
    this.notifications.push({ id: Date.now().toString(), ...notif });
    this.updateNotifications();
  }

  removeNotification(id: string): void {
    this.notifications.forEach((n, i) => {
      if (n.id === id) {
        this.notifications.splice(i, 1);
        this.updateNotifications();
      }
    });
  }

  private updateNotifications(): void {
    this.subNotifications.next(this.notifications);
  }
}
