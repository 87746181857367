<div class="box">
  <div class="box-login">
    <p class="login">
      Se connecter
    </p>
  </div>
  <div class="all-logo">
    <a class="logo">
      <div class="fab fa-google fa-1x"></div>
    </a>
    <a class="logo">
      <div class="fab fa-facebook-f fa-1x"></div>
    </a>
    <a class="logo">
      <div class="fab fa-linkedin-in fa-1x"></div>
    </a>
  </div>
  <form>

    <div class="text">
      <label *ngIf="error.length > 0">{{error}}</label>
      <input class="text-area" placeholder="Email" type="email" name="email" [(ngModel)]="email">
      <input class="text-area" placeholder="Mot de passe" type="password" name="password" [(ngModel)]="password">
      <label class="check">
        <input type="checkbox" name="remember" [(ngModel)]="remember">
        <span class="checkmark"></span>
        <p class="stay">Rester connecté</p>
      </label>
    </div>
  </form>

  <button type="submit" class="continue" (click)="login()">Continuer</button>
  <a class="link" (click)="resetOpen()">Mot de passe oublié ?</a>
</div>
