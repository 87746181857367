import { NavDirection } from './../../enums/direction';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterContentInit, AfterViewInit, ChangeDetectorRef,
  Component, ContentChildren, EventEmitter, HostBinding, HostListener, OnInit, Output
  , ViewChild
} from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { CartStatus, Tile } from 'src/app/types';
import { faTimesCircle as icon } from '@fortawesome/free-regular-svg-icons';
import { PlaybackContext } from 'src/app/models/playback';

import { DisplayComponent } from '../display/display.component';

import { GridViewService } from 'src/app/services/gridView.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  animations: [
    trigger('openCloseCart', [
      state(
        'openCart',
        style({
          marginBottom: 0
        })
      ),
      state(
        'closedCart',
        style({
          marginBottom: '-188px'
        })
      ),
      transition('openCart => closedCart', [animate('0.5s')]),
      transition('closedCart => openCart', [animate('0.5s')])
    ])
  ]
})
export class CartComponent implements OnInit, AfterViewInit, AfterContentInit {
  NavDirection = NavDirection;
  @Output() selectionChange = new EventEmitter<NavDirection>();
  @ContentChildren('items') items;
  PlaybackContext = PlaybackContext

  popinDimension: { height: number, width: number } = { height: 456, width: 729 };
  public icon = icon;
  public tiles: Tile[] = [];
  public current: Tile;
  public cartStatus: CartStatus = { isOpen: false, tileID: null };
  public isOpenCart = true;
  public cvideodimension: { height: number, width: number };
  @ViewChild(DisplayComponent) display: DisplayComponent;

  @HostBinding('class.opened') get opened(): boolean { return this.cartStatus.isOpen; }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ): void {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  constructor(public gridViewService: GridViewService, private cartService: CartService, private changeDetecor: ChangeDetectorRef) {
    // Get Cart content
    this.tiles = this.cartService.tiles.reverse();
    this.current = null;

    // Subscribe to next cart updates
    this.cartService.cart.subscribe((tiles) => {
      this.tiles = tiles.reverse();
    });

    this.cartService.cartStatus.subscribe((res) => {
      this.cartStatus = res;
      this.tiles.forEach((t, i) => {
        if (t.customID === res.tileID) {
          this.select(this.tiles[i]);
        }
      });
    });
  }

  ngOnInit(): void {
    /* this.resizeVideo(); */
    document.documentElement.style.setProperty('--cart-width', '1200px');
    document.documentElement.style.setProperty('--cart-height', '700px');
    document.documentElement.style.setProperty('--cart-pourcentage', '0%');
    document.documentElement.style.setProperty('--cart-margin-top', '0%');
    document.documentElement.style.setProperty('--cart-width', '100%');
    document.documentElement.style.setProperty('--cart-height', '100%');


    setTimeout(() => {
      this.resizeVideo();
    }, 200)
  }

  ngAfterContentInit(): void { }

  ngAfterViewInit(): void { }

  toggle(): void {
    if (this.cartStatus.isOpen === true) {
      // this.deletePlayer();
      this.display.player.setAudio(true);
    }
    this.cartStatus.isOpen = !this.cartStatus.isOpen;
  }

  close(): void {
    this.cartService.close();
    if (window.innerWidth < 900) {
      document.getElementById('cart').style.display = 'none';
    }

    setTimeout(() => {
      this.current = null;
    }, 400); // same timeout as animation
  }

  select(tile: Tile): void {
    this.current = tile;
    setTimeout(() => {
      this.resizeVideo();
    }, 200)
  }

  resizeVideo() {
    if (this.current !== undefined) {
      this.display.player.setAudio(true)
      const tilewidth = this.current.size.title.match(/\d+/g)[0]
      const tileheight = this.current.size.title.match(/\d+/g)[1]
      const ratioed = parseInt(tileheight) / parseInt(tilewidth);

      if (this.current.type === 'video') {
        if (window.innerWidth > 900) {
          if (ratioed === 2) {
            document.documentElement.style.setProperty('--cart-width', `${this.popinDimension.height}px`);
            document.documentElement.style.setProperty('--cart-height', `${this.popinDimension.width}px`);
            document.documentElement.style.setProperty('--cart-pourcentage', '40%');
          } else {
            document.documentElement.style.setProperty('--cart-width', '78%');
            document.documentElement.style.setProperty('--cart-pourcentage', '12%');
          }
        } else {
          const videowidth = window.innerWidth - 2 * (window.innerWidth * 5 / 100);
          if (ratioed === 2) {
            document.documentElement.style.setProperty('--cart-width', `${videowidth}px`);
            document.documentElement.style.setProperty('--cart-height', '100%');
            document.documentElement.style.setProperty('--cart-pourcentage', '6%');
          } else if (window.innerWidth < 900) {
            document.documentElement.style.setProperty('--cart-width', '100%');
            document.documentElement.style.setProperty('--cart-height', `${this.popinDimension.height}px`);
            document.documentElement.style.setProperty('--cart-margin-top', '25%');
            document.documentElement.style.setProperty('--cart-pourcentage', '2%');
          }
        }
      } else {
        document.documentElement.style.setProperty('--cart-width', '100%');
        document.documentElement.style.setProperty('--cart-height', '100%');
        document.documentElement.style.setProperty('--cart-pourcentage', '0%');
        document.documentElement.style.setProperty('--cart-margin-top', '0%');
      }
    }
  }

  selectItem(navDirection: NavDirection): void {
    let selectedIndex = this.tiles.findIndex(t => t.customID === this.current.customID);
    if (navDirection === NavDirection.PREV) {
      selectedIndex = (selectedIndex - 1 >= 0 ? selectedIndex - 1 : this.tiles.length - 1);
    } else {
      selectedIndex = (selectedIndex + 1 < this.tiles.length ? selectedIndex + 1 : 0);
    }
    this.select(this.tiles[selectedIndex]);
  }

  toggleCart(): void {
    this.isOpenCart = !this.isOpenCart;
  }

  goto(navDirection: NavDirection): void {
    this.changeDetecor.detectChanges();
    this.selectionChange.emit(navDirection);
  }
}
