<div
  [ngClass]="{'columns is-vcentered is-centered': !pip && !popin && !header, 'customvideos is-vcentered is-centered': pip || popin || header}">
  <div
    [ngClass]="{'column has-text-centered': !pip && !popin && !header, 'customvideo has-text-centered': pip || popin || header}">
    <!-- <li (click)="emit(TileActionName.EXTERNAL_LINK)" class="ticket"><span></span><i class="fa fa-external-link-alt"></i></li> -->
    <div *ngIf="tile?.type !== 'embedded' && header === false" class="flag flagURL" (click)="goto(tile, $event)">
      <div class="fa fa-external-link-alt"></div>
      <!-- <fa-icon [icon]="fa-external-link-alt"" class="fa-lg"></fa-icon>-->
    </div>
    <div *ngIf="tile?.type !== 'embedded' && player && player.popin && !isMobile" class="flag flagPIP"
      (click)="player.togglePip(true)">
      <div class="fa fa-photo-video"></div>
    </div>
    <!-- affiche embed -->
    <ng-container *ngIf="tile?.type === 'embedded'">
      <div [innerHTML]="tile.embedded | iframe | safeHtml" class="embedded"></div>
      <div class="buttons_embedded">
        <div>
          <img src="../../../assets/img/mute.png" alt="mute">
        </div>
        <div>
          <img src="../../../assets/img/full-screen.png" alt="full_screen" class="full_screen">
        </div>
      </div>
    </ng-container>
    <!-- {{tile?.embedded}} -->
    <!-- embed youtube -->
    <ng-container *ngIf="tile?.type === 'advertizer'">
      <div [innerHTML]="tile.embedded | iframe | safeHtml" class="embedded"></div>
      <!-- <div class="buttons_embedded">
        <div>
          <img src="../../../assets/img/mute.png" alt="mute">
        </div>
        <div>
          <img src="../../../assets/img/full-screen.png" alt="full_screen" class="full_screen">
        </div>
      </div> -->
    </ng-container>

    <!-- affiche video -->
    <ng-container *ngIf="tile?.type === 'video'" class="ng_container_video">
      <!-- <video id="{{tile.customID | replace:'-' : '_'}}-video" [width]="tile.size!.width" [height]="tile.size!.height" controls autoplay [src]="tile.fileName | safe:'url'" class="video"></video> -->
      <app-jwplayer #videoplayer [play]="playVideo" (endedVideo)=videoEnded() [isDisplaying]="playVideo"
        [isCart]="isCart" id="{{tile.customID | replace:'-' : '_'}}-video"
        [playerId]="'display-' + tile.customID | replace:'-' : '_'" [itemId]="tile.customID" controls
        [thumb]="tile.thFileName" [src]="tile.fileName" [popin]="popin" [pip]="pip" [cart]="cart" [header]="header"
        [tile]="tile" [resumePosition]="resumePosition" [resumeAction]="resumeAction"></app-jwplayer>
    </ng-container>

    <!-- affiche image, banner ou iframe -->
    <ng-container *ngIf="
        tile?.type === 'image' ||
        tile?.type === 'banner' ||
        tile?.type === 'iframe'
      "><iframe id="iframe-display" [src]="tile.url | safeUrl" frameborder="0" width="100%" height="100%"></iframe>
    </ng-container>
  </div>
</div>
