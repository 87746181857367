import { Component, OnInit } from '@angular/core';
import { SocialshareService } from '../../services/socialshare.service';
import { faFacebookSquare, faTwitterSquare, faWhatsappSquare, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FullPage } from '../../backoffice/models/full-page';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-socialshare',
  templateUrl: './socialshare.component.html',
  styleUrls: ['./socialshare.component.scss']
})
export class SocialshareComponent implements OnInit {
  showModal = false;
  url: string;
  page: FullPage;
  shareText = encodeURIComponent('Le kiosque des nouveaux médias (replay TV, podcast et séries) et médias classiques (news TV, radios) ainsi qu\'un showroom des marques personnalisé.');
  urlToShare : string;

  faFacebook = faFacebookSquare;
  faTwitter = faTwitterSquare;
  faLinkedin = faLinkedinIn;
  faWhatsapp = faWhatsappSquare;
  copied = false;

  constructor(private modalService: SocialshareService, private clipboard: Clipboard) { }

  ngOnInit(): void {
    this.urlToShare = location.href;
    this.modalService.sharedMessage.subscribe(data => {
      this.showModal = data.showModal;

      if ('page' in data) {
        this.page = data.page;
        if ('customID' in data.page) {
          return this.url = encodeURIComponent(`${this.urlToShare}`);
        }
      }

      if (this.page && 'tile' in data) {
        if (data.tile && 'url' in data.tile) {
          return this.url = encodeURIComponent(`${this.urlToShare}?page_id=${this.page.customID}&tile=${data.tile.customID}`);
        }
      }

      this.url = undefined;
    });
  }

  cancel(): void {
    this.modalService.nextMessage({ showModal: false, tile: undefined });
  }

  copyToClipboard(): void {
    const textTocopy = (document.getElementById('copyText') as HTMLInputElement).value;
    this.clipboard.copy(textTocopy);
    this.copied = true;
    setTimeout(function(): void { this.copied = false; }, 3000);
  }
}
