import { AuthService } from './../../services/auth.service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss'],
})
export class PasswordForgotComponent implements OnInit {
  constructor(private authService: AuthService) {}
  public email: string;
  public success: boolean;
  public error: boolean;
  @Output() close =  new EventEmitter<void>();
  ngOnInit(): void {}

  async sendReset() {
    console.log(this.email);

      this.authService
      .sendReset(this.email)
      .then((res) => {
        if (res) {
          this.success = true;
          this.error = false;
        }
        setTimeout(()=>this.close.emit(),4000)
      })
      .catch((err) => {
        this.error = true;
        this.success = false;
      });
  }
}
