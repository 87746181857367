import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class FootersService {
  private heart = new Subject<any>();
  constructor() { }

  public heartClick(): void {
    this.heart.next();
  }

  get heartClick$(): Subject<any> {
    return this.heart;
  }
}
