import { PagesService } from '../../backoffice/services/pages.service';
import { Component, OnInit, AfterViewInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Page } from '../../backoffice/models/page';
import { FullPage } from 'src/app/backoffice/models/full-page';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, AfterViewInit {
  @Input() page?: Page;
  public fullPage: FullPage;
  waves;
  max;
  currScroll = 0;
  currentPage;
  homePageId;
  index = 0
  offset = 0
  wavePerReq = 2
  
  constructor(
    private pageService: PagesService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.pageService.getPages().subscribe((pages: Page[]) => {
      this.homePageId = pages.filter(
        (p) => p.title === 'accueil'
      )[0]?.customID;
      this.route.params.subscribe((params) => {
        if (this.currentPage === null || this.currentPage === '' || params.customID == null)
          return this.getPage(this.homePageId);
        else return this.getPage(params.customID);
      });
    });

  }

  @HostListener('window:scroll', ['$event'])
  onStickyScroll(e): void {
    this.currScroll = window.pageYOffset + 500
    if (this.currScroll >= this.max){
      this.max = document.body.scrollHeight
      this.offset = this.wavePerReq * this.index;
      this.pageService.getWaves(this.fullPage.customID, this.offset, this.wavePerReq).then((data) => {
        if (data.data.length > 0){
          data.data.forEach ((el) =>{
            this.waves.push(el)
          })
          this.index+=1
        }
      })
  }
}

  ngAfterViewInit(): void {
    this.max = document.body.scrollHeight;
  }

  async getPage(id) {
    await this.pageService.getFullPage(id).subscribe((data) => {
      this.fullPage = data;
      this.waves = [];
      this.index = 0
      this.offset = this.wavePerReq * this.index;
      this.pageService.getWaves(id, this.offset, this.wavePerReq).then((data) => {
        this.max = document.body.scrollHeight;
        if (data.data.length > 0){
          data.data.forEach ((el) =>{
            this.waves.push(el)
          })
          this.index+=1
        }
      })
    });
  }
}
