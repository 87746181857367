import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PackeryOptions} from 'packery';
import {Wave} from '../backoffice/models/wave';
import {waveZoom} from '../models/waveZoom';
import {GridLayout} from '../enums/grid-layout';
import {Size} from "../backoffice/models/size";
import {PagesService} from "../backoffice/services/pages.service";

@Injectable({
  providedIn: 'any',
})
export class ZoomService {
  public zoomValue = 60;
  public zoomValueVague: waveZoom = { zoom: 40, waveId: '' };
  public zoom = new BehaviorSubject<number>(this.zoomValue);
  public zoomVague = new BehaviorSubject<waveZoom>(this.zoomValueVague);
  public zoomMax = 100;
  public zoomMin = 20;
  public zoomStep = 20;
  public nbColonne = 0;

  public options: PackeryOptions = {
    gutter: 30,
  };

  /* test commit */

  constructor(
    private pageService: PagesService,
  ) {
    this.nbColonne = 0;
  }

  public changeZoom(value: number): void {
    this.zoomValue = value;
    this.zoom.next(value);
  }

  public zoomPlus(): void {
    if (this.zoomValue < this.zoomMax) {
      this.changeZoom(this.zoomValue + this.zoomStep);
    }
  }

  public zoomMoins(): void {
    if (this.zoomValue > this.zoomMin) {
      this.changeZoom(this.zoomValue - this.zoomStep);
    }
  }

  public changeZoomVague(value: waveZoom): void {
    this.zoomValueVague = value;
    this.zoomVague.next(value);
  }

  public zoomPlusVague(
    wave: Wave,
    gridlayout: GridLayout,
    value: waveZoom
  ): void {
    if (value.zoom < this.zoomMax) {
      this.nbColonne -= 1;
      this.setZoomTile(gridlayout, wave, wave.gutterSize);
    }
  }

  public zoomMoinsVague(
    wave: Wave,
    gridlayout: GridLayout,
    value: waveZoom
  ): void {
    if (value.zoom > this.zoomMin) {
      // value.zoom += 10
      this.nbColonne += 1;
      this.setZoomTile(gridlayout, wave, wave.gutterSize);
    }
  }

  setZoomTile(gridLayout: GridLayout, wave: Wave, gutter: number): void {
    if (gutter === undefined) {
      gutter = 30;
    }
    let calculZoom;
    let zoomRectangle;

    if (wave.isActive) {
      const waveId = wave.customID;
      const FirstTile = wave.tiles[0];

      FirstTile.size = this.pageService.tileSizes.find((s) => s.customID === FirstTile.sizeID) ?? new Size();

      const size = FirstTile.size.width || 300;

      let waveWidth = window.innerWidth * 0.8;
      let withOfGutters;
      let padding;
      let largeurVaguePadding;
      let nbColonne;

      if (gridLayout === GridLayout.ONESQUARE) {
        if (window.innerWidth < 900) {
          waveWidth = window.innerWidth * 0.97;
          padding = 0.2 * waveWidth;
          largeurVaguePadding = waveWidth - padding;
          calculZoom = (largeurVaguePadding / size) * 100 * 0.99;
          zoomRectangle = calculZoom / 100 + 0.8;
          document.documentElement.style.setProperty(
            '--zoomRectangle',
            zoomRectangle
          );
        } else {
          nbColonne = 2 + this.nbColonne;
          withOfGutters = gutter * (nbColonne - 1);
          padding = 0.6 * waveWidth;
          largeurVaguePadding = waveWidth - padding;
          calculZoom =
            (largeurVaguePadding / (nbColonne * size + withOfGutters)) *
            100 *
            0.99;
        }
      }
      if (gridLayout === GridLayout.FOURSQUARE) {
        if (window.innerWidth < 900) {
          nbColonne = 2;
          waveWidth = window.innerWidth;
          withOfGutters = gutter;
          padding = 0.2 * waveWidth;
          largeurVaguePadding = waveWidth - padding;
          calculZoom = (largeurVaguePadding - withOfGutters) / (nbColonne * size) * 100 * 0.98
          zoomRectangle = calculZoom / 100 + 0.8;
          document.documentElement.style.setProperty(
            '--zoomRectangle',
            zoomRectangle
          );
        } else {
          nbColonne = 4 + this.nbColonne;
          withOfGutters = gutter * (nbColonne - 1);
          padding = 0.26 * waveWidth;
          largeurVaguePadding = waveWidth - padding;
          calculZoom = (largeurVaguePadding - withOfGutters) / (nbColonne * size) * 100 * 0.99
        }
      }
      if (gridLayout === GridLayout.NINESQUARE) {
        if (window.innerWidth < 900) {
          nbColonne = 2;
          waveWidth = window.innerWidth;
          padding = 0.2 * waveWidth;
          largeurVaguePadding = waveWidth - padding;
          withOfGutters = 3 * gutter;
          calculZoom = (largeurVaguePadding - withOfGutters) / (nbColonne * size) * (100 * 0.98)
          zoomRectangle = calculZoom / 100 + 0.8;
          document.documentElement.style.setProperty(
            '--zoomRectangle',
            zoomRectangle
          );
        } else {
          nbColonne = 5 + this.nbColonne;
          withOfGutters = gutter * (nbColonne - 1);
          padding = 0.12 * waveWidth;
          largeurVaguePadding = waveWidth - padding;
          calculZoom = (largeurVaguePadding - withOfGutters) / (nbColonne * size) * (100 * 0.99)
        }
      }
      const zoomObject: waveZoom = { zoom: calculZoom, waveId: waveId };
      this.changeZoomVague(zoomObject);
    }
    return calculZoom;
  }
}
