import { Playback, PlaybackContext } from '../../models/playback';
import { ZoomService } from '../../services/zoom.service';
import { PagesService } from '../../backoffice/services/pages.service';
import { SafePipe } from '../../pipes/safe.pipe';
import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  HostListener,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TileType } from '../../enums/type-type';
import { PlaybackService } from 'src/app/services/playback.service';
import { QuickviewService } from 'src/app/services/quickview.service';
import { VideoviewService } from 'src/app/services/videoview.service';
import { Tile } from '../../types';
import { Size } from '../../backoffice/models/size';
import { Wave } from '../../backoffice/models/wave';
import { BoxComponent } from '../box/box.component';

@Component({
  selector: 'app-newtile',
  templateUrl: './newtile.component.html',
  styleUrls: ['./newtile.component.scss'],
  providers: [SafePipe],
})
export class NewtileComponent implements OnInit, AfterViewInit, OnChanges {
  @HostBinding('class') CenterClass = '';
  @ViewChild('videoplayer') videoplayer!: any;

  @Input() context: PlaybackContext;
  @Input() wave: Wave;
  @Input() tile: Tile;
  @Input() tileZoom: number;
  @Input() lockSize: number | undefined;

  @Output() closed = new EventEmitter<boolean>();
  @Output() playerEvent = new EventEmitter<Playback>();

  embeddedScript: any;
  socials = ['instagram', 'facebook', 'tiktok', 'twitch', 'twitter'];
  containImage = false;
  tmpWidth;
  tmpHeight;
  public show = false;
  public pip = false;
  TileType = TileType;
  embeddedType: string;
  size: Size;
  showPreview = true;
  quickviewOpened = false;
  PlaybackContext = PlaybackContext;
  sitesWithParent = ['twitch'];
  selfEmbedded?: string;
  isMobile = false;
  original: boolean;
  playVideo: boolean;
  playButtonStyle: string = 'scale60';
  backgroundBlurred = false;
  resumeOnscreen: boolean;

  get external(): boolean {
    return this.videoplayer ? this.videoplayer.playingExternal : false;
  }

  private timerIn: number;
  private timerExpand: number;
  private inTilePlaying = false;
  public expanded: boolean = false;

  constructor(
    private el: ElementRef,
    private playback: PlaybackService,
    private pageService: PagesService,
    private zoomService: ZoomService,
    private quickviewService: QuickviewService,
    private videoviewService: VideoviewService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    (window as any).test = this;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tile']?.currentValue) {
      this.show = this.tile !== undefined;
      if (this.tile) {
        if (this.tile.type === TileType.VIDEO) {
          this.showPreview = true;
        }
        // if (!this.lockSize && this.pageService.tileSizes) {
        //   this.original = true;
        //   this.size = this.pageService.tileSizes.find(
        //     (s) => s.customID === this.tile.sizeID
        //   );
        //   this.tile.size = { ...this.size };
        // } else {
        //   this.tile.size = new Size();
        // }
        if (this.tile.type === TileType.IFRAME) {
          this.sitesWithParent.forEach((site) => {
            if (this.tile.url.includes(site)) {
              if (this.tile.url.match(/parent/gi)) {
                this.tile.url = this.tile.url.replace(
                  /parent=([^&]*)/gi,
                  `parent=${window.location.hostname}`
                );
              } else {
                this.tile.url += `&parent=${window.location.hostname}`;
              }
            }
          });
          this.tile.url += '&mute=1';
        }
        this.prepareEmbedded().then((hasEmbedded) => {
          if (hasEmbedded) {
            this.selfEmbedded = this.tile?.embedded!;
            if (!this.tile.isYoutubeEmbed) {
              const wallpaper = (
                this.tile!.thFileName || this.tile!.fileName
              )?.replace(/ /gi, '%20');
              if (wallpaper) {
                // find the index of src=" in this.selfEmbedded
                const index = this.selfEmbedded.indexOf('src="');
                // find the index of " after src="
                const index2 = this.selfEmbedded.indexOf('"', index + 5);
                // get the url between src=" and "
                const url = this.selfEmbedded.substring(index + 5, index2);
                // replace the url with the url + &mute=1
                this.selfEmbedded = this.selfEmbedded.replace(url, wallpaper);
              }
            }
          } else {
            this.selfEmbedded = '';
          }
          if (this.el.nativeElement) {
            this.setImage();
          }
        });
      }
    }

    if (changes['tileZoom']?.currentValue) {
      if (this.tile) {
        this.playButtonStyle = `scale${this.tileZoom}`;
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (this.tile.type == TileType.VIDEO) {
      const rect = this.videoplayer.el.nativeElement.getBoundingClientRect();

      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      if (topShown && bottomShown && this.resumeOnscreen) {
        this.resumeOnscreen = false;
        setTimeout(() => this.videoplayer.player.play(), 500);
        return;
      }
      if (
        this.videoplayer.player.getState() == 'playing' &&
        !(topShown && bottomShown)
      ) {
        this.videoplayer.player.pause();
        this.resumeOnscreen = true;
      }
    }
  }
  pause(): void {
    if (this.tile.type === TileType.VIDEO) {
      this.videoplayer.pause();
    }
  }

  isMedia(tile?: Tile): boolean {
    return (
      tile!.type === TileType.IMAGE ||
      tile!.type === TileType.VIDEO ||
      tile!.type === TileType.ADVERTIZER
    ); // || tile.thFileName || tile.fileName;
  }

  isSocialNetworkEmbed(): boolean {
    return this.tile.embedded?.startsWith('<blockquote', 0);
  }

  isEmbedded(): boolean {
    return (
      (this.tile.type === TileType.EMBEDDED ||
        this.tile.type === TileType.ADVERTIZER) &&
      (this.tile.embedded?.length || this.hasThumbnail()) && !this.tile.isYoutubeEmbed
    );
  }

  setIndex(z): void {
    this.el.nativeElement.style.setProperty('z-index', z);
  }

  //
  hasThumbnail(): boolean {
    return !!this.tile.fileName || !!this.tile.thFileName;
  }
  setImage(): void {
    setTimeout(() => {
      if (this.isMedia(this.tile)) {
        var wallpaper;
        wallpaper = (this.tile!.thFileName || this.tile!.fileName)
          ?.replace(/ /gi, '%20');

        if (wallpaper) {
          this.el.nativeElement.style.setProperty(
            '--background-image',
            `url(${wallpaper})`
          );
          // const img = new Image();
          // img.src = wallpaper;
        } else {
          this.el.nativeElement.style.setProperty('--background-image', 'none');
        }
      } else {
        this.el.nativeElement.style.setProperty('--background-image', 'none');
      }
    });
  }

  async prepareEmbedded(): Promise<boolean> {
    let hasEmbedded = false;
    if (this.tile) {
      if (this.tile.embedded && this.tile.type === TileType.EMBEDDED) {
        const regex = /(src=)(".")/;
        const script = this.tile.embedded.match(regex);
        if (script && script.length > 1) {
          const node = document.createElement('script');
          const src = script[2].replace(/"/g, '');
          if (src.match(/(.)\s/) && src.match(/(.)\s/)!.length > 1) {
            node.src = src.match(/(.)\s/)![1];
          } else {
            node.src = src;
          }

          if (node.src.match('.js')) {
            const body = document.querySelector('body');
            if (body) {
              body.append(node);
            }
            hasEmbedded = true;
          }
        }
      } else if (
        this.tile.embedded &&
        this.tile.type === TileType.ADVERTIZER
      ) {
        const body = document.querySelector('body');
        const adv = document.createElement('div');
        // adv.setAttribute('style', 'transform : scale(--zoom-value, --zoom-value)');
        const node = document.createElement('script');
        adv.innerHTML = this.tile.embedded;
        adv.childNodes.forEach((n: ChildNode) => {
          const el = <Element>n;
          if (el.tagName === 'SCRIPT') {
            const src = el.getAttribute('src');
            if (src) {
              node.setAttribute('src', src);
            }
            if (el.innerHTML) {
              node.textContent = `setTimeout(() => {${el.innerHTML}})`;
            } else {
              if (n.textContent) {
                this.selfEmbedded += n.textContent;
              }
            }
          }
        });
        if (body) {
          body.append(node);
        }
        // }
        hasEmbedded = true;
      }
    }
    // console.log('embedd', this.selfEmbedded, this.tile);
    return hasEmbedded;
  }

  playYtEmbed(): void {
    // console.log(this.action(PlaybackContext.MAINVIEW))
    console.log('heeey');
    // console.log(this.tile);
  }

  play(): void {
    if (this.context !== PlaybackContext.CART) {
      if (this.videoplayer) {
        this.videoplayer.openPopin();
        this.videoplayer.preview.subscribe((preview) => {
          this.showPreview = preview;
        });
      }
    }
  }

  // When video expand thanks to mouse over the tile
  playInTile(value: boolean): void {
    if (
      this.context === PlaybackContext.GRID &&
      this.videoplayer &&
      !this.external
    ) {
      if (value) {
        this.timerIn = window.setTimeout(() => {
          if (!this.external) {
            this.setIndex(10);
            this.showPreview = false;
            this.playVideo = true;
            this.inTilePlaying = true;
            this.timerExpand = window.setTimeout(() => {
              if (
                this.inTilePlaying &&
                !this.expanded &&
                !this.showPreview &&
                !this.external
              ) {
                // this.tile.size.height *= 1.7;
                // this.tile.size.width *= 1.7;

                this.expanded = true;
                this.videoplayer.setAudio(true);
              }
            }, 0); // Timer to delay the expand animation
          }
        }, 1000); // Timer of the expand animation
      } else {
        clearTimeout(this.timerIn);
        clearTimeout(this.timerExpand);

        // if (this.inTilePlaying) {
        //   setTimeout(() => {
        //     this.playVideo = false; //
        //     this.showPreview = true; // comment to set play in tile after expand
        //     this.inTilePlaying = false; //
        //     if (this.expanded) {
        //       this.tile.size.height /= 1.7;
        //       this.tile.size.width /= 1.7;
        //       this.expanded = false;
        //       this.videoplayer.setAudio(false);
        //     }
        //     this.setIndex(1)
        //   }, 1000);
        // };
      }
    }
  }
  private cancelExpand(complete: boolean): void {
    this.inTilePlaying = false;

    if (complete) {
      this.playVideo = false;
      this.showPreview = true;
    }

    clearTimeout(this.timerIn);
    clearTimeout(this.timerExpand);
    if (this.expanded) {
      // this.tile.size.height /= 1.7;
      // this.tile.size.width /= 1.7;
      this.expanded = false;
    }
  }

  getPopin(): boolean {
    if (this.videoplayer) {
      if (this.videoplayer.popin) {
        return true;
      }
    }
    return false;
  }

  getPip(): boolean {
    if (this.videoplayer) {
      if (this.videoplayer.pip) {
        return true;
      }
      return this.getPopin();
    }
    return false;
  }

  closePopin(): void {
    if (this.videoplayer) {
      if (this.videoplayer.popin) {
        this.videoplayer.close();
        this.videoplayer.pause();
        // this.videoplaye
      }
    }
  }

  togglePip(value?: boolean): void {
    // En commentaire: technique pour réaliser un pip avec n'importe quelle vidéo, mais enlève le background de la tuile là où elle se trouvait
    // if (this.videoplayer && this.tile.useJWPlayer) {
    this.pip = true;
    // this.showPreview = false;
    // this.playVideo = true;
    this.videoplayer.togglePip(this.pip);
    this.videoplayer.setAudio(true);

    this.cancelExpand(false);

    this.videoplayer.preview.subscribe((preview) => {
      this.showPreview = preview;
    });

    // this.el.nativeElement.style.setProperty('z-index', 100);
  }

  openTarget(tile: Tile): void {
    if (this.videoplayer && this.videoplayer.nativeElement) {
      // ?
    }
  }

  openPopin(tile: Tile): void {
    if (this.videoplayer /* && this.videoplayer.nativeElement */) {
      this.videoplayer.openPopin();
      this.cancelExpand(false);
    }
  }

  action(event: Playback): void {
    if (this.context !== PlaybackContext.CART) {
      event.context = this.context;
      this.playerEvent.emit(event);
    }
  }

  replaceStyle(innerHtml: string): string {
    return innerHtml.replace(
      /style=".*"/g,
      `style="min-width:${this.tile?.size?.width}px"`
    );
  }

  componentRef: Array<ComponentRef<BoxComponent>> = [];
  @ViewChild('boxes', { read: ViewContainerRef })
  boxesContainer!: ViewContainerRef;

  ngAfterViewInit(): void {
    if (this.tile.type === TileType.EDIT) {
      this.el.nativeElement.querySelector('div.datahtml').innerHTML =
        this.tile.edit.html;
      this.el.nativeElement.style.backgroundColor = this.tile.edit.params;
    }

    if (TileType.IFRAME === this.tile.type) {
      if (this.getRootUrl(this.tile.url)) {
        this.el.nativeElement.querySelector('iframe').classList.add('mytiktok');
      }
    }

    if (TileType.ADVERTIZER === this.tile.type) {
      if (this.isSocialNetworkEmbed()) {

        // We wait to be sure that the iframe is already rendered
        setTimeout(() => {
          try {
            // Trix to remove the embed min-width and have a well adapted tile

            const iframeDocument = this.el.nativeElement
              .querySelector('figure')
              .querySelector('iframe')
              .contentDocument;

            iframeDocument.onreadystatechange = function () {
              if (iframeDocument.readyState === 'complete') {
                // The target is blockquote for TikTock and iframe for Insta
                // There is an iframe inside TikTok's blockquote so important to try first with blockquote
                const body = iframeDocument.body
                const target = body.querySelector('blockquote') ?? body.querySelector('iframe');

                target.style.minWidth = 0;
              }
            }
          } catch (e) {
            console.error(e);
          }
        }, 200)
      }
    }

    setTimeout(() => {
      new Promise<void>((resolve) => {
        if (this.tile.type === TileType.ADVERTIZER) {
          if (this.el.nativeElement.querySelector('blockquote') != null) {
            resolve();
          }
        }
      }).then(() => {
        if (this.tile.type === TileType.ADVERTIZER) {
          if (this.tile.isActive === true) {
            // on recupère l'élément de classe advertizer contenant notre AD
            const classElem = Array.from(
              document.getElementsByClassName(
                'advertizer'
              ) as HTMLCollectionOf<HTMLElement>
            );

            const classwave = document.querySelectorAll(
              '[class^=wave-container]'
            );
            for (const adv in classElem) {
              // const tilewidth = this.tile.size.width;
              // switch case permettant de modifié les éléménts en fonction de la valeur de zoom
              // setTimeout(() => {
              //   if (this.tile.embedded.search('class="tiktok-embed"') === -1) {
              //     classElem[adv].firstElementChild.setAttribute(
              //       'style',
              //       'width:' +
              //         getComputedStyle(classElem[adv].parentElement).width +
              //         '; height: ' +
              //         getComputedStyle(classElem[adv].parentElement).height +
              //         ';'
              //     );
              //     // classElem[adv].firstElementChild.firstElementChild.setAttribute("style", "width:"+getComputedStyle(classElem[adv].parentElement).width+"; height: "+getComputedStyle(classElem[adv].parentElement).height+";");
              //   }
              // }, 1000);
            }
          }
        }
      });
    }, 3000);

    // for (let i = 0; i < this.tile.SurCoucheInfo.length; i++) {
    //   const dynamicComponentFactory =
    //     this.componentFactoryResolver.resolveComponentFactory(BoxComponent);
    //   this.componentRef.push(
    //     this.boxesContainer.createComponent(dynamicComponentFactory)
    //   );
    //   let cssstyletamp = this.tile.SurCoucheInfo[i].style;
    //   // IMPORTANT : LA TAILLE ORIGINAL DE LA TUILE EST CALCULE A PARTIR DE SON Size TITRE => l'attribut size.width ne correspond pas a la bonne taille !!!!!!!!!!
    //   // cssstyletamp = cssstyletamp.replace(
    //   //   cssstyletamp.match(/\d+/g)[0] + 'px',
    //   //   (
    //   //     (Number(cssstyletamp.match(/\d+/g)[0]) * 100) /
    //   //     Number(this.tile.size.title.match(/\d+/g)[0])
    //   //   ).toFixed(2) + '%'
    //   // );
    //   this.componentRef[this.componentRef.length - 1].instance.SetTextContent =
    //     this.tile.SurCoucheInfo[i].textcontent;
    //   this.componentRef[
    //     this.componentRef.length - 1
    //   ].instance.SetStyleTransform =
    //     cssstyletamp + ' scale(' + this.tileZoom + ')'; // this.tile.SurCoucheInfo[i].style;
    //   this.componentRef[this.componentRef.length - 1].instance.SetSizeBox =
    //     this.tile.SurCoucheInfo[i].sizebox;
    //   this.componentRef[this.componentRef.length - 1].instance.SetTransform =
    //     true;
    //   // this.componentRef[this.componentRef.length - 1].instance.SetScale = this.tileZoom;
    // }
  }

  IframeLoad() {
    if (TileType.IFRAME === this.tile.type) {
      if (this.getRootUrl(this.tile.url)) {
        this.el.nativeElement.querySelector('iframe').style.transform =
          'scale(' +
          Number(
            parseFloat(this.playButtonStyle.replace('scale', '')).toFixed(2)
          ) /
            100 +
          ')';
      }
    }
  }

  ngOnInit(): void {
    if (this.tile.SurCoucheInfo === undefined) {
      this.tile.SurCoucheInfo = [];
    }
    if (this.tile.type === TileType.EDIT) {
      this.CenterClass = 'center';
    }
    if (TileType.IFRAME === this.tile.type && this.getRootUrl(this.tile.url)) {
      this.CenterClass = 'TikTokContainer';
    }
    this.backgroundBlurred = this.tile.backgroundBlurred;
    this.setIndex(1);
    if (window.innerWidth < 900) {
      this.isMobile = true;
    }
    if (this.lockSize && this.context !== PlaybackContext.GRID) {
      // if (this.tile) {
      //   this.tile.size.width = this.lockSize;
      //   this.tile.size.height = this.lockSize;
      // }
    }

    // this.tileZoom = this.zoomService.zoomValue

    // this.tmpWidth = this.tile.size.width;
    // this.tmpHeight = this.tile.size.height;

    this.quickviewService.tile.subscribe((tile) => {
      if (this.tile) {
        if (tile && tile.customID === this.tile.customID) {
          if (this.videoplayer) {
            this.quickviewOpened = true;
            this.showPreview = false;
            this.cancelExpand(false);
            this.videoplayer.preview.subscribe((preview) => {
              this.showPreview = preview;
            });
          }
        } else {
          this.quickviewOpened = false;
        }
      }
    });

    this.videoviewService.closedNode.subscribe((closed) => {
      if (this.tile && closed.tile.customID === this.tile.customID) {
        this.quickviewOpened = false;
        this.cancelExpand(false);
      }
    });
  }

  getRootUrl(url: string): boolean {
    return (
      url.toString().replace(/^(.*\/\/[^/?#]*).*$/, '$1') ===
      'https://www.tiktok.com'
    );
  }
}
