import { AuthService } from './../../services/auth.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  public email: string = '';
  public password: string = '';
  public passwordConfirm: string = '';
  public error: string = '';
  public success: string = '';
  @Output() OpenLogin = new EventEmitter<void>();
  constructor(private AuthService: AuthService) {}
  ngOnInit(): void {}
  closeRegister(event): void {
    if (event.target.classList.contains('overlay-register')) this.close.emit();
  }
  register() {
    if (this.password != this.passwordConfirm) {
      this.error = 'Votre mot de passe et sa confirmation ne correspondent pas';
      return;
    }
    this.AuthService.register({
      Email: this.email,
      Password: this.password,
      confirmPassword: this.passwordConfirm,
    })
      .then((response: any) => {
        this.AuthService.login({
          Email: this.email,
          Password: this.password,
          remember: false,
        }).then((user) => {
          this.success = 'Votre compte a été créer avec succès et un email de confirmation vous a ete envoyé';
        });
      })
      .catch((err) => {
        console.log({ err });
        if ((err.status = 409)) {
          this.error = 'Ce compte existe deja';
        }
      });
  }
  openLogin() {
    this.OpenLogin.emit();
  }
  closepoppin(){
    this.close.emit();
  }
}
