import { AuthService } from './services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faShareAlt as shareIcon, faExpand as expandIcon, faCompress as compressIcon } from '@fortawesome/free-solid-svg-icons';
import { PagesService } from './backoffice/services/pages.service';
import { AfterViewInit, Component, ElementRef, HostBinding, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NotificationsService } from './services/notifications.service';
import { ApiService } from './services/api.service';
import { environment } from 'src/environments/environment';
import { QuickviewService } from './services/quickview.service';
import { CartService } from './services/cart.service';
import { ZoomService } from './services/zoom.service';
import { Page } from './backoffice/models/page';
import { FullPage } from './backoffice/models/full-page';
import { map } from 'rxjs/operators';
import { KPI, KPIService } from './backoffice/services/kpi.sevice';
import { Subscription, timer, Observable, forkJoin } from 'rxjs';
import { NewtileComponent } from './components/newtile/newtile.component';
import { SocialshareService } from './services/socialshare.service';
import { GridViewService } from './services/gridView.service';
import { GridLayout } from './enums/grid-layout';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Wave } from './backoffice/models/wave';
import { MessagingService } from './services/messaging.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NavbarService } from './services/navbar.service';
import { GridComponent } from './components/grid/grid.component';
import { CompteurService } from './services/compteur.service';
import { PopupsService } from './services/popups.service';
import { StorageService } from './services/storage.service';
import { VideoheaderComponent } from './components/videoheader/videoheader.component'
import { MenuComponent } from './components/menu/menu.component';
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          marginLeft: '-182px',
        })
      ),
      state(
        'closed',
        style({
          right: '-57px',
        })
      ),
      transition('open <=> closed', [animate('0.5s')]),
    ]),
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'push-notification';
  message;
  // public videoheader : videaoheader;
  public expandIcon = expandIcon;
  public compressIcon = compressIcon;
  public pagetitle: string;
  public waves: Wave[] = [];
  public max = 1;
  public isOpenInfo = false;
  public showShare = false;
  public pages: Page[];
  public selectedPage?: FullPage;
  public menuIsHidden: boolean;
  public KPIData: KPI = new KPI(window.location.hostname);
  public subscription: Subscription;
  public intervalId: number;
  private initialTimeInSec: number;
  public gutter = 30;
  public isSticky = false;
  public isStickyFoot = false;
  public deferredPrompt;
  public isShow: boolean;
  public isTurnedOff: boolean;
  public showAdblockBar = true;
  public isUserMenuOpen: boolean;
  public prevScrollpos = window.pageYOffset;
  public isMobile: boolean = false;
  public gridLayout: GridLayout;
  GridLayout = GridLayout;
  DisplayVideoHeader: boolean = false;
  pageId: string;
  private _videoOff: boolean = false;
  public userAgent;
  public shareIcon = shareIcon;
  navbarEventReciever: any;
  navbarEventReciever2: any;
  isSidebarOpen: any;
  isMenuNavOpen: Boolean;
  gridUpdated;
  showFeedback: boolean = false;
  showNotification: boolean = true;
  showInfoMobile: boolean = false;
  showFastCart: boolean = false;
  displayPopups: boolean = true;

  @Input() titleAdblocker = 'Un petit détail  …..  qui change tout';
  @Input() titleAdblockerRepeated =
    'Encore ce petit détail  …..  qui change tout';
  @HostBinding('class.fixed') isFixed = false;
  @ViewChildren(NewtileComponent) tilesComponent: QueryList<NewtileComponent>;
  @ViewChild(NavbarComponent) navbar;
  @ViewChild(MenuComponent) NavMenuRef;

  private order = [
    '5f5b5400f6217b00012893a3',
    '5f732c5d0b063c00014863bf',
    '5fde209a56ac5a0001d71471',
    '5fde20bf56ac5a0001d71472',
    '5fde20d056ac5a0001d71473',
    '5fde20ef56ac5a0001d71474',
    '5fde213556ac5a0001d71475',
  ];

  constructor(
    public cptrService: CompteurService,
    private el: ElementRef,
    private notificationsService: NotificationsService,
    private apiService: ApiService,
    private quickviewService: QuickviewService,
    private cartService: CartService,
    public zoomService: ZoomService,
    private pageService: PagesService,
    private kpiService: KPIService,
    private route: ActivatedRoute,
    private AuthService:AuthService,
    private socialshareService: SocialshareService,
    public gridViewService: GridViewService,
    private messagingService: MessagingService,
    private popupsService: PopupsService,
    private router: Router,
    private navbarService: NavbarService,
    private storageService: StorageService,
    // private facebookService: FacebookService
  ) {
    this.gridViewService.gridLayout.subscribe((gridLayout: GridLayout) => {
      this.gridLayout = gridLayout;
    });

    this.pageService.getPages().subscribe((pages: Page[]) => {
      this.pages = pages.filter((p) => p.isActive === true && !p.deleted);

      this.route.queryParams.subscribe((param) => {
        this.pageId = this.pages[10].customID;
        if ('page' in param) {
          const finded = this.pages.find((p) => p.title === param.page);
          if (finded) {
            this.pageId = finded.customID;
          }
        } else if ('page_id') {
          const finded = this.pages.find((p) => p.customID === param.page_id);
          if (finded) {
            this.pageId = finded.customID;
          }
        }
        this.changePage(this.pageId);
      });
    });

    this.cartService.cartStatus.subscribe((cartStatus) => {
      if (cartStatus.isOpen === true) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    });

    this.quickviewService.tile.subscribe((tile) => {
      if (tile !== null) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    });

    if (environment.production === true) {
      setTimeout(() => {
        this.notificationsService.addNotification({
          texts: [
            'Avez-vous testé toutes les icones sur chaque message ? Faîtes des essais pour voir à quoi elles servent.',
          ],
          buttons: [{ text: 'fermer', class: 'chevron', action: 'close' }],
        });
      }, 120 * 1000);
      setTimeout(() => {
        this.notificationsService.addNotification({
          texts: [
            "Avez-vous testé le zoom ? C'est essentiel pour tout voir en 1 clin d'oeil. Choisissez la formule qui vous convient le mieux !",
          ],
          buttons: [
            { text: '100%', action: 'changeZoom(100)' },
            { text: '80%', action: 'changeZoom(80)' },
            { text: '60%', action: 'changeZoom(60)' },
            { text: '40%', action: 'changeZoom(40)' },
            { text: 'fermer', class: 'chevron', action: 'close' },
          ],
        });
      }, 5 * 1000);
      setTimeout(() => {
        this.notificationsService.addNotification({
          texts: [
            "Avez-vous choisi votre mode d'affichage ? Essayez pour voir ce qui vous convient le mieux.",
          ],
          buttons: [
            { text: 'one square' },
            { text: 'four squares' },
            { text: 'nine squares' },
            { text: 'full screen' },
            { text: 'fermer', class: 'chevron', action: 'close' },
          ],
        });
      }, 5 * 1000);
    }
  }
  toggleMenuNav() {
    // console.log(this.NavMenuRef)
    if(!this.NavMenuRef.showMenu)window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setTimeout(()=>this.NavMenuRef.showMenu = !this.NavMenuRef.showMenu,200)
    // console.log('scroll top')
  }
  ngOnInit(): void {
    this.storageService.logAdblocker();
    this.isSidebarOpen = false;
    // this.initFacebookService();
    this.navbarEventReciever = this.navbarService.heartClick$.subscribe(() => {
      this.showFastCart = !this.showFastCart;
    });
    this.navbarEventReciever2 = this.navbarService.heartClose$.subscribe(() => {
      this.showFastCart = !this.showFastCart;
    });
    if (window.innerWidth < 1024) {
      this.isMobile = true;
    }

    const addBtn = document.getElementById('addPwa');
    addBtn.style.display = 'none';

    window.addEventListener('beforeinstallprompt', (e) => {
      // For older browsers
      e.preventDefault();
      this.deferredPrompt = e;
      if (window.innerWidth < 1024) {
        addBtn.style.display = 'block';
      }
    });

    setTimeout(() => this.messagingService.requestPermission(), 90000);

    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;

    setTimeout(() => (this.isShow = true), 50000);

    this.menuIsHidden = true;
    // save kpi isTabOpen (adblock enabled)
    this.countTime();

    // Display moreinformation if requested by url
    const queryparam = new URLSearchParams(window.location.search);

    const widgetName = queryparam.get('widget');
    if (widgetName !== '') {
      const element = document.getElementById('widget-' + widgetName);
      if (element) {
        element.classList.remove('hide');
      }
    }

    // Check if there is a cookie that notify to do not display the video header
    const cookies = document.cookie;
    const index = cookies.indexOf('headerClosed');
    if (index !== 1) {
      const cookieList = cookies.substring(index).split(';', 1);
      const cookieValue = cookieList[0].split('=', 2)[1];
      const lastClose = new Date(cookieValue);
      // Disable video header if the last header was closed less than 4 hours
      if (lastClose.getTime() + 14400000 > new Date().getTime()) {
        this.videoOff = true;
      }
    }
    this.storageService.logAdblocker();
  }

  socialShare(): void {
    this.socialshareService.nextMessage({
      showModal: true,
      page: this.selectedPage,
    });
  }

  /**
   * Methode qui modifie le booléen isSticky : si le scroll descend, il passe à true. Si l'on se trouve tout en haut de la page,
   * il passe à false.
   * @param e - event
   */
  @HostListener('window:scroll', ['$event'])
  onStickyScroll(e): void {
    // Gestion de l'état sticky au scroll

    const element = document.getElementById('waves_container').offsetTop;
    const currentScrollPos = window.pageYOffset;

    if (window.pageYOffset >= element) {
      if (this.prevScrollpos - currentScrollPos > 80) this.isSticky = true;
      if (this.prevScrollpos < currentScrollPos) this.isSticky = false;
    } else {
      this.isSticky = false;
    }

    if (window.innerWidth < 1024) {
      if (
        window.pageYOffset >= element &&
        this.prevScrollpos > currentScrollPos
      ) {
        this.isStickyFoot = true;
      } else {
        this.isStickyFoot = false;
      }
    }
    this.prevScrollpos = currentScrollPos;

    // Supression de la div video au scroll sur la vague
    const wave = document.getElementById('waves_container');
    if (wave && window.pageYOffset >= wave.offsetTop) {
      this.videoOff = true;
      document.cookie = 'headerClosed=' + new Date().toUTCString();
    }
    // Gestion de l'affichage progressif des vagues au scroll
    const truePos = window.innerHeight + window.pageYOffset;
    const pos = Math.round(window.innerHeight + window.pageYOffset);
    const max = document.documentElement.scrollHeight;
    /**
     * @triggerPoint définit avant l'arrivée de quelle vague on affiche le component adblocker-turn-off
     */
    const triggerPoint = this.selectedPage.waves?.length < 2 ? 2 : 3;
    if (pos === max || truePos === max) {
      this.max++;
      if (this.storageService.isAdblockOn === true) {
        // adblocker detecté
        this.showAdblockBar = !this.showAdblockBar
          ? false
          : this.max === triggerPoint
          ? false
          : true;
      }
    }
  }

  CptrSubscription: Subscription;
  @ViewChild('cptr') cptr: ElementRef;
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.storageService.isAdblockOn === true) {
        this.DisplayVideoHeader = true;
      }
      // else {
      // const cookieValue = this.popupsService.getCookie('popinMediashow');
      //     this.DisplayVideoHeader = false;
      //     this.displayPopups = false;
      // }
    }, 10 * 1000);
  }

  CloseAndOpen() {
    this.DisplayVideoHeader = false;
    setTimeout(() => {
      this.DisplayVideoHeader = true;
    }, 60 * 1000);
  }

  CloseDef() {
    this.DisplayVideoHeader = false;
    // this.popupsService.incrementCookie('popinMediashow');
  }

  public countClick(): void {
    this.KPIData.nbClick++;
  }

  @ViewChild(GridComponent) grid: GridComponent;
  /* fonctions permettant d'afficher les différentes formes de la grid */
  updateLayout(gridLayout: GridLayout): void {
    this.gridViewService.gridLayout.next(gridLayout);
    this.grid.test();
  }

  hidePwa(): void {
    document.getElementById('addPwa').classList.add('hidden');
  }

  // allows us to count the time spend on this page (seconds)
  public countTime(): void {
    const startDate = new Date();

    timer(1000, 1000)
      .pipe(
        map((x: number) => {
          const newDate = new Date(startDate.getTime());
          newDate.setSeconds(newDate.getSeconds() + x);
          return newDate;
        })
      )
      .subscribe((t) => {
        if (this.KPIData.timePassed === 0) {
          this.initialTimeInSec = t.getTime() / 1000;
        }
        this.KPIData.timePassed = t.getTime() / 1000;
      });
  }

  // allows us to send KPI request when user quit page

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    // get actual page
    this.KPIData.actualPage = this.selectedPage.title;
    this.KPIData.actualPageID = this.selectedPage.customID;

    if (sessionStorage.getItem('isAdBlocked') !== 'true') {
      this.KPIData.desactivatedAdblocker = true;
    }

    if (this.KPIData.timePassed < 11) {
      this.KPIData.closedBefore10 = true;
    }
    // save time spent on mediashow
    this.KPIData.timePassed = this.KPIData.timePassed - this.initialTimeInSec;
    // save popInData for the KPI
    this.KPIData.tabOnDisplay =
      sessionStorage.getItem('isAdBlocked') === 'true';
    this.KPIData.popInOkIsDone =
      sessionStorage.getItem('popInOkIsDone') === 'true';
    this.KPIData.popInHowTo = sessionStorage.getItem('popInHowTo') === 'true';
    this.KPIData.popInClosed = sessionStorage.getItem('popInClosed') === 'true';
    this.KPIData.popInAccessVideo =
      sessionStorage.getItem('popInAccessVideo') === 'true';
    this.KPIData.tutoOkisDone =
      sessionStorage.getItem('TutoOkisDone') === 'true';
    this.KPIData.tutoHowto = sessionStorage.getItem('tutoHowto') === 'true';

    // delete all tmps in sessionStorage
    sessionStorage.removeItem('popInOkIsDone');
    sessionStorage.removeItem('popInHowTo');
    sessionStorage.removeItem('popInClosed');
    sessionStorage.removeItem('popInAccessVideo');
    sessionStorage.removeItem('isAdBlocked');
    this.PushKPIData();
  }

  addPwa(): void {
    const addBtn = document.getElementById('addPwa');
    addBtn.style.display = 'none';
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      // if (choiceResult.outcome === 'accepted') {
      // } else {
      // }
      this.deferredPrompt = null;
    });
  }

  async changePage(pageId: string): Promise<any> {
    await this.pageService
      .getFullPage(pageId)
      .subscribe((fullPage: FullPage) => {
        this.selectedPage = fullPage;
        this.pagetitle = fullPage.title;
        this.waves = this.selectedPage.waves.filter((w) => w.isActive);
        this.socialshareService.nextMessage({
          showModal: false,
          page: this.selectedPage,
        });
      });
  }

  public PushKPIData(): void {
    const KPIObservables: Observable<{ [k: string]: any }>[] = [];
    KPIObservables.push(this.kpiService.addKPIData(this.KPIData));
    forkJoin(KPIObservables).subscribe(
      (resp) => {},
      (_err) => {
        alert('Error while pushing KPI data');
      }
    );
  }

  public testUserAgent(): boolean {
    const agent = window.navigator.userAgent;
    const agentLower = agent.toLowerCase();
    return agentLower.includes(
      'prerender' ||
        'googlebot' ||
        'bingbot' ||
        'yandex' ||
        'baiduspider' ||
        'twitterbot'
    );
  }

  // private initFacebookService(): void {
  //   const initParams: InitParams = { xfbml:true, version:'v3.2'};
  //   this.facebookService.init(initParams);
  // }

  public zoomPlus(): void {
    this.zoomService.zoomPlus();
  }

  public zoomMoins(): void {
    this.zoomService.zoomMoins();
  }

  public setTurnOff(isTurnedOff: boolean): void {
    this.isTurnedOff = isTurnedOff;
  }

  set videoOff(videoOff: boolean) {
    this._videoOff = videoOff;
    if (videoOff && this.max === 1) {
      this.max = 2;
    }
  }

  get videoOff(): boolean {
    return this._videoOff;
  }
}
