import { AfterViewInit, Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { Tile } from 'src/app/types';
import { DisplayComponent } from '../display/display.component';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-videoheader',
  templateUrl: './videoheader.component.html',
  styleUrls: ['./videoheader.component.scss']
})
export class VideoheaderComponent implements OnInit, AfterViewInit {
  isOpen: boolean;
  _displayHeader = true;
  isMute: boolean;
  presentationNone: boolean;
  adblockNone: boolean;
  isDisable = false;
  isDisable2 = false;

  showTutorial = false;
  adBlockPlus: boolean = false;
  adBlock: boolean = false;
  uBlock: boolean = false;
  ghostery: boolean = false;
  text: boolean = true;
  tile: Tile = {
    customID: 'video-header-tile',
    createdAt: new Date(),
    updatedAt: new Date(),
    isActive: true,
    description: 'Video header of the page',
    target: 'https://www.lolitalempicka.com/?gclid=CjwKCAjwi9-HBhACEiwAPzUhHMquMCB6M_cglVmweG0MJSt6JVxh151zww0-Mqbx2NU6Ist37s6WRxoC1nEQAvD_BwE',
    type: 'video',
    sizeID: '', // to define
    useJWPlayer: true,
    html: '',
    url: '',
    embedded: '',
    title: 'Lolita Lempicka Video',
    fileName: 'https://imagesettoutlereste.fra1.digitaloceanspaces.com/videos/LolitaLempicka.mp4',
    thUrl: 'https://www.lolitalempicka.com/?gclid=CjwKCAjwi9-HBhACEiwAPzUhHMquMCB6M_cglVmweG0MJSt6JVxh151zww0-Mqbx2NU6Ist37s6WRxoC1nEQAvD_BwE',
    thFileName: 'https://imagesettoutlereste.fra1.digitaloceanspaces.com/videos/LolitaLempicka.mp4',
    printOrder: 0,
    filename: '',
    thLink: '',
    SurCoucheInfo: []
  };

  adblocker: any;
  onceOnly: any;

  @Output() videoOff = new EventEmitter<boolean>();
  @ViewChild('display') display: DisplayComponent;
  @Input()
  get displayHeader(): boolean {
    return this._displayHeader;
  }

  set displayHeader(value: boolean) {
    if (!value) {
      if (this.display && this.display.player) {
        this.display.player.togglePip(true);
      }
    }
    this._displayHeader = value;
  }

  constructor(private storageService: StorageService) { }

  ngOnInit(): void {
    this.isOpen = false;
    this.presentationNone = false;
    this.adblockNone = true;
    // this.onceOnly = 0;

    if (this.storageService.isAdblockOn === true) {
      // adblock detecté
      this.adblocker = true;
      this.presentationNone = true;
      this.isOpen = true;
    } else {
      this.adblocker = false;
      this.presentationNone = false;
      this.isOpen = true;
    }
  }

  ngAfterViewInit(): void {
    // Launch video without audio after complete initialisation of the player component
    if (this.display) {
      setTimeout(() => {
        this.display.player.setAudio(false);
        this.isMute = true;
        this.display.player.play = true;
      }, 150);
    }
    setTimeout(() => {
      this.isDisable = true;
    }, 10*1000);
  }

  scrollOnClick(anchor: string): void {
    const anchorElement = document.getElementById(anchor);
    anchorElement.scrollIntoView();
    setTimeout(() => {
      // anchorElement.scrollIntoView();
      // scroll to leave the videaoheader and allow to display the video as a PIP.
      window.scrollBy(0, 600);
    }, 700);
  }

  toggleVolume(): void {
    this.isMute = !this.isMute;
    this.display.player.setAudio(!this.isMute);
  }

  // toggle for the button.
  toggleVolumebutton(): void {
    if (this.isMute === true) {
      this.isMute = !this.isMute;
      this.display.player.setAudio(!this.isMute);
    }
  }

  reloadAfterDisable(){
    window.location.reload();
  }
  slideTwoDelayCroix(){
    this.showTutorial=true;
    setTimeout(() => {
      this.isDisable2 = true;
    }, 7*1000);
  }

  displayInfos(value): void {
    if (value === 'presentation') {
      this.presentationNone = false;
      this.adblockNone = !this.presentationNone;
    } else if (value === 'adblock') {
      this.adblockNone = false;
      this.presentationNone = !this.adblockNone;
    }
  }

  chooseAdBlock(blockerName: string) {
    this.adBlockPlus = (blockerName === 'adBlockPlus');
    this.adBlock = (blockerName === 'adBlock');
    this.uBlock = (blockerName === 'uBlock');
    this.ghostery = (blockerName === 'ghostery');
    this.text = (blockerName === '');
  }

  @Output() close: EventEmitter<any> = new EventEmitter<any>()
  Close() {
    this.close.emit(1);
  }
}
