import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Grid, Tile } from '../types';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { }

  // login(credentials: Credentials) {
  //   return this.http.post(`${environment.apiUrl}/login`, credentials);
  // }

  getGrids(): Observable<Grid[]> {
    return this.http.get<Grid[]>(`${environment.apiUrl}/grids`);
  }

  getGrid(id: string): Observable<Tile[]> {
    return this.http.get<Tile[]>(`${environment.apiUrl}/grid/${id}`);
  }

  getTile(id: string): Observable<Tile> {
    return this.http.get<Tile>(`${environment.apiUrl}/tile/${id}`);
  }

  upsertGrid(grid: Grid): Observable<Grid> {
    if (grid.id) {
      return this.updateGrid(grid);
    } else {
      return this.postGrid(grid);
    }
  }

  postGrid(grid: Grid): Observable<Grid> {
    return this.http.post<Grid>(`${environment.apiUrl}/grid`, grid);
  }

  private options = {
    headers: new HttpHeaders( {
      accept: 'application/json',
      'Content-type': 'application/json'

    })
  };
  postTopic(topic: string, token:string): Observable<any> {

    this.options.headers = this.options.headers.set('Authorization','key=AAAAuqv4DM0:APA91bGHHSqQ5WL3TQMfb2REPMCD1S3vbUD8geCXvw2fgvTPcFoRHf5HZIxKwiqJc4ruBZrYsXhsfvuxWJ-cpXK_Ef3FOn9AK50rMGopWfldcAL0Bb-RmRuA_7SBNnUgC40FaDFfzgCA');
    return this.http.post<any>('https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic, topic, this.options);
    
  }

  updateGrid(grid: Grid): Observable<Grid> {
    return this.http.put<Grid>(`${environment.apiUrl}/grid/${grid.id}`, grid);
  }
}
