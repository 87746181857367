import { Component, Input, OnInit, ElementRef, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          transform: 'translateY(0)',
        })
      ),
      state(
        'closed',
        style({
          transform: 'translateY(-110%)',
          height: 0,
          overflow: 'hidden',
          padding: 0,
        })
      ),
      transition('open => closed', [animate('0.7s 0s ease-in-out')]),
      transition('closed => open', [animate('0.8s 0s ease-in-out')]),
    ]),
    trigger('hide', [
      // ...
      state(
        'hidden',
        style({
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          opacity: 1,
        })
      ),
      transition('hidden => visible', [animate('0.2s 0.7s ease-in-out')]),
      transition('visible => hidden', [animate('0.2s 0s ease-in-out')]),
    ]),
  ],
})
export class MenuComponent implements OnInit {
  @Input() showMenu: boolean;
  displayedCategories: any;
  @Input() pages: any;
  trendingPages: any;
  generalPages: any;
  input: any;
  // mobile boolean getter
  get mobile(): boolean {
    return window.innerWidth <= 640
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elRef: ElementRef
  ) {
    this.showMenu = false;
    this.displayedCategories = 1;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.trendingPages = this.pages?.filter(
        (page) => page.categoriePage === 'Tendances'
      );
      this.generalPages = this.pages?.filter(
        (page) => page.categoriePage === 'General'
      );
    }, 1000);
  }

  toogleMenu() {
    this.showMenu = !this.showMenu;
    if (this.showMenu === true) {
      setTimeout(() => this.setActive(), 250);
    }

    setTimeout(() => {
      this.input = document.getElementById(
        'copyText'
      ) as HTMLInputElement | null;
      this.input.value = location.href;
    }, 3000);
  }

  setActive() {
    // Get the container element

    const btnContainer = document.getElementById('list');

    // Get all buttons with class="btn" inside the container
    const btns = btnContainer.getElementsByClassName('listItem');

    // Loop through the buttons and add the active class to the current/clicked button
    for (let i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', function () {
        const current = document.getElementsByClassName('active');
        current[0].className = current[0].className.replace(' active', '');
        this.className += ' active';
      });
    }
  }

  sendLog(value) {
    this.displayedCategories = value;
  }
}
