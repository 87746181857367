import { IData } from '../interfaces/data';
import { Size } from './size';
export class Tile implements IData {
  id?: string;
  customID?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  isActive: boolean;
  deleted?: boolean;
  owner?: string;
  description?: string;
  target!: string;
  type!: string;
  size?: Size;
  sizeID!: string;
  useJWPlayer!: boolean;
  html!: string;
  url!: string;
  title!: string;
  filename!: string;
  embedded!: string;
  backgroundBlurred?: boolean;
  thLink!: string;
  printOrder?: number;
  constructor() {
    this.backgroundBlurred = false;
    this.isActive = false;
    this.size = new Size();
  }
}
