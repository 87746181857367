import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-adblocker-turn-off',
  templateUrl: './adblocker-turn-off.component.html',
  styleUrls: ['./adblocker-turn-off.component.scss'],
  animations: [
    trigger('openCloseAd', [
      state(
        'open',
        style({
          left: '0px'
        })
      ),
      state(
        'closed',
        style({
          left: '-2000px'
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')])
    ])
  ]
})
export class AdblockerTurnOffComponent implements OnInit {
  isOpen: boolean;
  showTutorial = false;
  adBlockPlus: boolean = false;
  adBlock: boolean = false;
  uBlock: boolean = false;
  ghostery: boolean = false;
  text: boolean = true;
  @Input() title: string;
  @Output() turnOff = new EventEmitter<boolean>();
  @Input() isTurnedOff: boolean;

  constructor() { }

  ngOnInit(): void {
    this.isTurnedOff = true;
  }

  OnTurnOff(): void {
    this.isTurnedOff = true;
  }

  public hide(): void {
    document.getElementById('content').classList.add('hidden')
    document.getElementById('how-btn').classList.add('hidden')
  }

  chooseAdBlock(blockerName: string) {
    this.adBlockPlus = (blockerName === 'adBlockPlus');
    this.adBlock = (blockerName === 'adBlock');
    this.uBlock = (blockerName === 'uBlock');
    this.ghostery = (blockerName === 'ghostery');
    this.text = (blockerName === '');
  }
}
