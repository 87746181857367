import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {animate, keyframes, transition, trigger,} from '@angular/animations';
import {ZoomService} from 'src/app/services/zoom.service';
import {GridViewService} from 'src/app/services/gridView.service';
import {QuickviewService} from 'src/app/services/quickview.service';
import {GridLayout} from 'src/app/enums/grid-layout';
import {Wave} from 'src/app/backoffice/models/wave';
import {TileActionName} from 'src/app/enums/tile-action';
import {Playback, PlaybackContext} from 'src/app/models/playback';
import {SocialshareService} from '../../services/socialshare.service';
import {Tile} from '../../types';
import {TileType} from '../../enums/type-type';
import {Tile as Btile} from '../../backoffice/models/tile';
import {PagesService} from '../../backoffice/services/pages.service';
import * as kf from 'src/assets/keyframes/keyframes';
import {Size} from '../../backoffice/models/size';
import {waveZoom} from '../../models/waveZoom';
import {MessagingService} from '../../services/messaging.service';
import {ApiService} from '../../services/api.service';
import {PopupsService} from '../../services/popups.service';
import {PlaybackService} from '../../services/playback.service';
import {NewtileComponent} from '../newtile/newtile.component';
import {CartService} from '../../services/cart.service';
import {TileActionEvent} from '../../backoffice/models/tile-action-event';
import {TileActionComponent} from '../tile-action/tile-action.component';

declare let jwplayer: any;

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [ ZoomService ],
  animations: [
    trigger('cardAnimator', [
      transition('* => swiperight', animate(300, keyframes(kf.swiperight))),
      transition('* => swipeleft', animate(300, keyframes(kf.swipeleft))),
      transition('* => swipeup', animate(350, keyframes(kf.swipeup))),
      transition('* => swipedown', animate(350, keyframes(kf.swipedown))),
    ]),
  ],
})
export class GridComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() animationState: string[] = [];
  @Input() limit = 10;
  @Input() isPrerender;
  @Input() index = 0;
  @Input() wave?: Wave;
  @Input() set event(event) {if (event) { this.test() }} // TODO find a way to avoid @Input() set

  @ViewChild('container') container: ElementRef<HTMLElement>;
  @ViewChild('pipPlayer', { static: true }) pipPlayer: any;
  @ViewChild(`fille`, { static: false }) private playerEl: ElementRef;
  @ViewChildren(TileActionComponent) tileActions: QueryList<TileActionComponent>;
  @ViewChildren(NewtileComponent) tilesComponent: QueryList<NewtileComponent>;

  @HostBinding('class') classes = '';

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:scroll', ['$event'])
  isScrolledIntoView(e) {
    if (this.wave.bandeauVideoIsActive && this.playerEl) {
      const rect = this.playerEl.nativeElement.getBoundingClientRect();

      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      if (this.isBVIncScreen == topShown && bottomShown) return;
      this.isBVIncScreen = topShown && bottomShown;
      this.isBVIncScreen
        ? setTimeout(() => this.player.play(), 500)
        : this.player.pause();
    }
  }

  static _piptabs: number = 0;

  get piptabs(): number {
    return this._piptabs;
  }

  set piptabs(value: number) {
    this._piptabs = value;
  }

  draggies: any[] = [];
  isHidden: boolean;
  PlaybackContext = PlaybackContext;
  shortTileList: Btile[] = [];
  lineLen: number = 0;
  lineNb: number = 0;
  realTiles: number = 0;
  showButtonMore: boolean = true;
  showtest: boolean = false;
  mobile = false;

  innerWidth: number;
  backgroundVideoHeader = '';
  backgroundImageBandeau = '';

  public gutter = 30;
  public strdescr =
    'il y a là probablement quelques infos qui vont vous intéresser tout particulièrement.';
  // public strdescr = "L'internaute découvre quelques offres médias, puis se voit invité diplomatiquement mais fermement à désactiver son bloqueur de publicités uniquement pour MEDIASHOW. Il y découvre en un clin d'oeil de nombreuses offres provenant des médias et des marques adaptées à son profil, pour considérer seulement les 4 ou 5 messages qu'il préfère le plus et les mettre dans une liste de lecture.";
  public gridName: string;
  public tiles: Tile[] = [];
  public tileSize: {
    height: number,
    width: number,
  }
  public gridLayout: GridLayout;
  public zoom = 40;

  TileType = TileType;
  zoomVague: number;
  showActions = false;
  defaultTouch = { x: 0, y: null, time: 0 };
  isBVIncScreen: boolean; // is bandeau video in screen

  private titleIsHidden: boolean;
  private gridElement: HTMLElement;
  private tmpindex = 0;
  private colorr = [
    '#498DE0',
    '#FA965B',
    '#ED6E75',
    '#D554A4',
    '#6ACA5E',
    '#28C0D7',
  ];
  private readonly fillSet: Array<{ basename: string; url: string }> = [
    {
      basename: '../../assets/img/fillTiles/Rectangle-bleu',
      url: 'https://beta1.mediashow.fr/page/892f5961-acbe-498b-8936-6a14ff7a2263/news-radio',
    },
    {
      basename: '../../assets/img/fillTiles/Rectangle-orange',
      url: 'https://beta1.mediashow.fr/page/518f9e1a-aecd-4cf4-b429-48dc7ba0cbb1/seriesvod',
    },
    {
      basename: '../../assets/img/fillTiles/Rectangle-ros1',
      url: 'https://beta1.mediashow.fr/page/e65829f9-a532-4f13-a92e-71d21bdf8402/m%C3%A9dias-du-moment',
    },
    {
      basename: '../../assets/img/fillTiles/Rectangle-ros2',
      url: 'https://beta1.mediashow.fr/page/b814d12a-243a-4ce9-98ec-4a43c5c15551/news-tv',
    },
    {
      basename: '../../assets/img/fillTiles/Rectangle-rouge',
      url: 'https://beta1.mediashow.fr/page/b1bf1a5a-5a92-416d-90e0-9628dc47ff9c/cinema',
    },
    {
      basename: '../../assets/img/fillTiles/Rectangle-vert',
      url: 'https://beta1.mediashow.fr/page/aac637f2-5d82-4049-ab95-9e5788b1b38d/musique',
    },
  ];

  size: Size;

  public _piptabs: number = 0;
  pipedTile: NewtileComponent;
  videosToResume: Set<string> = new Set<string>();

  player: any;
  isNone = '';
  isNoneVideo = '';

  constructor(
    private zoomService: ZoomService,
    private gridViewService: GridViewService,
    private cartService: CartService,
    private quickviewService: QuickviewService,
    private playback: PlaybackService,
    private modalService: SocialshareService,
    private pageService: PagesService,
    private el: ElementRef,
    private route: ActivatedRoute,
    private apiservice: ApiService,
    private messagingservice: MessagingService,
    private angularFireMessaging: AngularFireMessaging,
    private popupService: PopupsService
  ) {
    this.gridViewService.gridLayout.subscribe((gridLayout: GridLayout) => {
      this.gridLayout = gridLayout;
      if (this.wave &&  this.gutter) {
        this.zoomService.setZoomTile(gridLayout, this.wave, this.gutter);
      }
      this.refreshWave();
    });

    this.zoomService.zoom.subscribe((zoomValue: number) => {
      this.zoom = zoomValue;
      this.el.nativeElement.style.setProperty('--zoom-value', this.zoom / 100);
      setTimeout(() => {
        this.refreshWave();
      }, 500);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['wave']?.currentValue) {
      if (this.wave.customID == '892b720b-63ec-4130-b838-7a75f29a7a98'){
        console.log(this.wave)
      }
      this.adjustWave();
    }
  }

  ngOnInit(): void {
    if (this.wave.gutterSize) {
      if (window.innerWidth < 900) {
        // Mobile
        this.gutter = this.wave.gutterSizeMobile;
        if (this.gutter === undefined) {
          this.gutter = 30;
        }
      } else {
        // PC
        this.gutter = this.wave.gutterSize;
      }

      this.gutter *= (this.zoom / 100);
    }

    this.zoomService.zoomVague.subscribe((zoomObject: waveZoom) => {
      if (this.wave.customID === zoomObject.waveId) {
        this.zoom = zoomObject.zoom;
        this.el.nativeElement.style.setProperty(
          '--zoom-value',
          this.zoom / 100
        );
        setTimeout(() => {
          this.refreshWave();
        }, 500);
      }
    });

    this.innerWidth = window.innerWidth;
    if (window.innerWidth < 900) {
      this.mobile = true;
    }
    setTimeout(() => {
      this.setZoomTile(this.gridLayout);
      this.backgroundVideoHeader = this.wave.backgroundVideoHeader;
      this.backgroundImageBandeau = this.wave.backgroundImageBandeau;
    }, 0);
    this.zoom = 30;
    this.isHidden = false;
    this.gridName = `grid-${this.wave.customID}`;
    this.titleIsHidden = true;
    this.tmpindex = this.index;
    setTimeout(() => {
      this.lineLen = this.computeTilePerLine();
      this.generateNewLine(true);
    }, 0);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setZoomTile(this.gridLayout);
    }, 50);
    this.gridElement = document.querySelector(`.${this.gridName}`);
    this.route.queryParams.subscribe(async (param) => {
      if ('tile' in param) {
        const tile = this.tilesComponent.find(
          (t) => t.tile.customID === param.tile
        );
        if (tile) {
          setTimeout(() => {
            this.doAction({ tile: tile.tile, action: TileActionName.WATCH });
            this.generateNewLine(true);
          }, 200);
        }
      }
    });
    // this.tileFill();
    // this.generateNewLine(true);
    window.addEventListener('resize', this.onRsize.bind(this));
    (window as any).prerenderReady = true;
    if (window.innerWidth < 900) {
      this.draggies.forEach((element) => this.draggies.reduce(element));
    }

    setTimeout(() => {
      if (
        this.wave.videoHeader !== undefined &&
        this.wave.bandeauType !== '' &&
        this.wave.bandeauType != null
      ) {
        (document.querySelector('.box-video') as HTMLElement).style.background =
          'linear-gradient(180deg, #F2665F 0%, #D93E4D 100%);';
      }
    }, 2000);
    if (this.wave.bandeauVideoIsActive) {
      const p = jwplayer(`${this.wave.customID}-video-container`);
      if (p.setup) {
        this.player = p;

        this.player.setup({
          // controls: !this.header,
          file: `https://imagesettoutlereste.fra1.digitaloceanspaces.com/images/${this.wave.videoHeader}`,
          // image: this.thumb,
          stretching: 'uniform',
          height: '100%',
          width: '100%',
          volume: 10,
        });
      }
    }
  }

  pauseVideos() {
    this.tilesComponent
      .filter(({ tile: { type } }) => type === TileType.VIDEO)
      .forEach(({ videoplayer, tile }) => {
        if (!this.isHidden && this.videosToResume.has(tile.customID))
          videoplayer.resume() || this.videosToResume.delete(tile.customID);

        if (this.isHidden && videoplayer.player.getState() === 'playing')
          videoplayer.pause() || this.videosToResume.add(tile.customID);
      });
  }

  test() {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth < 900) {
      this.mobile = true;
    }
    setTimeout(() => {
      this.setZoomTile(this.gridLayout);
    }, 500);
    this.zoom = 30;
    this.isHidden = false;
    this.gridName = `grid-${this.wave.customID}`;
    this.titleIsHidden = true;
    this.tmpindex = this.index;
    setTimeout(() => {
      this.lineLen = this.computeTilePerLine();
      this.generateNewLine(true);
    }, 300);
  }

  actions(tileID: string, toggle: boolean): void {
    this.tileActions.find((t) => t.tile.customID === tileID).show = toggle;
    console.log('Action -> ', toggle)
    // this.packery.destroy();
  }

  setZoomPlus(): void {
    if (this.gridLayout === GridLayout.FOURSQUARE) {
      this.gridLayout = GridLayout.ONESQUARE;
      this.setZoomTile(this.gridLayout);
    }
    if (this.gridLayout === GridLayout.NINESQUARE) {
      this.gridLayout = GridLayout.FOURSQUARE;
      this.setZoomTile(this.gridLayout);
    }
  }

  setZoomMoins(): void {
    if (this.gridLayout === GridLayout.FOURSQUARE) {
      this.gridLayout = GridLayout.NINESQUARE;
      this.setZoomTile(this.gridLayout);
    }
    if (this.gridLayout === GridLayout.ONESQUARE) {
      this.gridLayout = GridLayout.FOURSQUARE;
      this.setZoomTile(this.gridLayout);
    }
  }

  setZoomTile(gridLayout: GridLayout): void {
    this.zoomService.setZoomTile(gridLayout, this.wave, this.gutter);
  }

  identifier(index: number, item: any): any {
    return item.customID;
  }

  onRsize() {
    setTimeout(() => {
      this.refreshWave();
    }, 2000);
  }

  public zoomPlusVague(value: number, gridLayout: GridLayout): void {
    const zoomObject: waveZoom = { zoom: value, waveId: this.wave.customID };
    this.zoomService.zoomPlusVague(this.wave, gridLayout, zoomObject);
  }

  public zoomMoinsVague(value: number, gridLayout: GridLayout): void {
    const zoomObject: waveZoom = { zoom: value, waveId: this.wave.customID };
    this.zoomService.zoomMoinsVague(this.wave, gridLayout, zoomObject);
  }

  public changeZoomWave(value: number): void {
    const zoomObject: waveZoom = { zoom: value, waveId: this.wave.customID };
    this.zoomService.changeZoomVague(zoomObject);
  }

  inCart(tile: Tile): boolean {
    return this.cartService.isInCart(tile.customID);
  }

  goto(tile: Tile, e: Event): void {
    e.preventDefault();
    console.log('grid goto -> see')
    if(tile.isYoutubeEmbed){
      this.see(tile,e)
      return
    }
    window.open(tile.target, '_blank');
  }

  see(tile: Tile, e: Event): void {
    if (e) {
      e.preventDefault();
    }

    tile.size = this.pageService.tileSizes.find((s) => s.customID === tile.sizeID) ?? new Size();
    tile.size = {
      ...tile.size,
      ...this.tileSize,
    }

    if (tile.type == TileType.ADVERTIZER && tile.thFileName) {
      // e.stopPropagation();
      console.log('see', tile);
      console.log('Use quickview service')
      return this.quickviewService.open(tile);
    } else {
      if (tile.customID.includes(this.wave.id + 'fill')) {
        console.log('Use Goto')
        this.goto(tile, event);
      } else {
        console.log('Use quickview service')
        this.quickviewService.open(tile);
      }
    }
  }

  playbackAction(event: Playback): void {
    console.log('Playback action', event.action)

    this.playback.action.next(event);
  }

  cancelExpand(tile :Tile){
    console.log(this.tilesComponent.find((t) => t.tile.customID === tile.customID))

  }

  share(tile){
    this.doAction({
      tile,
      action: TileActionName.SOCIAL_SHARE
    })
  }

  doAction(tileEvent: TileActionEvent, wave?: Wave): void {
    switch (tileEvent.action) {
      case TileActionName.ADDCART:
        this.cartService.toggleInCart(tileEvent.tile, wave);
        break;
      case TileActionName.CLOSE:
        this.removeTile(tileEvent.tile);
        break;
      case TileActionName.EXTERNAL_LINK:
        this.goto(tileEvent.tile, event);
        break;
      case TileActionName.SOCIAL_SHARE:
        this.socialShare(tileEvent.tile);
        break;
      case TileActionName.WATCH:
        this.tilesComponent
          .find((t) => t.tile.customID === tileEvent.tile.customID)
          .pause();
        this.see(tileEvent.tile, event);
        break;
      case TileActionName.PIP:
        this.pip(tileEvent.tile);
        break;
      case TileActionName.CANCEL_EXPAND:
        this.cancelExpand(tileEvent.tile);
        break;
    }
  }

  socialShare(tile: Tile): void {
    this.modalService.nextMessage({ showModal: true, tile });
  }

  pip(tile: Tile): void {
    // this.tilesComponent.forEach(t => t.togglePip(false));
    this.tilesComponent
      .find((t) => t.tile.customID === tile.customID)
      .togglePip(true);
    // this.index = 100;
  }

  public async delay(ms: number): Promise<void> {
    await new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
  }

  isLiked(tile): boolean {
    return this.cartService.isInCart(tile.customID);
  }

  removeTile(tile: any): void {
    const index = this.wave.tiles.findIndex(
      (t) => t.customID === tile.customID
    );
    this.wave.tiles.splice(index, 1);
    this.cartService.remove(tile.customID);
  }

  delayRemovetile(tile: any): void {
    this.delay(300).then((any) => {
      this.removeTile(tile);
    });
  }

  updateTile(): void {
    if (this.gridElement) {
      const gutter = (this.gutter * this.zoom) / 100;
    }
  }

  updateGrid(): void {
    this.gridElement = document.querySelector(`.${this.gridName}`);
  }

  hidden(): void {
    this.isHidden = !this.isHidden;

  }

  /**
   * Calcul le nombre de tuiles que l'on peut mettre par ligne
   * @returns Nombre de tuiles qu'une ligne peut contenir
   */
  computeTilePerLine(): number {
    var len = 0;
    const zoomRatio = this.zoom / 100;
    const firstTile = this.wave.tiles[0] || null;
    const tileSize = this.pageService.tileSizes.find(
      (s) => s.customID === firstTile.sizeID
    );
    const tWidth = tileSize.width * zoomRatio;
    const gutter = this.gutter * zoomRatio;

    if (!this.container || this.container.nativeElement === null) {
      return -1;
    }
    const cStyle = window.getComputedStyle(this.container.nativeElement, null);
    if (cStyle === null) {
      return -2;
    }
    let padding: number;
    switch (this.gridLayout) {
      case 'onesquare':
        // padding = 30%
        padding = 2 * (parseFloat(cStyle.width) * ((100 - 85) / 100));
        break;
      case 'foursquares':
        // padding = 13%
        padding = 2 * (parseFloat(cStyle.width) * ((100 - 90) / 100));
        break;
      case 'ninesquares':
        // padding = 3%
        padding = 2 * (parseFloat(cStyle.width) * ((100 - 95) / 100));
        break;
      default:
        padding = 2 * (parseFloat(cStyle.width) * ((100 - 95) / 100));
        break;
    }

    const gWidth = this.container.nativeElement.clientWidth - padding;
    let tot: number = tWidth;
    while(tot < gWidth){
      len++
      tot += tWidth;
      if(tot + gutter < gWidth){
        tot += gutter;
      }
    }
    return len - 1;
  }

  /**
   * Génère une tuile de remplissage/autopromo aléatoirement parmis le set this.fillset
   * @returns Renvoie une tuile de remplissage
   */
  private generateFillTile(): Btile {
    const background =
      this.fillSet[Math.floor(Math.random() * (this.fillSet.length - 1))];
    const tmptile: any = new Btile();
    const idNumber = this.wave.tiles.length - this.realTiles;
    const model = this.wave.tiles[0];
    const tileSize = this.pageService.tileSizes.find(
      (s) => s.customID === model.sizeID
    );
    let filename = background.basename;

    switch (`${tileSize.width}x${tileSize.height}`) {
      case '300x600':
        filename += '-T2.png';
        break;
      case '300x250':
        filename += '-T1.png';
        break;
      case '500x720':
        filename += '-T3.png';
        break;
      default:
        if (tileSize.width >= tileSize.height) {
          filename += '-T1.png';
        } else {
          filename += '-T2.png';
        }
        break;
    }

    tmptile.createdAt = new Date();
    tmptile.updatedAt = new Date();
    tmptile.customID = this.wave.id + 'fill' + idNumber;
    tmptile.id = this.wave.id + 'fill' + idNumber;
    tmptile.type = 'image';
    tmptile.title = 'name';
    tmptile.size = model.size;
    tmptile.sizeID = model.sizeID;
    tmptile.isActive = true;
    tmptile.target = background.url;
    tmptile.url = background.url;
    tmptile.printOrder = 1;
    tmptile.fileName = filename;
    return tmptile;
  }

  /**
   * Fonction qui génère une ligne de tuile avec max 1 tuile de remplissage si besoin
   * @param update Permet de définir si la fonction doit elle-même update l'affichage à la fin
   */
  private generateNewLine(update: boolean): void {
    const size = this.shortTileList ? this.shortTileList.length : 0;
    const rest = this.lineLen - (this.wave.tiles.length % this.lineLen);
    let fillPosition: number = -1;
    const singleLine: boolean = this.wave.tiles.length + 1 < this.lineLen;
    let count: number;
    if (
      rest < this.lineLen &&
      rest <= this.wave.tiles.length / this.lineLen + 1
    ) {
      if (this.shortTileList.length - this.realTiles < rest) {
        fillPosition = Math.floor(Math.random() * this.lineLen);
      }
    }

    if (singleLine) {
      this.showButtonMore = false;
      count = this.lineLen - this.wave.tiles.length;
      fillPosition = -1;
      for (let i = 0; i < count; ) {
        if (this.wave.tiles[this.realTiles]) {
          this.shortTileList.push(this.wave.tiles[this.realTiles]);
          this.realTiles += 2;
        }
        this.shortTileList.push(this.generateFillTile());
        i++;
      }
    } else if (
      !update &&
      this.wave.tiles.length - this.realTiles + (fillPosition !== -1 ? 1 : 0) <
        this.lineLen
    ) {
      this.showButtonMore = false;
      return;
    }

    for (
      let i = 0;
      i < this.lineLen &&
      (this.realTiles < this.wave.tiles.length || i === fillPosition);
      i++
    ) {
      if (i === fillPosition) {
        this.shortTileList.push(this.generateFillTile());
      } else {
        this.shortTileList.push(this.wave.tiles[this.realTiles]);
        this.realTiles += 1;
      }
    }
    this.lineNb += 1;
    if (update) {
      setTimeout(() => {
        if (window.innerWidth < 900) {
          this.updateGrid();
        } else {
          this.updateTile();
        }
      }, 0);
    }

    if (this.wave.tiles.length - this.realTiles + 1 < this.lineLen) {
      this.showButtonMore = false;
    } else {
      this.showButtonMore = true;
    }
  }

  refreshWave(): void {
    setTimeout(() => {
      this.adjustWave();
    }, 20);
  }

  /**
   * Permet de regénérer des lignes de la bonne longueur lorsqu'un changement est effectué sur le mode d'affichage
   */
  adjustWave(): void {
    // Actuellement on régénère tout depuis le début en comptant sur l'optimisation existante du ngFor
    // A terme, il faudrait gérer exactement ce qui doit changer pour gagner en optimisation et ne pas regénérer de tuiles tant que pas nécessaire

    const oldReal = this.realTiles;
    this.lineLen = this.computeTilePerLine();

    this.shortTileList = [];
    this.realTiles = 0;
    this.lineNb = 0;

    for (let tot = 0; this.realTiles < oldReal; tot = this.realTiles) {
      this.generateNewLine(false);
      if (tot === this.realTiles) {
        break;
      }
    }

    const zoomRatio = this.zoom / 100;
    const { sizeID } = this.wave.tiles[0] || {};
    const { height, width, title } = this.wave.tiles[0].size ?? this.pageService.tileSizes.find(
      (s) => s.customID === sizeID
    );
    this.tileSize = {
      height: height * zoomRatio,
      width: width * zoomRatio,
    };
    console.log(title, this.tileSize)

    setTimeout(() => {
      if (window.innerWidth < 900) {
        this.updateGrid();
      } else {
        this.updateTile();
      }
    }, 20);
  }

  startAnimation(state, index) {
    if (!this.animationState[index]) {
      this.animationState[index] = state;
    }
    setTimeout(() => {
      this.resetAnimationState(index);
    }, 300);
  }

  resetAnimationState(index) {
    this.animationState[index] = '';
  }

  generateNewLineMobile(index) {
    for (let _i = 0; _i < index; _i++) {
      this.generateNewLine(false);
    }
  }

  changeToNone() {
    this.isNone = 'none';
  }

  changeToDisplay() {
    this.isNone = '';
  }

  changeToNoneVideo() {
    this.isNoneVideo = 'none';
    this.player.pause();
  }

  changeToDisplayVideo() {
    this.isNoneVideo = '';
    setTimeout(() => this.player.play(), 500)
  }

  subscribeTokenToTopic() {
    this.angularFireMessaging.requestToken.subscribe(
      // On récupére le token de l'utilisateur
      (token) => {
        let str = this.wave.title;
        const find = ' ';
        const spaces = new RegExp(find, 'g');
        str = str.replace(spaces, '');
        // on supprime les espaces car firebase n'accepte pas les sujets contenant des espaces dans leur nom
        this.apiservice.postTopic(str, token).subscribe((data) => {
          // Abonne lutilisateur au sujet passé en premier paramètre
        });
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
    // }
  }

  popupLog(id) {
    const c = this.popupService.waveClickCountCookie(id);
    // Correspond au nombre de clicks sur la vague
    if (c === 10) {
      this.subscribeTokenToTopic();
    }
  }

  public onSwipe(direction: 'swipeleft' | 'swiperight', tile: Tile, index: number) {
    this.startAnimation(direction, index);
    switch (direction) {
      case "swipeleft":
        this.share(tile);
        break;
      case "swiperight":
        this.cartService.toggleInCart(tile);
        break;
      default:
        break;
    }
  }
}
