<div class="widget_bg hide" id="widget-demopopin">
  <div class="move-down">
    <div class="widget">
      <div class="div_close" (click)="hide()" (click)="help(true)">
        <img src="../../../assets/img/times-solid 1.svg" alt="close" class="close">
      </div>
      <div *ngIf="concept === true" class="concept">
        <h3 class="titleWidget">Hello !</h3>
        <div class="pWidget_texte">
          <p class="pWidget2">Uniquement pour ce site, <span class="underline">pouvez-vous désactiver votre bloqueur de
              publicités</span>, pour financer vos sites favoris, car beaucoup de nos sources sont servies par des
            serveurs qui sont parfois bloqués.</p>
        </div>

        <div class="buttons">
          <a class="plus" (click)="help(false)">
            <p class="p_plus">Comment faire ?</p>
          </a>
          <a class="discover" (click)="hide()" (click)="help(true)">
            <p class="p_discover">Ok, c'est fait !</p>
          </a>
        </div>
      </div>

      <div *ngIf="concept === false" class="how_to_do">
        <div class="listButtons">
          <p class="askChoice"> Quel bloqueur de pub utilisez vous ?</p>
          <div class="buttonList">
            <div class="buttonAdblocker" [ngClass]="{'buttonClicked': adBlockPlus, 'unclicked': !adBlockPlus}"
              (click)="eventAdBlock('adBlockPlus')">
              <img src="../../../assets/img/adblockpluslogo.svg" alt="" class="buttonleft">
              <p>AdBlock Plus</p>
            </div>
            <div class="buttonAdblocker" [ngClass]="{'buttonClicked': adBlock, 'unclicked': !adBlock}"
              (click)="eventAdBlock('adBlock')">
              <img src="../../../assets/img/adblocklogo.svg" alt="" class="adblocklogo">
              <p>AdBlock</p>
            </div>
            <div class="buttonAdblocker" [ngClass]="{'buttonClicked': uBlock, 'unclicked': !uBlock}"
              (click)="eventAdBlock('uBlock')">
              <img src="../../../assets/img/ublocklogo.svg" alt="" class="buttonleft">
              <p>Ublock Origin</p>
            </div>
            <div class="buttonAdblocker" [ngClass]="{'buttonClicked': ghostery, 'unclicked': !ghostery}"
              (click)="eventAdBlock('ghostery')">
              <img src="../../../assets/img/ghosterylogo.svg" alt="" class="ghosterylogo">
              <p>Ghostery</p>
            </div>
          </div>
        </div>
        <div class="gifad">
          <div class="tutoArea">
            <div *ngIf="text">
              <p>
                Sélectionner votre bloqueur de publicités pour voir ici comment le désactiver
              </p>
            </div>
            <div *ngIf="adBlockPlus" class="gif">
              <img src="../../../assets/img/adblockplus.gif" alt="">
            </div>

            <div *ngIf="adBlock" class="gif">
              <img src="../../../assets/img/adblock.gif" alt="">
            </div>

            <div *ngIf="uBlock" class="gif">
              <img src="../../../assets/img/ublock.gif" alt="">
            </div>

            <div *ngIf="ghostery" class="gif">
              <img src="../../../assets/img/ghostery.gif" alt="">
            </div>
          </div>
          <a class="done" (click)="hide(true)" (click)="help(true)">
            <p class="p_discover">Ok, c'est fait !</p>
          </a>
        </div>

      </div>

    </div>
  </div>
