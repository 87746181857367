import { AuthService } from './../../services/auth.service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  public passwordConfirm: string;
  public password: string;
  private token: string;
  public mdpError:boolean;
  public mdpSuccess:boolean;
  @Output() close = new EventEmitter<void>();
  constructor(private AuthService: AuthService) {}

  ngOnInit(): void {
    const queryparam = new URLSearchParams(window.location.search);
    this.token = queryparam.get('rpToken');
  }
  resetPass() {
    if (this.password === this.passwordConfirm && this.password.length >=8){
      this.mdpError = !true
      this.AuthService.reset({
        token: this.token,
        password: this.password,
      }).then(() => {

        this.mdpSuccess = true;
      });

    } else {
      this.mdpError = true
    }
  }
  closepoppin(){
    this.close.emit();
  }
}
