import { AuthService } from './../../services/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/backoffice/models/user';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  verticalLinks = [];
  horizontalLinks = [];
  @Output() isMenuOpen = new EventEmitter<boolean>();
  @Input() isScrolling;
  @Output() isRegisterOpen = new EventEmitter<boolean>();
  constructor(private AuthService:AuthService) {}
  public get user(): User{
    return this.AuthService.user;
  }
  public get isAuth():boolean{
    return this.AuthService.token?.length > 0;
  }
  ngOnInit(): void {


    this.verticalLinks = [
      {
        title: 'Programmes',
        img: './../../../assets/img/userMenu/programme.svg',
        link: '#',
      },
      {
        title: 'Notifications',
        img: './../../../assets/img/userMenu/notifications.svg',
        link: '#',
      },
      {
        title: 'Marques',
        img: './../../../assets/img/userMenu/marques.svg',
        link: '#',
      },

    ];

    this.horizontalLinks = [
      {
        title: 'Réseaux',
        link: '#',
      },
      {
        title: 'Productions',
        link: '#',
      },
      {
        title: 'Droits',
        link: '#',
      },
    ];
  }
  logout(){
    this.AuthService.logout()
  }
  lockScroll() {
    if (window.innerWidth < 900) {
      const e = document.head.parentElement;
      e.classList.toggle('lock-scroll');
    }
  }

  closeUserMenu(): void {
    this.isMenuOpen.emit(false);
  }
  public toggleRegister() {
    this.closeUserMenu();
    this.isRegisterOpen.emit(!this.isRegisterOpen);
  }
}
