import { Playback } from './../models/playback';
import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlaybackService {
  action = new Subject<Playback>();
  subject = new Subject<any>();
  constructor() { }
  UnactiveAllPopin(id: string): void {
    this.subject.next({ id: id });
  }

  getActivePopin(): Observable<any> {
    return this.subject.asObservable();
  }

  UnactiveZindex(): Observable<any> {
    return this.subject.asObservable();
  }
}
