import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'iframe'
})
export class IframePipe implements PipeTransform {
  transform(htmlString: string): string {
    return changeHtmlAttritutes(htmlString);
  }
}

function changeHtmlAttritutes(htmlString: string): any {
  const widthRegex = /width="\w+"/gm;
  const heightRegex = /height="\w+"/gm;

  htmlString = htmlString.replace(widthRegex, '"width=100%"');
  htmlString = htmlString.replace(heightRegex, '"height=100%"');

  return htmlString;
}
