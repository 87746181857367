import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Tile } from '../types';

@Injectable({
  providedIn: 'root'
})
export class QuickviewService {
  public tile = new Subject<Tile>();

  constructor() {}

  open(tile: Tile): void {
    this.tile.next(tile);
  }

  close(): void {
    this.tile.next(null);
  }
}
