import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GridLayout } from '../enums/grid-layout';

interface FsDocument extends HTMLDocument {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

interface FsDocumentElement extends HTMLElement {
  mozFullScreenElement?: boolean;
  webkitFullscreenElement?: boolean;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
  msRequestFullscreen?: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class GridViewService {
  public gridLayout = new BehaviorSubject<GridLayout>(GridLayout.FOURSQUARE);
  public isFullscreen = false;

  constructor() {
  }

  checkFullscreen(): boolean {
    if (document.fullscreenElement) {
      return true;
    } else {
      return false;
    }
  }

  openFullscreen(): void {
    const elem: FsDocumentElement = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
    // Trouver un moyen de gérer le fullscreen
  }

  closeFullscreen(): void {
    const doc: FsDocument = document;
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      /* Firefox */
      doc.mozCancelFullScreen();
    } else if (doc.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      doc.webkitExitFullscreen();
    } else if (doc.msExitFullscreen) {
      /* IE/Edge */
      doc.msExitFullscreen();
    }
  }
}
