<div class="box">
    <div class="box-login">
        <p class="login">
            Réinitialisation du mot de passe
        </p>
    </div>
    <form>

        <div class="text">
            <label *ngIf="mdpError">Veuillez verifier votre mot de passe</label>
            <label *ngIf="mdpSuccess">Votre mot de passe a été Enregistrer</label>

            <input class="text-area" placeholder="Mot de passe" type="password" name="password" [(ngModel)]="password" minlength="8">
            <input class="text-area" placeholder="Confirmation du Mot de passe" type="password" name="password" [(ngModel)]="passwordConfirm" minlength="8">
        </div>
    </form>

    <button *ngIf="!mdpSuccess" type="submit" class="continue" (click)="resetPass()">Continuer</button>
    <button *ngIf="mdpSuccess" type="submit" class="continue" (click)="closepoppin()">Continuer</button>
</div>
