  <div *ngIf="isEnabled(TileActionName.CLOSE)" (click)="emit(TileActionName.CLOSE)" class="actionr">
  <i class="fa fa-times"></i>
</div>
<ul>
  <li *ngIf="isEnabled(TileActionName.ADDCART)" (click)="emit(TileActionName.ADDCART)" class="ticket"><i
      class="{{inCart ? 'fas fa-heart' : 'far fa-heart'}} "></i></li>
  <li *ngIf="isEnabled(TileActionName.WATCH)" (click)="emit(TileActionName.WATCH)" class="ticket"><i
      class="fas fa-eye"></i></li>
  <li *ngIf="isEnabled(TileActionName.EXTERNAL_LINK)" class="ticket"><a
      (click)="this.cptrService.NewTileAction(TileActionName.EXTERNAL_LINK,this.tile.customID)" href="{{tile.target}}"
      target="_blank" class="has-text-white"><i class="fa fa-external-link-alt"></i></a></li>
  <li *ngIf="isEnabled(TileActionName.SOCIAL_SHARE)" (click)="emit(TileActionName.SOCIAL_SHARE)" class="ticket"><i
      class="fas fa-share-alt"></i></li>
  <li *ngIf="isEnabled(TileActionName.PIP)" (click)="emit(TileActionName.PIP)" class="ticket"><i
      class="fas fa-photo-video"></i></li>
</ul>
