export enum PlaybackAction {
  STOP,
  PLAY,
  PAUSE,
  PIP
}

export enum PlaybackContext {
  GRID,
  MAINVIEW,
  CART,
  POPIN,
  PIP
}

export class Playback {
  itemId: string;
  playerId: string;
  action: PlaybackAction;
  context: PlaybackContext;
  position: number;
}
