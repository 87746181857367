import { DisplayComponent } from '../display/display.component';
import { Playback, PlaybackAction, PlaybackContext } from 'src/app/models/playback';
import { PlaybackService } from 'src/app/services/playback.service';
import { Component, HostListener, HostBinding, OnInit, ViewChild } from '@angular/core';
import { QuickviewService } from 'src/app/services/quickview.service';
import { Tile } from 'src/app/types';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import { VideoviewService } from 'src/app/services/videoview.service';
import { ZoomService } from 'src/app/services/zoom.service';
import { Wave } from 'src/app/backoffice/models/wave';
import { GridViewService } from 'src/app/services/gridView.service';

@Component({
  selector: 'app-quickview',
  templateUrl: './quickview.component.html',
  styleUrls: ['./quickview.component.scss']
})
export class QuickviewComponent implements OnInit {
  @ViewChild(DisplayComponent) display: DisplayComponent;
  public isOpen = false;
  public tile: Tile;
  public wave: Wave;

  public resumeAction: PlaybackAction = -1;
  public resumePosition: number = 0;
  public popinDimension: { height: number, width: number } = { height: 456, width: 729 };

  // public elemT: any;
  public faTimesCircle = faTimesCircle;
  public zoomService: ZoomService;
  public tilewidth: number;
  public tileheight: number;
  private verticals: boolean;

  @HostBinding('class.opened') get opened(): boolean { return this.isOpen; }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ): void {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  constructor(
    public gridViewService: GridViewService,
    private quickviewService: QuickviewService,
    private playback: PlaybackService,
    private videoViewService: VideoviewService
  ) {
    this.quickviewService.tile.subscribe((tile) => {
      if (tile !== null) {
        document.documentElement.style.setProperty('--tile-width', '100%');
        document.documentElement.style.setProperty('--tile-height', '100%');
        document.documentElement.style.setProperty('--pourcentage', '0%');
        document.documentElement.style.setProperty('--margin-top', '0%');
        this.tile = tile;
        this.isOpen = true;
        const ratioed = this.tile.size.height / this.tile.size.width;

        if (this.tile.type === 'video') {
          let ratio = 1
          const popinRef = {
            height: 456,
            width: 729
          }

          this.getVideoDimensionsOf(this.tile.url).then((dimension) => {
            const vertical: boolean = (dimension.width < dimension.height);
            this.verticals = vertical;
            ratio = vertical ? (dimension.width / dimension.height) : (dimension.height / dimension.width);
            this.popinDimension = vertical ? { height: popinRef.height, width: popinRef.height * ratio } : { height: popinRef.width * ratio, width: popinRef.width };
          });

          if (window.innerWidth > 900 && ratioed === 2) {
            document.documentElement.style.setProperty('--tile-width', `${this.popinDimension.height}px`);
            document.documentElement.style.setProperty('--tile-height', `${this.popinDimension.width}px`);
            document.documentElement.style.setProperty('--pourcentage', '35%');
            document.documentElement.style.setProperty('--margin-top', '6%');
          } else {
            const videowidth = window.innerWidth - 2 * (window.innerWidth * 5 / 100);
            const videoheight = window.innerHeight - 2 * (window.innerHeight * 5 / 100);
            if (ratioed === 2) {
              document.documentElement.style.setProperty('--tile-width', `${videowidth}px`);
              document.documentElement.style.setProperty('--tile-height', '100%');
            } else if (window.innerWidth < 900) {
              document.documentElement.style.setProperty('--tile-width', '100%');
              document.documentElement.style.setProperty('--tile-height', `${this.popinDimension.height}px`);
              document.documentElement.style.setProperty('--margin-top', '25%');
              if (ratioed === 2) {
                document.documentElement.style.setProperty('--pourcentage', '15%');
              } else document.documentElement.style.setProperty('--margin-top', '60%');
            }
          }
        }
      } else {
        this.isOpen = false;
      }
    });

    this.playback.action.subscribe((action) => {
      if (this.tile) {
        this.resumeAction = action.action;
        this.resumePosition = action.position;
      }
    })

    this.videoViewService.closedNode.subscribe((closed) => {
      if (this.tile && this.tile.customID === closed.tile.customID) {
        this.isOpen = false;
        this.quickviewService.close();
        this.tile = null;
      }
    })
  }

  ngOnInit(): void {}

  close(): void {
    this.isOpen = false;
    setTimeout(() => {
      // console.log("hey")
      const playback = new Playback();
      playback.action = this.display.playVideo ? PlaybackAction.PLAY : PlaybackAction.PAUSE;
      playback.position = this.display.videoPlayerPosition;
      playback.itemId = this.tile.customID;
      playback.context = this.display.playVideo
        ? PlaybackContext.PIP
        : PlaybackContext.GRID;
      this.tile = null;
    }, 1200); // same timeout as animation
  }

  getVideoDimensionsOf(url: string): Promise<{height: number, width: number}> {
    return new Promise(resolve => {
      // create the video element
      const video = document.createElement('video');

      // place a listener on it
      video.addEventListener('loadedmetadata', function () {
        // retrieve dimensions
        const height = this.videoHeight;
        const width = this.videoWidth;
        // send back result
        resolve({ height, width });
      }, false);

      // start download meta-datas
      video.src = url;
    });
  }
}
