import { AuthService } from './../../services/auth.service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-email-confirmation',
  templateUrl: './email-confirmation.component.html',
  styleUrls: ['./email-confirmation.component.scss']
})
export class EmailConfirmationComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  public error :boolean;
  public success :boolean;
  public token :string;
  public errorMessage:string;
  constructor(private AuthService : AuthService) { }

  ngOnInit(): void {
    const queryparam = new URLSearchParams(window.location.search);
    this.token = queryparam.get('ceToken');
    this.AuthService.confirmEmail(this.token).then((res)=>{
      this.success = !(this.error = false)
    }).catch((err)=>{
      if (err.status == 404) {
        console.log(err)
        this.errorMessage = 'Votre email a deja ete confirmé';
      }
      this.success = !(this.error = true)
    })
  }
  closePopin(){
    this.close.emit()
  }

}
