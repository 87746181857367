
import { JwplayerComponent } from './../jwplayer/jwplayer.component';
import { PlaybackAction } from './../../models/playback';
import { PlaybackService } from './../../services/playback.service';
import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeart2, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import { Tile } from 'src/app/types';

import { VideoviewService } from 'src/app/services/videoview.service';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss']
})
export class DisplayComponent implements OnInit {
  @ViewChild(JwplayerComponent) player: JwplayerComponent;

  @Input() tile: Tile;
  @Input() popin: boolean = false;
  @Input() pip: boolean = false;
  @Input() cart: boolean = false;
  @Input() header: boolean = false;
  @Input() resumeAction: PlaybackAction;
  @Input() resumePosition: number;
  @Input() isCart: boolean = false;
  @Input()
  @Output() isVideoEnded = new EventEmitter<boolean>();

  playing = false;
  isMobile = false;
  @Input()
  get playVideo(): boolean {
    return this.playing;
  }

  set playVideo(value: boolean) {
    if (window.innerWidth < 900) {
      if (this.player) {
        this.player.close();
      }
    }
    this.playing = value;
    if (this.player && !value) {
      this.player.pause();
    }
  }

  get videoPlayerPosition(): number {
    return this.player ? this.player.getPosition() : 0;
  }

  public faHeart = faHeart;
  public faHeartBroken = faHeart2;
  public faExternalLinkAlt = faExternalLinkAlt;

  constructor(public el: ElementRef, private playback: PlaybackService, private videoViewService: VideoviewService) {
  }

  ngOnInit(): void {
    if (window.innerWidth < 900) {
      this.isMobile = true;
    }
    // console.log('this.tile', this.tile);
  }

  videoEnded() {
    this.isVideoEnded.emit(true)
  }

  goto(tile: Tile, e: Event): void {
    e.preventDefault();
    if (this.player) {
      this.player.pause();
    }
    window.open(tile.target, '_blank');
  }

  change() {
    const iframe = <HTMLIFrameElement>document.getElementById('iframe-display');
    iframe.src = '../../assets/img/cinema.PNG';
  }
}
