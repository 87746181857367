import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../backoffice/models/user';

declare global {
  interface RegisterData {
    Email: string;
    Password: string;
    confirmPassword: string;
  }
  interface LoginData {
    Email: string;
    Password: string;
    remember: boolean;
  }

  interface ResetPasswordPayload {}
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private cookieService: CookieService) {

    const t = this.cookieService.get('jwt');
    if(t){
      this.getUserByToken().subscribe((res) => {
        this.user = (res as any).data as User;
      });
    }
  }
  private b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode(p1);
        }
      )
    );
  }
  public user: User;
  public token: string;
  register(data: RegisterData) {
    return this.http.post(`${environment.endpoint}/registerVisitor`, data).toPromise();
  }
  login(data: LoginData) {
    return this.http
      .post(
        `${environment.endpoint}/loginVisitor`,
        `{"email": "${btoa(data.Email)}",\r\n"password": "${btoa(
          data.Password
        )}"\r\n}`
      ).toPromise()
      .then((res) => {
        this.token = res as string;
        if (data.remember) this.cookieService.set('jwt', this.token);
        this.getUserByToken().subscribe((res) => {
          this.user = (res as any).data as User;
        });

        return this.user
      });
  }
  logout(){
    this.user = null;
    this.token = null;
    this.cookieService.delete('jwt')
  }
  getUserByToken() {
    console.log(this.token);
    return this.http.post(
      `${environment.endpoint}/tokenVisitor`,
      `{"token": "${this.token}"}`
    );
  }
  sendReset(email: string): Promise<any> {
    return this.http
      .post(`${environment.endpoint}/resetPassword`, { email })
      .toPromise();
  }
  reset(data: ResetPasswordPayload): Promise<any> {
    return this.http
      .post(`${environment.endpoint}/resetPasswordConfirmation`, data)
      .toPromise();
  }
  confirmEmail(token: string): Promise<any> {
    return this.http
      .post(`${environment.endpoint}/confirmEmail`, { token })
      .toPromise();
  }
}
