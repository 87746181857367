import { NavDirection } from './../../enums/direction';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, AfterContentInit, ContentChildren } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit, AfterContentInit {
  NavDirection = NavDirection;
  @Output() selectionChange = new EventEmitter<NavDirection>();
  @ContentChildren('items') items;
  constructor(private changeDetecor: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
  }

  goto(navDirection: NavDirection): void {
    this.changeDetecor.detectChanges();
    this.selectionChange.emit(navDirection);
  }
}
