<div *ngIf="wave" class="wave-container" (click)="popupLog(wave.customID)">
  <div
    *ngIf="wave.videoHeader != undefined && wave.bandeauVideoIsActive == true"
    class="box-video {{isNoneVideo}}"
    style="background: linear-gradient(9deg, {{wave.backgroundVideoHeader}} 31.78%, rgb(237, 248, 239) 99.8%)"
  >
    <div id="mereVideo" class="mere">
      <div #fille class="fille">
        <vg-player class="offset">
          <video #media [vgMedia]="media" class="videoB" id="singleVideo" autoplay="autoplay" preload="auto"
            muted="muted" loop="loop" controls height="300px" style="object-fit: contain;min-width: 1080px;">
            <source src="https://imagesettoutlereste.fra1.digitaloceanspaces.com/images/{{wave.videoHeader}}"
              type="video/mp4">
          </video>
        </vg-player>


        <div id="{{wave.customID}}-video-container" style="width:100%;height:100%;"></div>
      </div>
      <div class="bandeauChevron">
        <i class="fas fa-chevron-down resizeChevron" (click)="changeToNoneVideo()"></i>
      </div>
    </div>
  </div>

  <div *ngIf="isNoneVideo == 'none'" (click)="changeToDisplayVideo()">
    <div class="ifIsNoneVideo"></div>
    <div style="display: flex; justify-content: end;">
      <h5 class="titreIfNone">Video</h5>
      <i class="fas fa-chevron-up" style="color: #2c7b82; font-size: x-large; padding-top: 5px;"
        (click)="changeToNoneVideo()"></i>
    </div>
  </div>

  <div class="box {{isNone}}"
    style="background: linear-gradient(9deg, {{wave.backgroundImageBandeau }} 31.78%, rgb(237, 248, 239) 99.8%)"
    *ngIf="wave.bandeau != undefined && wave.bandeauType == 'image-mini' && wave.bandeauIsActive == true">
    <div class="mere">
      <img class="imgg" src="https://imagesettoutlereste.fra1.digitaloceanspaces.com/images/{{ window.innerWidth > 640 ? wave.imageBandeau : wave.imageBandeauMobile }}"
        height="350px" alt="">
      <div id="mainBandeauImage" class="fille">
        <h1 class="titre-bandeau">{{ wave.bandeau }}</h1>
        <div class="fillefille">
          <h6 class="description-bandeau">
            {{ wave.descriptionBandeau }}
          </h6>
        </div>
      </div>
      <div>
        <i class="fas fa-chevron-down resizeChevron" (click)="changeToNone()"></i>
      </div>
    </div>
  </div>

  <div *ngIf="isNone == 'none'" (click)="changeToDisplay()" style="display: flex ; justify-content: end;">
    <div class="ifIsNoneImage"></div>
    <h5 class="titreIfNone">Image</h5><i class="fas fa-chevron-up"
      style="color: #b6c2c3; font-size: x-large; padding-top: 5px;" (click)="changeToNone()"></i>
  </div>

  <div *ngIf="wave.bandeau != undefined && wave.bandeauType == 'image-full' && wave.bandeauIsActive == true"
    style="border: none;box-shadow:none;border-radius: 100px;background: linear-gradient(9deg, {{wave.backgroundImageBandeau}} 31.78%, rgb(237, 248, 239) 99.8%)"
    class="box {{isNone}}">
    <div class="mere">
      <div class="fille">
        <img style="/* height: 350px; */object-fit: contain;" class="imgg-full"
          src="https://imagesettoutlereste.fra1.digitaloceanspaces.com/images/{{ wave.imageBandeau }}" alt="">
      </div>
      <div class="bandeauChevron">
        <i class="fas fa-chevron-down resizeChevron" (click)="changeToNone()"></i>
      </div>
    </div>
  </div>

  <div [className]="isHidden ? 'wave-header round-corner' : 'wave-header'">
    <div class="bandeau-titre-wave">
      <h2>{{ wave.title }}</h2>
      <p [innerHTML]="wave.description ? wave.description : strdescr "
        [className]="isHidden ? 'none' : 'bandeau-titre-wave'"></p>
    </div>
    <div class="level-item has-text-centered" id="controls-panel">
      <div class="moins">
        <i class="fas fa-search-minus" (click)="!mobile ? zoomMoinsVague(zoom, gridLayout) : setZoomMoins()"></i>
      </div>
      <div id="mobileChevron" class="chevron">
        <i [className]="isHidden ? 'fas fa-chevron-down' : 'fas fa-chevron-up'" (click)="hidden()"></i>
      </div>
      <div class="div_number_zoom">
        <p class="number_zoom">{{zoom | number : '.0-0'}} %</p>
      </div>
      <div class="plus">
        <i class="fas fa-search-plus plus-icon" (click)="!mobile ? zoomPlusVague(zoom, gridLayout) : setZoomPlus()"></i>
      </div>

      <div id="pcChevron" class="chevron">
        <i [className]="isHidden ? 'fas fa-chevron-down' : 'fas fa-chevron-up'" (click)="hidden();pauseVideos()"></i>
      </div>
    </div>
  </div>

  <br>

  <ng-container *ngIf="!isHidden">
    <!-- Liste complète -->
<!--      [@openClose]="isHidden ? 'closed' : 'open'"-->
    <div
      #container
      *ngIf="(wave.isShortList == false || wave.isShortList == undefined || isPrerender) || mobile"
      [style.zIndex]="index"
      [style.gap.px]="gutter"
      class="{{ gridName }} wave {{gridLayout}}"
    >
      <div
        *ngFor="let tile of  wave.tiles | sort:'printOrder'; let i = index"
        [attr.tileid]="tile.customID"
        class="m-tile"
      >
        <ng-container *ngIf="!mobile; else mobileTile">
          <app-newtile
            [style.--tile-width.px]="tileSize.width"
            [style.--tile-height.px]="tileSize.height"
            [style.--tile-background]="tile.backgroundColor"
            [style.zIndex]="wave.tiles.length - i"
            [context]="PlaybackContext.GRID"
            [tile]="tile"
            [tileZoom]="zoom"
            (playerEvent)="playbackAction($event)"
            (click)="goto(tile, $event)"
            (closed)="removeTile($event)"
          >
          </app-newtile>

          <app-tile-action
            class="actions"
            [style.zIndex]="(wave.tiles.length - i) + 1"
            [inCart]="inCart(tile)"
            [tile]="tile"
            [zoom]="zoom"
            [waveTitle]="wave.title"
            (action)="doAction($event, wave)"
          ></app-tile-action>
          <div class="animate__animated animate__slideInUp"></div>
        </ng-container>

        <ng-template #mobileTile>
          <ul *ngIf="isLiked(tile)" class="rectangle" (click)="this.cartService.toggleInCart(tile);">
            <li class="ticket">
              <fa-icon
                *ngIf="isLiked(tile) && mobile"
                (click)="this.cartService.toggleInCart(tile);"
                class="fas fa-1x fa-heart"
                style="color:red; left:18px;bottom:18px;position:fixed;"
              ></fa-icon>
            </li>
          </ul>

          <app-newtile
            [@cardAnimator]="animationState[i]"
            [tile]="tile"
            [tileZoom]="zoom"
            [style.--tile-width.px]="tileSize.width"
            [style.--tile-height.px]="tileSize.height"
            (swipeleft)="onSwipe('swipeleft', tile, i)"
            (swiperight)="onSwipe('swiperight', tile, i)"
            (closed)="removeTile($event)"
            (press)="see(tile, null)"
            (click)="goto(tile, $event)">
          </app-newtile>
        </ng-template>
      </div>
    </div>
    <!-- Bouton en voir + -->
<!--      [@openClose]="isHidden ? 'closed' : 'open'"-->
    <div
      *ngIf="wave.isShortList == true && !mobile"
      [style.zIndex]="index"
      [style.gap.px]="gutter"
      class="{{ gridName }} wave {{gridLayout}}"
      #container
    >
      <div
        *ngFor="let tile of shortTileList | sort:'printOrder'; let i = index"
        [attr.tileid]="tile.customID"
        class="m-tile"
        style="flex-direction: column;"
      >
        <app-newtile
          [style.zIndex]="wave.tiles.length - i"
          [context]="PlaybackContext.GRID"
          [tile]="tile"
          [tileZoom]="zoom"
          [style.--tile-width.px]="tileSize.width"
          [style.--tile-height.px]="tileSize.height"
          [style.--tile-background]="tile.backgroundColor"
          (playerEvent)="playbackAction($event)"
          (click)="goto(tile, $event)"
          (mouseenter)="actions(tile.customID, true)"
          (mouseleave)="actions(tile.customID, false)"
          (closed)="removeTile($event)"
        ></app-newtile>

        <app-tile-action
          [style.zIndex]="(wave.tiles.length - i) + 1"
          [inCart]="inCart(tile)"
          [tile]="tile"
          [zoom]="zoom"
          [waveTitle]="wave.title"
          (action)="doAction($event, wave)"
        ></app-tile-action>

        <div class="animate__animated animate__slideInUp"></div>
        <!-- <app-cptr-tuile @Apparition></app-cptr-tuile> -->
      </div>
    </div>
  </ng-container>

  <div id="waveControls" *ngIf="!isHidden && !mobile && wave.isShortList == true">
    <button *ngIf="showButtonMore" id="showMore" (click)="generateNewLine(true)">En voir +</button>
  </div>

</div>
