import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  private heart = new Subject<any>();
  private close = new Subject<any>();

  constructor() { }

  public heartClick(): void {
    this.heart.next();
  }

  get heartClick$(): Subject<any> {
    return this.heart;
  }

  public heartClose(): void {
    this.close.next();
  }

  get heartClose$(): Subject<any> {
    return this.close;
  }
}
