import {
  Playback,
  PlaybackAction,
  PlaybackContext,
} from './../../models/playback';
import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { PlaybackService } from './../../services/playback.service';
import { VideoviewService } from 'src/app/services/videoview.service';
import { QuickviewService } from 'src/app/services/quickview.service';
import { Tile } from 'src/app/types';
import { VideoNode } from 'src/app/models/videoview';

import { Subscription } from 'rxjs';

declare let jwplayer: any;
@Component({
  selector: 'app-jwplayer',
  templateUrl: './jwplayer.component.html',
  styleUrls: ['./jwplayer.component.scss'],
})
export class JwplayerComponent implements OnInit, OnDestroy {
  private _height: number;
  @Input()
  get height(): number {
    return this._height;
  }

  set height(value: number) {
    const newSize = { width: this.width, height: value };
    this.el.nativeElement.style.setProperty('--height', `${value}px`);
    this.updateSize(newSize);
    this._height = value;
  }

  private _width: number;
  private tmpHeight: number;
  private tmpWidth: number;
  @Input() isCart: boolean = false;
  @Input() isDisplaying: boolean = false; // = false;
  @Input()
  get width(): number {
    return this._width;
  }

  set width(value: number) {
    const newSize = { width: value, height: this.height };
    this.el.nativeElement.style.setProperty('--width', `${value}px`);
    this.updateSize(newSize);
    this._width = value;
  }

  @Input() src: string;
  @Input() playerId: string;
  @Input() thumb: string;
  @Input() context: PlaybackContext;
  @Input() itemId: string;
  @Output() action = new EventEmitter<Playback>();

  @Input() tile: Tile;
  @Input() resumeAction: PlaybackAction;
  @Input() resumePosition = 0;
  @Output() preview = new EventEmitter<boolean>();
  private oldstate: PlaybackAction = -1;

  @Input() header = false;
  @Input() lockInTile = false;
  @Output() endedInTile = new EventEmitter<boolean>();
  @Output() endedVideo = new EventEmitter<boolean>();
  private closeSub: Subscription;
  private endedOnce = false;
  private _pip = false;
  private _popin = false;
  private _cart = false;
  player: any;
  @Input()
  get cart(): boolean {
    return this._cart;
  }

  set cart(value: boolean) {
    this._cart = value;
    if (value) {
      this.context = PlaybackContext.CART;
    }
  }

  @Input()
  get popin(): boolean {
    return this._popin;
  }

  set popin(value: boolean) {
    this._popin = value;
  }

  @Input()
  get pip(): boolean {
    return this._pip;
  }

  set pip(value: boolean) {
    this._pip = value;
  }

  private _play = false;
  @Input()
  get play(): boolean {
    return this._play;
  }

  get playingExternal(): boolean {
    return !this.isDisplaying && (this.pip || this.popin);
  }

  private getPlaybackState(state: string): PlaybackAction {
    switch (state) {
      case 'paused':
        return PlaybackAction.PAUSE;
      case 'playing':
        return PlaybackAction.PLAY;
      default:
        return PlaybackAction.STOP;
    }
  }

  close(end: boolean = false): void {
    if (this.cart) {
      return;
    }
    if (end) {
      this.preview.emit(true);
    }
    if (this.pip) this.pip = false;
    if (this.popin) {
      this.popin = false;
      if(!end) this.pip = true;
    }
    if (this.pip)
      return this.toggleVideoNode(
        false,
        this.getPlaybackState(this.player.getState()),
        PlaybackContext.PIP
      );
    if (!(this.popin || this.pip))
      return this.toggleVideoNode(
        false,
        PlaybackAction.STOP,
        PlaybackContext.GRID
      );
    if (end) {
      this.pip = false;
      this.popin = false;
      this.toggleVideoNode(false, PlaybackAction.STOP, PlaybackContext.GRID);
    }
  }

  openPopin(): void {
    if (this.cart || this.header) {
      return;
    }
    this.popin = true;
    this.toggleVideoNode(true, PlaybackAction.PLAY, PlaybackContext.POPIN);
    this.subscribeExternalClose();
  }

  set play(value: boolean) {
    this._play = value;
    if (this.player && this._play) {
      this.player.play();
      if (!this.header && !this.pip && !this.popin) {
        this.playbackService.UnactiveAllPopin('Message');
        if (this.isDisplaying) {
          this.setAudio(true);
          return;
        }
      }
    }
    if (!this.header && !this.lockInTile && this.player && !value) {
      setTimeout(() => {
        this.setAudio(false);
        this.togglePip(true);
      }, 1200);
    }
  }

  togglePip(value: boolean): void {
    if (this.cart || (this.header && this.endedOnce)) {
      return;
    }
    this.pip = true;
    this.toggleVideoNode(true, PlaybackAction.PLAY, PlaybackContext.PIP);
    if (this.header) {
      return;
    }
    this.subscribeExternalClose();
  }

  updateSize(size: { height: number; width: number }): void {
    if (this.player) {
      this.player.setConfig(size);
    }
  }

  resume(position: number): void {
    this.player.seek(position);
    this.player.play();
    this._play = true;
  }

  setAudio(volume: boolean): void {
    if (volume) {
      this.player.setConfig({
        mute: false,
        volume: this.player.getVolume(),
      });
    } else {
      this.player.setConfig({
        mute: true,
      });
    }
  }

  pause(): void {
    this.player.pause();
  }

  getPosition(): number {
    return this.player ? this.player.getPosition() : -1;
  }

  /**
   * Open or Close the video node (popin of pip) of this tile
   * @param openClose Boolean to define if the function open (true) or close (false)
   * @param action Action that will be applied to the player inside the node (play, pause, stop, ...)
   * @param context Context where the video will be played when this method will be resolved (pip, popin, grid...)
   */
  private toggleVideoNode(
    openClose: boolean,
    action: PlaybackAction,
    context: PlaybackContext
  ): void {

    const playback = new Playback();
    playback.action = action;
    playback.playerId = this.playerId;
    playback.position = this.player.getPosition();
    playback.itemId = this.itemId;
    playback.context = context;
    const node: VideoNode = {
      tile: this.tile,
      playback: playback,
    };
    console.log({playback, node})
    if (openClose === true) {
      this.player.stop();
      this.videoViewService.view(node);
    } else {
      this.videoViewService.close(node);
    }
  }

  /**
   * Prepare the resume inTile of the video opened in pip/popin.
   * Then, when the pip will be closed, the playback will resume
   * in tile.
   */
  private subscribeExternalClose(): void {
    this.closeSub = this.videoViewService.closedNode.subscribe((closed) => {
      if (
        closed.tile.customID === this.tile.customID &&
        closed.playback.context === PlaybackContext.GRID
      ) {
        this.pip = false;
        this.popin = false;
        this.player.seek(closed.playback.position);
        this.preview.emit(false);
        this.context = closed.playback.context;
        this.setAudio(true);
        this.actionHandler(closed.playback.action);
      }
    });
  }

  /**
   * Listen to the service Quickview to prepare resume in tile
   * when the pip will be closed.
   * (Pip opened by the Quickview when it will be closed)
   */
  private setupResumeInTile(): void {
    if (this.cart) {
      return;
    }
    this.quickviewService.tile.subscribe((tile) => {
      if (
        tile !== null &&
        tile.customID === this.tile.customID &&
        !this.isDisplaying
      ) {
        // Envoie état actuel de la vidéo pour que la quickview puisse le récupérer
        const playback = new Playback();
        playback.action = PlaybackAction.PLAY;
        playback.playerId = this.playerId;
        playback.position = this.player.getPosition();
        playback.itemId = this.itemId;
        playback.context = PlaybackContext.MAINVIEW;
        this.playbackService.action.next(playback);

        this.pip = true;
        this.closeSub = this.videoViewService.closedNode.subscribe((closed) => {
          // console.log({closed})
          if (
            closed.tile.customID === this.tile.customID &&
            closed.playback.context === PlaybackContext.GRID
          ) {
            this.pip = false;
            this.popin = false;
            if (this.player.getDuration().VOD >= closed.playback.position) {
              this.player.stop();
            } else {
              this.player.seek(closed.playback.position);
              this.preview.emit(false);
            }
            this.context = closed.playback.context;
            this.setAudio(true);
            this.actionHandler(closed.playback.action);
          }
        });
      } else if (tile !== null) {
        // setup old state si besoin sur tous les player
        this.oldstate = this.getPlaybackState(this.player.getState());
        if (this.oldstate === PlaybackAction.PLAY) {
          this.pause();
        }
      } else {
        // rétablir old state si besoin sur tous les player
        if (this.oldstate === PlaybackAction.PLAY) {
          this.play = true;
        }
      }
    });
  }

  private actionHandler(action: PlaybackAction): void {
    switch (action) {
      case PlaybackAction.PLAY:
        this.play = true;
        break;
      case PlaybackAction.PAUSE:
        this.pause();
        break;
      default:
        break;
    }
  }

  constructor(
    public el: ElementRef,
    private quickviewService: QuickviewService,
    private playbackService: PlaybackService,
    private videoViewService: VideoviewService
  ) {
    if (this.popin) {
      this.height = 365;
      this.width = 575;
    } else if (this.pip) {
      this.height = 240;
      this.width = 420;
    }
    if (!this.cart) {
      this.setupResumeInTile();
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.player = jwplayer(`${this.playerId}-video-container`);
      this.player.setup({
        controls: !this.header,
        file: this.src,
        image: this.thumb,
        stretching: this.header ? 'fill' : 'uniform',
        height: '100%',
        width: '100%',
        volume: 10,
      });
      this.player.on('displayClick', (e) => {
        if (
          this.player.getState() !== 'playing' &&
          !this.pip &&
          this.getPosition() === 0
        ) {
          this.openPopin();
        }
      });
      this.player.on('play', (e) => {
        if (!this.isDisplaying && (this.popin || this.pip)) {
          /* TROUVER UNE SOLUTION POUR CA */
          this.player.stop();
          return;
        }
        const playback = new Playback();
        playback.action = PlaybackAction.PLAY;
        playback.playerId = this.playerId;
        playback.position = this.player.getPosition();
        playback.itemId = this.itemId;
        if (this.context) {
          playback.context = this.context;
        }
        this.action.emit(playback);
        this._play = true;
      });
      this.player.on('pause', (e) => {
        const playback = new Playback();
        playback.action = PlaybackAction.PLAY;
        playback.playerId = this.playerId;
        playback.itemId = this.itemId;
        if (this.context) {
          playback.context = this.context;
        }
        playback.position = this.player.getPosition();
        this.action.emit(playback);
      });
      this.player.on('stop', (e) => {
        this.preview.emit(true);
      });
      this.player.on('idle', (e) => {
        if (this.isCart) {
          this.endedVideo.emit(true);
        }
      });
      this.player.on('complete', (e) => {
        if (this.lockInTile) {
          this.player.stop();
          this.endedInTile.emit(true);
          return;
        } else if (!this.isDisplaying) {
          this.player.stop();
        }else{
          console.log('player complete put it in grid and stop');
          // const playback = new Playback();
          // playback.action = PlaybackAction.STOP;
          // playback.playerId = this.playerId;
          // playback.itemId = this.itemId;
          // playback.context = PlaybackContext.GRID;
          // this.context = PlaybackContext.GRID;
          // this.action.emit(playback);
          this.oldstate = PlaybackAction.STOP;

          this.lockInTile = true;
          this.endedOnce = true;
          this._play = false;
          this.player.stop()
          this.close(true);
          this.context = PlaybackContext.GRID;
          return
        }
        if (!this.header) {
          this.close(true);
        } else {
          this.play = true;
          this.setAudio(false);
          this.endedOnce = true;
        }
      });
      if (this._play && this.player) {
        this.player.play();
      }
      if (this.resumeAction) {
        console.log('resume action')
        this.resume(this.resumePosition);
        this.actionHandler(this.resumeAction);
        if (this.popin) {
          this.setAudio(true);
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.closeSub) {
      this.closeSub.unsubscribe();
    }
  }
}
