import { ConfigService } from './config.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Page } from '../models/page';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { FullPage } from '../models/full-page';
import { BackendResponse } from '../models/response';
import { map, tap } from 'rxjs/operators';
import { Size } from '../models/size';
import { Tile } from 'src/app/types';
import { Wave } from '../models/wave';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PagesService extends ApiService {
  tileSizes: Size[];

  constructor(http: HttpClient, config: ConfigService) {
    super(http, config)
    this.getSizes().then(sizes => {
      this.tileSizes = sizes;
    });
  }

  public getPages(): Observable<Page[]> {
    return this.get<Page[]>('pages');
  }

  public getWaves(id: string, offset: number, count: number): Promise<any> {
    return this.http.get(`${environment.endpoint}/pagess/${id}?count=${count.toString()}&offset=${offset.toString()}`).toPromise();
  }

  async getSizes(): Promise<Size[]> {
    return await this.get<Size[]>('sizes').toPromise();
  }

  public getPage(pageId: string): Observable<FullPage> {
    return this.get<Page>(`pages/${pageId}`).pipe(
      map((response: any) => {
        return response.page;
      })
    );
  }

  sortWave(a: Wave, b: Wave): number {
    if (a.printOrder < b.printOrder) {
      return -1;
    } else if (a.printOrder > b.printOrder) {
      return 1;
    }
  }

  public getFullPage(pageId: string): Observable<Page> {
    return this.get<FullPage>(`pages/${pageId}`).pipe(
      map((response: any) => {
        return response.page;
      }),
    );
  }

  public addPage(page: Page): Observable<BackendResponse> {
    return this.post<BackendResponse>('pages', page);
  }

  public changeActive(page: Page): Observable<BackendResponse> {
    return this.post<BackendResponse>(`pages/${page.customID}/${page.isActive ? 'enable' : 'disable'}`, {});
  }

  public updatePage(page: Page): Observable<BackendResponse> {
    return this.post<BackendResponse>(`pages/${page.customID}`, page);
  }
}
