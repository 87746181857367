import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TileActionName } from '../enums/tile-action';
import { v4 as uuidv4 } from 'uuid';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CompteurService {
  private MediaPass: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public MediaPassObservable: Observable<number> = this.MediaPass.asObservable();
  private MediaPassManager: MediaPassManager[] = [];

  constructor(private cookieService: CookieService) {
    const MediaPassID = this.cookieService.get('MediaPassID')

    if (MediaPassID === '') {
      this.cookieService.set('MediaPassID', uuidv4())
      this.cookieService.set('MediaPass', '0');
      this.cookieService.set('MediaPassManager', '[]');
      this.MediaPass.next(0);
    } else {
      this.MediaPass.next(Number(this.cookieService.get('MediaPass')))
      this.MediaPassManager = JSON.parse(this.cookieService.get('MediaPassManager'))
    }
  }

  NewTileAction(action: TileActionName, id: string) {
    let exist: boolean = true;
    for (let i = 0; i < this.MediaPassManager.length; i++) {
      if (this.MediaPassManager[i].id === id) {
        switch (action) {
          case TileActionName.ADDCART:
            if (this.MediaPassManager[i].TilesActions.ADDCART === 0) {
              this.MediaPassManager[i].TilesActions.ADDCART = TileActionCoints.ADDCART
              this.MediaPass.next(this.MediaPass.getValue() + TileActionCoints.ADDCART)
            } else {
              this.MediaPassManager[i].TilesActions.ADDCART = 0
              this.MediaPass.next(this.MediaPass.getValue() - TileActionCoints.ADDCART)
            }

            break;
          case TileActionName.WATCH:
            if (this.MediaPassManager[i].TilesActions.WATCH === 0) {
              this.MediaPassManager[i].TilesActions.WATCH = TileActionCoints.WATCH
              this.MediaPass.next(this.MediaPass.getValue() + TileActionCoints.WATCH)
            } else {
              // this.MediaPassManager[i].TilesActions.WATCH = 0
              // this.MediaPass -= TileActionCoints.WATCH
            }

            break;
          case TileActionName.SOCIAL_SHARE:
            if (this.MediaPassManager[i].TilesActions.SOCIAL_SHARE === 0) {
              this.MediaPassManager[i].TilesActions.SOCIAL_SHARE = TileActionCoints.SOCIAL_SHARE
              this.MediaPass.next(this.MediaPass.getValue() + TileActionCoints.SOCIAL_SHARE)
            } else {
              // this.MediaPassManager[i].TilesActions.SOCIAL_SHARE = 0
              // this.MediaPass -= TileActionCoints.SOCIAL_SHARE
            }

            break;
          case TileActionName.EXTERNAL_LINK:
            if (this.MediaPassManager[i].TilesActions.EXTERNAL_LINK === 0) {
              this.MediaPassManager[i].TilesActions.EXTERNAL_LINK = TileActionCoints.EXTERNAL_LINK
              this.MediaPass.next(this.MediaPass.getValue() + TileActionCoints.EXTERNAL_LINK)
            } else {
              // this.MediaPassManager[i].TilesActions.EXTERNAL_LINK = 0
              // this.MediaPass -= TileActionCoints.EXTERNAL_LINK
            }

            break;
          default:
            break;
        }
        this.cookieService.set('MediaPass', String(this.MediaPass.getValue()));
        this.cookieService.set('MediaPassManager', JSON.stringify(this.MediaPassManager))
        exist = false;
        break;
      }
    }

    if (exist) {
      this.MediaPassManager.push(new MediaPassManager(id))
      this.NewTileAction(action, id);
    }
  }
}

enum TileActionCoints {
  CLOSE = 20,
  ADDCART = 30,
  WATCH = 50,
  EXTERNAL_LINK = 100,
  SOCIAL_SHARE = 70,
  PIP = 40,
}

export interface CptrTileAction {
  CLOSE: number;
  ADDCART: number;
  WATCH: number;
  EXTERNAL_LINK: number;
  SOCIAL_SHARE: number;
  PIP: number;
}

class MediaPassManager {
  public TilesActions: CptrTileAction = {
    CLOSE: 0,
    ADDCART: 0,
    WATCH: 0,
    EXTERNAL_LINK: 0,
    SOCIAL_SHARE: 0,
    PIP: 0
  };

  public id: string;
  constructor(id: string) {
    this.id = id;
  }
}
