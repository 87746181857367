<div class="section" id="navbar">
  <div class="container">
    <div class="level">
      <div class="level-left has-text-centered">
        <div class="level-item" id="userIcon" (click)="toggleMenu(!isUserMenuOpen)">
          <img src="./../../../assets/img/user.svg" alt="user icon">
        </div>
        <div (click)="toggleMenuNav()"><i class="fas fa-2x fa-bars" id="desktopoptions"></i>
        </div>
        <div class="squares-select" [@openClose]="isOptionsVisible ? 'open' : 'closed'" style="position:absolute">
          <img class="square-type" src="../../../assets/img/one_square.svg" alt="one_square"
            [ngClass]="{'one' : this.gridLayout === GridLayout.ONESQUARE}" (click)="updateLayout(GridLayout.ONESQUARE)">
          <img class="square-type" src="../../../assets/img/four_squares.svg" alt="four_squares"
            [ngClass]="{'four' : this.gridLayout === GridLayout.FOURSQUARE}"
            (click)="updateLayout(GridLayout.FOURSQUARE)">
          <img class="square-type" src="../../../assets/img/nine_squares.svg" alt="nine_squares"
            [ngClass]="{'nine' : this.gridLayout === GridLayout.NINESQUARE}"
            (click)="updateLayout(GridLayout.NINESQUARE)">
        </div>
        <div class="level-item">
          <img id="logomediashow" src="./../../../assets/img/logo.svg" (click)="innerWidth?toggle() : redirect()" alt="logo_mediashow" />
        </div>
        <div class="heart-container">
          <fa-icon (click)="toggleCart()" class="fas fa-2x fa-heart"></fa-icon>
          <span class="numeroCoeur" *ngIf="tiles.length < 10" (click)="toggleCart()">{{ tiles.length }}</span>
          <span class="numeroCoeur2" *ngIf="tiles.length >= 10" (click)="toggleCart()">{{ tiles.length }}</span>
        </div>
        <div class="bg-blue">
          <img src="assets/img/user.svg" alt="" (click)="toggleMenu(!isUserMenuOpen);lockScroll()">
        </div>
      </div>
      <div class="level-right has-text-centered">
        <i class="search-icon fas fa-search"></i><input class="search" placeholder="Search" aria-labelledby="soon">
        <div class="soon" role="tooltip" id="soon">Bientôt disponible</div>
      </div>
    </div>
    <!-- <img src="../../../assets/img/arrow-down-sign-to-navigate.svg" alt="info_toggle" class="info-btn"
      (click)="toggle()"> -->
  </div>
</div>

<app-user-menu (isMenuOpen)="toggleMenu(!isUserMenuOpen)" [ngClass]="{'d-none':!isUserMenuOpen}" (isRegisterOpen)="toggleRegister(!isRegisterOpen)"
  [isScrolling]="isUserMenuScroll">
</app-user-menu>
<app-registration *ngIf="isRegisterOpen" (close)="toggleRegister(false)" (OpenLogin)="toggleLogin(true)"></app-registration>
<app-login *ngIf="isLoginOpen" (close)="toggleLogin(false)" (reset)="toggleReset()"></app-login>
<app-password-reset *ngIf="passwordResetOpen"></app-password-reset>
<app-password-forgot *ngIf="passwordForgotOpen" (close)="toggleReset()"></app-password-forgot>
<app-email-confirmation *ngIf="confirmEmailOpen" (close)="toggleConfirmEmail()"></app-email-confirmation>
<carousel class="help" [@openClose]="isOpen ? 'open' : 'closed'" [height]='270' [cellWidth]="'100%'" [dots]='true'
  (swipeup)="toggle()">
  <div class="carousel-cell help1">
    <div class="div_close1">
      <img src="../../../assets/img/Fermer.svg" alt="close" class="close1" (click)="toggle()">
    </div>
    <div class="div_swip">
      <div>
        <img src="../../../assets/img/swipe-left (1) 1.png" alt="swipe-left">
      </div>
      <div>
        <p>Glissez à droite une tuile pour l’ajouter à votre liste.</p>
      </div>
    </div>
  </div>

  <div class="carousel-cell help1">
    <div class="div_close1">
      <img src="../../../assets/img/Fermer.svg" alt="close" class="close1" (click)="toggle()">
    </div>
    <div class="div_swip">
      <div>
        <img src="../../../assets/img/swipe-right 1.svg" alt="swip_right">
      </div>
      <div>
        <p>Glissez à gauche une tuile pour la partager sur les réseaux sociaux.</p>
      </div>
    </div>
  </div>

  <div class="carousel-cell help2">
    <div class="div_close2">
      <img src="../../../assets/img/Fermer.svg" alt="close2" class="close2" (click)="toggle()">
    </div>
    <div class="div_explanations">
      <p>Appuyez rapidement une tuile pour ouvrir son  site d’origine</p>
    </div>
  </div>
  <div class="carousel-cell help2">
    <div class="div_close2">
      <img src="../../../assets/img/Fermer.svg" alt="close3" class="close2" (click)="toggle()">
    </div>
    <div class="div_explanations">
      <p>Appuyez un instant une tuile pour avoir ses détails</p>
    </div>
  </div>
  <div class="carousel-cell help2">
    <div class="div_close2">
      <img src="../../../assets/img/Fermer.svg" alt="close4" class="close2" (click)="toggle()">
    </div>
    <div class="div_explanations">
      <p>Utilisez le + et le -  pour afficher en 1  ou 2 ou 3 colonnes</p>
    </div>
  </div>
  <div class="carousel-cell help2">
    <div class="div_close2">
      <img src="../../../assets/img/Fermer.svg" alt="close5" class="close2" (click)="toggle()">
    </div>
    <div class="div_explanations">
      <p>appuyez sur le cœur rouge pour ouvrir votre liste de lecture
      </p>
    </div>
  </div>
  <div class="carousel-cell help2">
    <div class="div_close2">
      <img src="../../../assets/img/Fermer.svg" alt="close5" class="close2" (click)="toggle()">
    </div>
    <div class="div_explanations">
      <p>utilisez le menu latéral droite pour choisir vos paramètres d’affichage </p>
    </div>
  </div>
</carousel>

<carousel id="conversation" class="carouseli help dots" [@openClose]="isOpenI ? 'open' : 'closed'" [height]='392'
  [cellWidth]="'100%'" [dots]='true' (swipeup)="toggleI()">
  <div class="carousel-cell help1 carouseli">
    <div class="div_close1">
      <img src="../../../assets/img/Fermer.svg" alt="close" class="close1" (click)="toggleI()">
    </div>
    <div class="div_swip">
      <div>
        <p>Avez-vous testé toutes les fonctionnalités de l’appli ? faites des essais en touchant,
          cliquant, glissant, les tuiles mais aussi les boutons , icones, chevron du header et footer</p>
      </div>
    </div>
  </div>
  <div class="carousel-cell help2 carouseli">
    <div class="div_close2">
      <img src="../../../assets/img/Fermer.svg" alt="close2" class="close2" (click)="toggleI()">
    </div>
    <div class="div_explanations">
      <p>Avez-vous choisi votre mode d&#39;affichage préféré ? Essayez pour voir ce qui vous convient
        le mieux entre 1 – 2 ou 3 colonnes avec l’écran.
      </p>
    </div>
  </div>
  <div class="carousel-cell help2 carouseli">
    <div class="div_close2">
      <img src="../../../assets/img/Fermer.svg" alt="close3" class="close2" (click)="toggleI()">
    </div>
    <div class="div_explanations">
      <p>Le principe des découvertes sur Mediashow, c’est de voir entre 50 à 100 sujets
        rapidement en laissant son intuition sélectionner les 4 – 5 prioritaires du jour pour vous.
        Vous pouvez soit les mettre dans votre liste de lecture en glissant la tuile vers la droite,
        soit les consulter immédiatement en cliquant dessus.
      </p>
    </div>
  </div>
</carousel>
