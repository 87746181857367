<!-- <div [@openClose]="isOpen ? 'open' : 'closed'">
  <app-registration></app-registration>
</div> -->
<div class="fast-cart no-tile-menu" [@openClose]="isOpen ? 'open' : 'closed'">
  <!-- on agrandit le petit panier afin qu'une partie soit sur la droite de la page non visible
    pour l'utilisateur et on applique HostListener (mouseenter et mouseleave) dessus -->
  <!-- petit panier -->
  <div class="container">
    <div class="liste">
      <h5>Ma liste de lecture</h5>
      <button class="redButton" (click)="heartClose()">Fermer</button>
      <button *ngIf="tiles.length > 0" class="redButton" (click)="openCart()"> Ouvrir ma liste</button>
    </div>
    <div class="content">
      <ng-container *ngIf="tiles.length > 0">
        <div *ngFor="let tile of tiles" class="thumb-tiles">
          <app-newtile [context]="PlaybackContext.CART" (mouseenter)="actions(tile.customID, true)"
            (mouseleave)="actions(tile.customID, false)" [tile]="tile" [lockSize]="156"
            (click)="openCart(tile.customID)"></app-newtile>
          <app-tile-action [inCart]="true" [enabledActions]="enabledActions" [tile]="tile" [zoom]="60"
            (action)="doAction($event)"></app-tile-action>
        </div>
      </ng-container>
      <ng-container *ngIf="tiles.length === 0">
        <p>Vous n'avez pas encore de favoris</p>
        <p>
          <fa-icon [icon]="faHeartBroken" class="fa-lg"></fa-icon>
        </p>
      </ng-container>
    </div>

    <button *ngIf="tiles.length > 0" class="redButton" (click)="clearCart()">Vider la liste</button>

  </div>
</div>
