import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class PopupsService {
  constructor(private cookieService: CookieService) { }

  getCookie(cookieName) {
    let cookieValue = this.cookieService.get(cookieName);
    if (cookieValue === undefined || cookieValue === '') {
      this.cookieService.set(cookieName, '0', 365);
      cookieValue = this.cookieService.get(cookieName);
    }
    return parseInt(cookieValue);
  }

  incrementCookie(cookieName) {
    let cookieValue = parseInt(this.cookieService.get(cookieName));
    cookieValue++;
    this.cookieService.set(cookieName, cookieValue.toString(), 365);
  }

  waveClickCountCookie(id) {
    let cookieArray;
    const cookieValue = this.cookieService.get('WaveClickCount'); // Recuperer le cookie WaveClickCount
    let doitIncrementer;
    let arrayIndex;
    let cpt;
    doitIncrementer = false;
    if (cookieValue !== '') { // SI LE COOKIE EXISTE :
      // VERIFIER DANS LE TABLEAU DU COOKIE SI UN OBJET POSSEDE L'ID PASSé EN PARAMETRE
      cookieArray = JSON.parse(cookieValue);
      arrayIndex = 0;
      cookieArray.forEach(element => {
        if (element.id === id) {
          doitIncrementer = true; // DEFINIR SI ON DOIT INCREMENTER L'OBJET OU PAS
        }
        if (doitIncrementer !== true) { arrayIndex++ }
      });

      if (doitIncrementer === true) {
        cookieArray[arrayIndex].clickCount++; // INCREMENTER L'OBJET
        cpt = cookieArray[arrayIndex].clickCount;
        this.cookieService.set('WaveClickCount', JSON.stringify(cookieArray), 365);
      } else {
        const createdObject = {
          id: id,
          clickCount: 1
        }
        cpt = createdObject.clickCount;
        cookieArray.push(createdObject); // CREER UN NOUVEL OBJET ET L'AJOUTER AU COOKIE
        this.cookieService.set('WaveClickCount', JSON.stringify(cookieArray), 365);
        // AJOUTER L'OBJET DANS LE TABLEAU DU COOKIE
      }
      doitIncrementer = false;
    } else { // SI LE COOKIE N'EXISTE PAS :
      const createdCookie = [];
      const createdObject = {
        id: id,
        clickCount: 1
      }
      cpt = createdObject.clickCount;
      createdCookie.push(createdObject);
      this.cookieService.set('WaveClickCount', JSON.stringify(createdCookie), 365);
      // CREER TON COOKIE AVEC UN TABLEAU D'OBJETS + CREER L'OBJET DONT L'ID EST CELUI DE LA VAGUE CLICKéE
    }
    return cpt;
  }
}
