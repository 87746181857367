import { IData } from '../interfaces/data';
export class Size implements IData {
  id?: string;
  customID?: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  isActive?: boolean;
  deleted?: boolean;
  owner?: string;
  description?: string;
  height: number | undefined;
  width: number | undefined;
  title!: string;
  constructor() { }
}
