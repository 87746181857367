<div class="overlay-register" (click)="closeRegister($event)">

  <div class="box">
    <div class="box-creat">
      <p class="creat">
        Créer un compte
      </p>
    </div>
    <p class="text" *ngIf="success.length == 0">Pour gérer plusieurs playlist, votre profil et vos préférences, inscrivez-vous.</p>
    <p class="text" *ngIf="success.length != 0">{{success}}</p>
    <div class="all-logo" *ngIf="success.length == 0">
      <a class="google logo">
        <div class="fab fa-google fa-1x"></div>
      </a>
      <a class="facebook logo">
        <div class="fab fa-facebook-f fa-1x" aria-hidden="true"></div>
      </a>
      <a class="linkedin logo">
        <div class="fab fa-linkedin-in fa-1x"></div>
      </a>
    </div>
    <form *ngIf="success.length == 0">

      <div class="box-registration">
        <label *ngIf="error.length > 0" style="color: red ;">{{error}}</label>
        <input class="text-area" placeholder="Email" type="email" name="email" [(ngModel)]="email">
        <input class="text-area" placeholder="Mot de passe" type="password" name="password" [(ngModel)]="password" minlength="8">
        <input class="text-area" placeholder="Confirmation du Mot de passe" type="password" name="passwordConfirm" [(ngModel)]="passwordConfirm" minlength="8">
      </div>
    </form>
    <button class="sign-up" name="sign-up" (click)="register()" *ngIf="success.length == 0">S'inscrire</button>
    <button class="sign-up" name="sign-up" (click)="closepoppin()" *ngIf="success.length != 0">Fermer</button>
    <a class="link" name="sign-in" (click)="openLogin()" *ngIf="success.length == 0">Se connecter</a>
  </div>
</div>
