import { DisplayComponent } from './../display/display.component';
import { Component, OnInit, ViewChild, Input, ElementRef } from '@angular/core';
import { Tile } from 'src/app/types';
import { PlaybackAction } from 'src/app/models/playback';
import { ResizeEvent } from 'angular-resizable-element';
import { PagesService } from 'src/app/backoffice/services/pages.service';

@Component({
  selector: 'app-videonode',
  templateUrl: './videonode.component.html',
  styleUrls: ['./videonode.component.scss']
})
export class VideoNodeComponent implements OnInit {
  @ViewChild(DisplayComponent) display: DisplayComponent;
  @Input() tile: Tile;
  @Input() action: PlaybackAction;
  @Input() position: number;
  popinDimension: { height: number, width: number } = { height: 456, width: 729 };
  pipDimension: { height: number, width: number } = { height: 240, width: 420 };
  appearing = false;
  piptab : number = 0;
  dragPosition = { x: 0, y: 0 };
  style: {};
  private _popin: boolean = false;
  private _pip: boolean = false;
  @Input()
  set popin(value: boolean) {
    this.dragPosition = { x: 0, y: 0 };
    this._popin = value;
    this.appearing = (this._popin);
    if (this._popin) {
      setTimeout(() => { this.appearing = false; this.dragPosition = { x: 0, y: 0 }; }, 50);
    }
    this.style = {};
    this.defineNodeFormat();
  }

  get popin(): boolean {
    return this._popin;
  }

  @Input()
  set pip(value: boolean) {
    if (window.innerWidth > 900) {
      this._pip = value;
      this.appearing = (this._pip);
      if (this._pip) {
        setTimeout(() => { this.appearing = false; }, 455);
      }
      this.dragPosition = { x: 0, y: 0 };
    }
    this.style = {};
    this.defineNodeFormat();
  }

  get pip(): boolean {
    return this._pip;
  }

  set mute(value: boolean) {
    if (this.display.player) {
      this.display.player.setAudio(!value);
    }
  }

  onResizeEnd(event: ResizeEvent): void {
    this.style = {
      width: `${event.rectangle.width}px`,
      height: `${event.rectangle.height}px`,
      top: `${event.rectangle.top}px`,
      left: `${event.rectangle.left}px`,
      bottom: `${event.rectangle.bottom}px`,
      right: `${event.rectangle.right}px`
    };
  }

  onResizeStart() {
    this.appearing = false;
  }

  /**
   Returns the dimensions of a video asynchrounsly.
  @param {String} url Url of the video to get dimensions from.
  @return {Promise} Promise which returns the dimensions of the video in 'width' and 'height' properties.
  */
  getVideoDimensionsOf(url: string): Promise<{height: number, width: number}> {
    return new Promise(resolve => {
      // create the video element
      const video = document.createElement('video');

      // place a listener on it
      video.addEventListener('loadedmetadata', function () {
        // retrieve dimensions
        const height = this.videoHeight;
        const width = this.videoWidth;
        // send back result
        resolve({ height, width });
      }, false);

      // start download meta-datas
      video.src = url;
    });
  }

  defineNodeFormat(): void {
    let ratio = 1;
    const horizontalPipRef = {
      height: 240,
      width: 420
    }
    const verticalPipRef = {
      height: 350,
      width: 350
    }
    const popinRef = {
      height: 456,
      width: 729
    }
    let pipRef: { height: number, width: number };

    this.getVideoDimensionsOf(this.tile.fileName).then((dimension) => {
      const vertical: boolean = (dimension.width < dimension.height);
      ratio = vertical ? (dimension.width / dimension.height) : (dimension.height / dimension.width);
      pipRef = vertical ? verticalPipRef : horizontalPipRef;
      this.popinDimension = vertical ? { height: popinRef.height, width: popinRef.height * ratio } : { height: popinRef.width * ratio, width: popinRef.width };
      this.pipDimension = vertical ? { height: pipRef.height, width: pipRef.height * ratio } : { height: pipRef.width * ratio, width: pipRef.width };
    });
  }

  constructor(public el: ElementRef, private pageService: PagesService) {
  }

  ngOnInit(): void {
  }
}
