<div cdkDrag cdkDragBoundary=":host" [cdkDragFreeDragPosition]="dragPosition" mwlResizable [enableGhostResize]="true"
  [resizeCursorPrecision]="9" (resizeEnd)="onResizeEnd($event)" (resizeStart)="onResizeStart()" [ngStyle]="style"
  [resizeEdges]="{ left: true, right: true, top: true, bottom: true }"
  [ngClass]="{'pip-slide': pip && appearing, 'popup-appear': popin && appearing, 'POPIN': popin,'PIP': pip,'NONE': !pip && !popin }"
  [style.--popin-height.px]="popinDimension.height" [style.--popin-width.px]="popinDimension.width"
  [style.--pip-height.px]="pipDimension.height" [style.--pip-width.px]="pipDimension.width">
  <app-display [tile]="tile" [popin]="popin" [pip]="pip" [playVideo]="true" [resumePosition]="position"
    [resumeAction]="action">
  </app-display>
  <div cdkDragHandle class="drag"></div>
</div>
