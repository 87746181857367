<app-videoheader *ngIf="DisplayVideoHeader" (close)="CloseAndOpen();" (closeDef)="CloseDef();"></app-videoheader>

<app-sidebar (updateGrid)="this.gridUpdated = $event" (showFeedback)="this.showFeedback = !this.showFeedback"
  (showNotification)="this.showNotification = !this.showNotification" (showInfoMobile)="this.isOpenInfo = !this.isOpenInfo"
  (showFastCart)="this.showFastCart = !this.showFastCart" [selectedPage]="this.selectedPage">
</app-sidebar>

<app-feedback *ngIf="this.showFeedback == true" style="margin: auto;margin-bottom: 8%;margin-top:24%;" [isOpen]="true"
  [pagetitle]="pagetitle" id="feedback" title="test" (isClose)="this.showFeedback = !this.showFeedback">
</app-feedback>

<app-notifications *ngIf="this.showNotification == true" [@isOpen2]="this.showNotification" [concept1]="true"
  (closeNotification)="this.showNotification = !this.showNotification">
</app-notifications>

<app-fast-cart *ngIf="this.showFastCart == true" [isOpen]="this.showFastCart"></app-fast-cart>

<div (click)="countClick()">

  <app-quickview></app-quickview>

  <app-cart id="cart"></app-cart>

  <app-socialshare></app-socialshare>

  <app-demo-popin></app-demo-popin>

  <app-moreinformation></app-moreinformation>
  <!-- <div id="fb-root"></div>
  <div class="fb-customerchat"
     page_id="817999341675433">
</div> -->
  <div class="grids">
    <app-navbar (scroll)="onStickyScroll($event)" id="navbar" [selectedPage]="selectedPage" [isOpen]="isOpenInfo"
      [ngClass]="{'sticky': isSticky}" (toggleMenuEvent)="toggleMenuNav()"></app-navbar>
    <app-menu [pages]="pages" ></app-menu>
    <app-adblocker-turn-off *ngIf="displayPopups == true" [isTurnedOff]="showAdblockBar"
      (scroll)="onStickyScroll($event)" [title]="titleAdblockerRepeated" (turnOff)="setTurnOff($event)">
    </app-adblocker-turn-off>
    <app-cptr id="appcptr"></app-cptr>

    <!-- <div class="menu">
      <div class="fas fa-ellipsis-h" (click)="menuIsHidden = !menuIsHidden"></div>
      <div *ngIf="pages" class="pages" [ngClass]="{'hidden': menuIsHidden}">
        <a href="javascript:void(0);"
          [ngClass]="{'selected':(selectedPage ? selectedPage.customID === page.customID : false)}"
          (click)="changePage(page.customID)" *ngFor="let page of pages">{{page.title}}</a>
      </div>
    </div> -->

    <div *ngIf="selectedPage" class="waves" id="waves_container">
      <!-- <ng-container *ngFor="let wave of waves let i = index">
        <app-grid [event]="gridUpdated" [index]="waves.length - i" [wave]="wave" *ngIf="(i < max) && wave.isActive">
        </app-grid>
        <app-grid [index]="waves.length - i" [wave]="wave" *ngIf="testUserAgent()" [isPrerender]="testUserAgent()">
        </app-grid>
      </ng-container> -->

      <router-outlet></router-outlet>
    </div>


    <div class="section" id="addPwa">
      <div class="content has-text-centered">
        <p class="title is-2" (click)="addPwa()">
          <u>Ajouter l'application sur la page d'accueil</u>
        </p>
        <img src="../../../assets/img/Fermer.svg" alt="close3" class="close2" (click)="hidePwa()">
      </div>
    </div>
    <app-videocontainer></app-videocontainer>
    <app-footer [ngClass]="isStickyFoot && isTurnedOff ? 'stickyBottom' : 'd-none' " (scroll)="onStickyScroll($event)"
      [selectedPage]="selectedPage"></app-footer>
  </div>

</div>

<app-footer (scroll)="onStickyScroll($event)" id="footer" [ngClass]="{'stickyBottom': isStickyFoot}"
  [selectedPage]="selectedPage">
</app-footer>
