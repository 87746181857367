import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CartStatus, Tile } from '../types';
import { StorageService } from './storage.service';
import { Wave } from '../backoffice/models/wave';

declare global {
  interface Window {
    dataLayer: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CartService {
  get tilesIDs(): string[] {
    return this._tilesIDs;
  }

  set tilesIDs(value: string[]) {
    this._tilesIDs = value;
  }

  public cart = new Subject<Tile[]>();
  public cartStatus = new Subject<CartStatus>();
  // todo passer "lastAction" dans "cartStatus"
  public lastAction = new Subject<string>();

  public tiles: Tile[] = [];
  private _tilesIDs: string[] = [];

  constructor(private storageService: StorageService) {
    const saved = storageService.getCart();
    if (saved) {
      saved.forEach((s) => {
        this.add(s);
      });
    }
  }

  public add(tile: Tile, waveTitle?: string): void {
    if (!this.isInCart(tile.customID)) {
      this.tiles.unshift(tile);
      this._tilesIDs.unshift(tile.customID);
      this.update();
      if (typeof window.dataLayer !== 'undefined' && waveTitle) {
        window.dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            add: {
              products: [{
                name: tile.title,
                id: tile.customID,
                category: tile.type,
                brand: waveTitle,
                url: tile.url
              }]
            }
          }
        });
      }
      // todo passer "lastAction" dans "cartStatus"
      this.lastAction.next('added');
    }
  }

  public removeAll(): void {
    const tilesId = this.tilesIDs
    while (tilesId.length > 0) {
      this.remove(tilesId[0])
    }
  }

  public remove(tileID: string, waveTitle?: string): void {
    const idIndex = this.getIndexById(tileID);
    if (idIndex !== -1) {
      this.tiles.forEach((tile, i) => {
        if (tile.customID === tileID) {
          this._tilesIDs.splice(idIndex, 1);
          this.tiles.splice(i, 1);
          this.update();
          if (typeof window.dataLayer !== 'undefined' && waveTitle) {
            window.dataLayer.push({
              event: 'removeFromCart',
              ecommerce: {
                remove: {
                  products: [{
                    name: tile.title,
                    id: tile.customID,
                    category: tile.type,
                    brand: waveTitle,
                    url: tile.url
                  }]
                }
              }
            });
          }
          // todo passer "lastAction" dans "cartStatus"
          this.lastAction.next('removed');
        }
      });
    }
  }

  public toggleInCart(tile: Tile, wave?: Wave): void {
    const index = this.tiles.findIndex(t => t.customID === tile.customID);
    if (index >= 0) {
      this.remove(tile.customID, wave ? wave.title : undefined);
    } else {
      this.add({ ...tile }, wave ? wave.title : undefined);
    }
  }

  private getIndexById(tileID: string): number {
    return this._tilesIDs.indexOf(tileID);
  }

  public isInCart(tileID: string): boolean {
    return this.getIndexById(tileID) !== -1;
  }

  public open(tileID: string): void {
    this.cartStatus.next({ isOpen: true, tileID });
  }

  public close(): void {
    this.cartStatus.next({ isOpen: false, tileID: null });
  }

  private update(): void {
    this.cart.next(this.tiles);
    this.save();
  }

  private save(): void {
    this.storageService.setCart(this.tiles);
  }
}
