import { AuthService } from './../../services/auth.service';
import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  HostListener,
} from '@angular/core';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import {
  faExpand as expandIcon,
  faCompress as compressIcon,
  faShareAlt as shareIcon,
} from '@fortawesome/free-solid-svg-icons';
import { ZoomService } from 'src/app/services/zoom.service';
import { GridViewService } from 'src/app/services/gridView.service';
import { SocialshareService } from '../../services/socialshare.service';
import { CookieService } from 'ngx-cookie-service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { GridLayout } from 'src/app/enums/grid-layout';
import { DOCUMENT } from '@angular/common';
import { NavbarService } from '../../services/navbar.service';
import { FullPage } from '../../backoffice/models/full-page';
import { Tile } from '../../types';
import { CartService } from '../../services/cart.service';
import { GridComponent } from '../grid/grid.component';
import { User } from 'src/app/backoffice/models/user';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          visibility: 'visible',
          marginTop: '10px',
        })
      ),
      state(
        'closed',
        style({
          opacity: 0,
          marginTop: '-400px',
          marginBottom: '100px',
        })
      ),
      transition('open => closed', [animate('0.5s')]),
      transition('closed => open', [animate('0.5s')]),
    ]),
  ],
})
export class NavbarComponent implements OnInit {
  public expandIcon = expandIcon;
  public compressIcon = compressIcon;
  public shareIcon = shareIcon;
  public faHeart = faHeart;
  public passwordResetOpen: boolean;
  public passwordForgotOpen: boolean;
  public confirmEmailOpen: boolean;
  private menuNavOpen = false;
  @Input() isOpen = false;
  // output event emitter
  @Output() toggleMenuEvent = new EventEmitter<void>();
  public isOpenI = false;
  public gutter = 30;
  public isOptionsVisible: boolean;
  GridLayout = GridLayout;
  public gridLayout: GridLayout;
  public sticky = false;
  public isUserMenuOpen: boolean;
  public isRegisterOpen = false;
  public isLoginOpen = false;
  @Input() public isUserMenuScroll: boolean;
  @Input() selectedPage: FullPage;
  public tiles: Tile[] = [];
  @ViewChild(GridComponent) grids;
  public display = false;
  public innerWidth: boolean;

  constructor(
    @Inject(DOCUMENT) document,
    public zoomService: ZoomService,
    public gridViewService: GridViewService,
    private navbar: NavbarService,
    public socialshareService: SocialshareService,
    private AuthService: AuthService,
    private cartService: CartService,
    private cookieService: CookieService
  ) {
    this.gridViewService.gridLayout.subscribe((gridLayout: GridLayout) => {
      this.gridLayout = gridLayout;
    });
    this.tiles = this.cartService.tiles.reverse();
  }
  
  ngOnInit(): void {
    this.isUserMenuOpen = false;
    this.isOptionsVisible = false;
    if (window.innerWidth > 768) {
      this.innerWidth = true;
    } else {
      this.innerWidth = false;
      const DidactitielMobile = this.cookieService.get('DidactitielMobile')
      if (DidactitielMobile === ''){
        this.cookieService.set('DidactitielMobile', '1')
      }
      if (DidactitielMobile <= '2'){
        this.cookieService.set('DidactitielMobile', (Number(this.cookieService.get('DidactitielMobile')) + 1).toString())
        this.isOpen = true;
      }
      
    }
    //check reset password token
    const queryparam = new URLSearchParams(window.location.search);
    this.passwordResetOpen = !!queryparam.get('rpToken');
    this.confirmEmailOpen = !!queryparam.get('ceToken');
  }
  toggleMenuNav() {
    this.toggleMenuEvent.emit();
  }
  toggleReset() {
    this.passwordForgotOpen = !this.passwordForgotOpen;
  }
  toggleConfirmEmail() {
    this.confirmEmailOpen = !this.confirmEmailOpen;
  }
  public changeZoom(e): void {
    this.zoomService.changeZoom(e.value);
  }

  public zoomPlus(): void {
    this.zoomService.zoomPlus();
  }

  public zoomMoins(): void {
    this.zoomService.zoomMoins();
  }

  public show(elementId: string): void {
    document.getElementById(elementId).classList.remove('hide');
  }

  /* fonction permettant de faire aapraître/disparaître le carousel d'aide sur mobile */
  toggle(): void {
    this.isOpen = !this.isOpen;
  }
  redirect() {}
  @HostListener('window:resize', ['$event'])
  redirectt(): void {
    if (window.innerWidth > 768) {
      this.innerWidth = true;
    } else {
      this.innerWidth = false;
    }
  }
  toggleI(): void {
    this.isOpenI = !this.isOpenI;
  }

  setZoomTile(gridLayout: GridLayout, selectedPage: FullPage): void {
    if (selectedPage === undefined) {
      selectedPage = this.selectedPage;
    }

    for (const val of selectedPage.waves) {
      if (val.isActive) {
        if (window.innerWidth < 900) {
          this.zoomService.setZoomTile(gridLayout, val, val.gutterSizeMobile);
        } else {
          this.zoomService.setZoomTile(gridLayout, val, val.gutterSize);
        }
      }
    }
  }

  /* fonctions permettant d'afficher les différentes formes de la grid */
  updateLayout(gridLayout: GridLayout): void {
    this.gridViewService.gridLayout.next(gridLayout);
    this.setZoomTile(gridLayout, this.selectedPage);
  }

  toggleMenu(event): void {
    this.isUserMenuOpen = event;
  }
  toggleRegister(event): void {
    this.isRegisterOpen = event;
  }
  toggleLogin(value: boolean) {
    if (this.isRegisterOpen && value) this.toggleRegister(false);

    this.isLoginOpen = value;
  }
  lockScroll() {
    const e = document.head.parentElement;
    e.classList.toggle('lock-scroll');
  }

  toggleCart(): void {
    this.navbar.heartClick();
  }

  public openOptions(): void {
    this.isOptionsVisible = !this.isOptionsVisible;
    setTimeout(() => {
      this.isOptionsVisible = false;
    }, 3000);
  }

  socialShare(): void {
    this.display = true;

    this.socialshareService.nextMessage({
      showModal: true,
      page: this.selectedPage,
    });
  }
}
