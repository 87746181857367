import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MoreinformationComponent } from './components/moreinformation/moreinformation.component';
import { DemoPopinComponent } from './components/demo-popin/demo-popin.component';
import { PageComponent } from './components/pages/page.component';

const routes: Routes = [
  { path: 'moreinformation', component: MoreinformationComponent },
  { path: 'demopopin', component: DemoPopinComponent },
  { path: 'page/:customID/:title', component: PageComponent },
  { path: '', component: PageComponent } //A l'arrivée sur le site ca va charger la page appelée précisément 'accueil'. Voire le code dans Page.Component (code fait dans l'urgence)
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
