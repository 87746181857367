import { Injectable } from '@angular/core';
import { Tile } from '../types';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  isAdblockOn: boolean = false;

  constructor() { }

  set(key: string, obj: any): void {
    localStorage.setItem(key, JSON.stringify(obj));
  }

  get(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  // Cart
  setCart(tiles: Tile[]): void {
    this.set('cart', tiles);
  }

  getCart(): Tile[] {
    return this.get('cart');
  }

  // DESORMAIS LA METHODE LA PLUS EFFICACE POUR LA DETECTION D'ADBLOCKER. ELLE SERA A REMPLACER DANS TOUTE L'APPLICATION. FONCTIONNE AVEC UBLOCK ADBLOCK ET AUTRES
  logAdblocker() {
    const iframe = document.createElement('iframe');
    iframe.height = '1px';
    iframe.width = '1px';
    iframe.id = 'ad';
    iframe.src = 'https://domain.com/ads.html';

    document.body.appendChild(iframe);

    setTimeout(() => {
      const iframe = document.getElementById('ad');
      if (iframe.style.display === 'none' || iframe.style.display === 'hidden' || iframe.style.visibility ===
        'hidden' || iframe.offsetHeight === 0) {
        this.isAdblockOn = true;
        // alert("Adblock is blocking ads on this page");
        iframe.remove();
      } else {
        this.isAdblockOn = false;
        // alert("Adblock is not detecting ads on this page");
        iframe.remove();
      }
    }, 100);
  }
}
