<div class="wrapper" id="video-header">
  <div class="content">
    <div *ngIf="isOpen == true" class="dark-bg flex">
      <div *ngIf="!showTutorial" class="popin-container" id="header-text" style="align-items: center;">
        <i class="fas fa-times" [class.hidden]="!isDisable" (click)="Close();"></i>
        <i class="fas fa-times" [class.hidden]="isDisable" style="visibility: hidden;"></i>
        <div id="presentation" [class.hidden]="presentationNone">
          <h1>Pourquoi Mediashow ?</h1>
          <p>
            Pour financer vos médias en affichant, ici, les publicités que vous ne voulez pas voir, là-bas, sur vos
            sites habituels.
          </p>
          <p>
            Mediashow est aussi un catalogue de découverte rapide des médias : live TV, radio, replay, podcasts, VOD ...
          </p>
        </div>
        <div id="tutorial" *ngIf="adblocker" style="display: flex; flex-direction: column;">
          <h1>Juste un petit détail qui change tout …</h1>
          <p style="text-align: center;">
            Pour voir les annonces des marques et des médias qui financeront vos sites favoris,
            <br>
            <span>Pouvez-vous désactiver votre bloqueur de publicité</span>
            <br>mais uniquement pour Mediashow.
          </p>
          <div class="button_container">
            <a class="button" id="ok-btn" (click)="reloadAfterDisable();"
              style="color: white; background-color: rgb(57, 196, 216);">OK c’est fait</a>
            <!-- <a class="button" [class.hidden]="isDisable" id="ok-btn"
              style="color: white; background-color: rgb(57, 196, 216);">OK c’est fait</a> -->
            <a class="button" id="how-btn" (click)="slideTwoDelayCroix();">Comment faire ?</a>
          </div>
        </div>
        <button id="fermer" *ngIf="!presentationNone" [disabled]="!isDisable" (click)="Close();">Fermer</button>
      </div>

      <div *ngIf="showTutorial" id="tutorial" class="popin-container">
        <i [class.hidden]="!isDisable2" class="fas fa-times" (click)="isOpen=false;Close();"></i>
        <i [class.hidden]="!isDisable2" class="fas fa-times" style="visibility: hidden;"></i>
        <p> Quel bloqueur de pub utilisez vous ?</p>
        <div class="tuto_choice">
          <div class="button_list">
            <div class="buttonAdblocker" [class.button_clicked]="adBlockPlus"
              (click)="chooseAdBlock(adBlockPlus ? '' : 'adBlockPlus')">
              <img src="../../../assets/img/adblockpluslogo1.svg" alt="" class="buttonleft">
              <p>AdBlock Plus</p>
            </div>
            <div class="buttonAdblocker" [class.button_clicked]="adBlock"
              (click)="chooseAdBlock(adBlock ? '' : 'adBlock')">
              <img src="../../../assets/img/adblocklogo.svg" alt="" class="adblocklogo">
              <p>AdBlock</p>
            </div>
            <div class="buttonAdblocker" [class.button_clicked]="uBlock"
              (click)="chooseAdBlock(uBlock ? '' : 'uBlock')">
              <img src="../../../assets/img/ublocklogo1.svg" alt="" class="buttonleft">
              <p>Ublock Origin</p>
            </div>
            <div class="buttonAdblocker" [class.button_clicked]="ghostery"
              (click)="chooseAdBlock(ghostery ? '' : 'ghostery')">
              <img src="../../../assets/img/ghosterylogo.svg" alt="" class="ghosterylogo">
              <p>Ghostery</p>
            </div>
          </div>
          <div class="demo_area">
            <div *ngIf="text">
              <p>
                Sélectionner votre bloqueur de publicités pour voir ici comment le désactiver
              </p>
            </div>
            <div *ngIf="adBlockPlus" class="gif">
              <img src="../../../assets/img/adblockplus.gif" alt="">
            </div>

            <div *ngIf="adBlock" class="gif">
              <img src="../../../assets/img/adblock.gif" alt="">
            </div>

            <div *ngIf="uBlock" class="gif">
              <img src="../../../assets/img/ublock.gif" alt="">
            </div>

            <div *ngIf="ghostery" class="gif">
              <img src="../../../assets/img/ghostery.gif" alt="">
            </div>
          </div>
        </div>
        <div class="button_container">
          <a class="button ok-btn" (click)="reloadAfterDisable();toggleVolume();">OK c’est fait</a>
          <a class="button how-btn" (click)="slideOne();">Retour</a>
        </div>
      </div>
    </div>
    <a id="scroll-down">
    </a>
    <div class="controls">
    </div>
  </div>
</div>