<!-- <div class="notifs">
    <div class="notif" *ngFor="let notif of notifications">
        <p *ngFor="let text of notif.texts">{{ text }}</p>
        <button *ngFor="let button of notif.buttons" class="btn" [ngClass]="button.class" (click)="do(notif.id, button.action)">
      {{ button.text }}
    </button>
    </div>
</div> -->

<!-- notif1 -->
<div class="notif_chevron chevron2" [ngClass]="isOpen2 ? 'none' : 'display'" (click)="notif2();intro();">
  <i class="fas fa-info"></i>
</div>
<div class="line_notif line_notif2" [ngClass]="isOpen2 ? 'display' : 'none'" [@openClose2]="isOpen2 ? 'open2line' : 'closed2line'"></div>
<div class="notif notif2" [@openClose2]="isOpen2 ? 'open2' : 'closed2'">
  <div *ngIf="concept1" class="concept">
    <div class="message_notif">
      <div class="texte_notif">
        <p>Avez-vous testé toutes les icones sur chaque message? Faîtes des essais pour voir à quoi elles servent.</p>
      </div>
      <div class="icones">
        <div class="icone">
          <i class="fas fa-times"></i>
        </div>
        <div class="icone">
          <i class="fas fa-heart"></i>
        </div>
        <div class="icone">
          <i class="fas fa-eye"></i>
        </div>
        <div class="icone">
          <i class="fas fa-external-link-alt"></i>
        </div>
        <div class="icone">
          <i class="fas fa-share-alt"></i>
        </div>
        <div class="icone">
          <i class="fas fa-images"></i>
        </div>
      </div>
    </div>
    <a class="plus" (click)="zoomEye()">
      <div class="img_chevron2 arrow">
        <i class="fas fa-chevron-right"></i>
      </div>
    </a>
  </div>

  <div *ngIf="concept2" class="talkZoom">
    <div class="message_notif">
      <div class="texte_notif">
        <p>Avez-vous testé le zoom ? C'est essentiel pour tout voir en 1 clin d'oeil. Choisissez la formule qui vous convient le mieux ! </p>
      </div>
      <div class="zoom1">
        <div class="zooms1">
          <p (click)="changeZoom(100)">100%</p>
          <p (click)="changeZoom(80)">80%</p>
          <p (click)="changeZoom(60)">60%</p>
          <p (click)="changeZoom(40)">40%</p>
        </div>
      </div>
    </div>
    <a class="plus" (click)="screen()">
      <div class="img_chevron3 arrow">

        <i class="fas fa-chevron-right"></i>
      </div>
    </a>
    <a class="plus" (click)="intro()">
      <div class="img_chevron4 arrow">
        <i class="fas fa-chevron-left"></i>
      </div>
    </a>
  </div>



  <div *ngIf="concept3" class="message_notif">
    <div class="texte_notif">
      <p>
        Avez-vous choisi votre mode d'affichage ? Essayez pour voir ce qui vous convient le mieux.
      </p>
    </div>
    <div class="zoom2">
      <div class="zooms2">
        <div>
          <i _ngcontent-asa-c87="" class="fas fa-desktop ng-tns-c87-0"></i>
        </div>

        <div><img src="../../../assets/img/one_square.svg" alt="one_square" (click)="updateLayout(GridLayout.ONESQUARE)"></div>
        <div><img src="../../../assets/img/four_squares.svg" alt="four_squares" (click)="updateLayout(GridLayout.FOURSQUARE)"></div>
        <div><img src="../../../assets/img/nine_squares.svg" alt="nine_squares" (click)="updateLayout(GridLayout.NINESQUARE)"></div>
        <div><img src="../../../assets/img/expand-solid 1.svg" alt="full_screen" (click)="openFullscreen()"></div>
      </div>
    </div>
    <a class="plus" (click)="zoomEye()">
      <div class="img_chevron5 arrow">
        <i class="fas fa-chevron-right"></i>
      </div>
    </a>
  </div>


  <div class="chevron" (click)="notif2()">
    <div class="img_chevron">
      <img src="../../../assets/img/chevron-right-solid 1.svg" alt="chevron">
    </div>
  </div>
</div>