<div class="box">
    <div class="box-login">
        <p class="login">
            Email confirmation
        </p>
    </div>
        <div class="text">
            <label *ngIf="!(success || error)">Veuillez patienter ...</label>
            <label *ngIf="error && errorMessage.length == 0">Une erreur s'est produite lors de la confirmation de votre adresse email</label>
            <label *ngIf="error && errorMessage.length > 0">{{errorMessage}}</label>
            <label *ngIf="success">Adresse email confirmé</label>
        </div>

    <button *ngIf="success || error" type="submit" class="continue" (click)="closePopin()">Fermer</button>
</div>
