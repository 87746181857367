<div [@openClose]="isSidebarOpen?'open':'closed'" class="sidebarContainer" (mouseover)="mouseOverSidebar()" (mouseleave)="mouseLeaveSidebar()">
  <div *ngIf="tiles.length > 0 && tiles.length < 100" id="round">
    {{ tiles.length }}
  </div>
  <div *ngIf="tiles.length > 99" id="round">
    99+
  </div>
  <i (click)="toggleFastCart()" class="fas fa-2x fa-heart"></i>
  <hr>
  <!-- <img *ngIf="isMobile" class="fa-info" src="../../../assets/img/info.svg" (click)="toggleInfoMobile()">
  <img *ngIf="!isMobile" class="fa-info" src="../../../assets/img/info.svg" (click)="toggleNotifiaction()"> -->
  <!-- <i class="fas fa-2x fa-info" (click)="toggleNotifiaction()"></i> -->
  <div *ngIf="!isMobile">
  <hr>
  <div class="display-format">
    <img src="../../../assets/img/desktop.svg" *ngIf="selectionDisplayFormat == false" (click)="openFormats()" class="fa-desktop">
    <!-- <i *ngIf="selectionDisplayFormat == false" (click)="openFormats()" class="fas fa-2x fa-desktop"></i> -->
    <div *ngIf="selectionDisplayFormat == true" class="selectionDisplayFormat">
      <img src="../../../assets/img/desktop.svg" (click)="openFormats()" class="fas fa-2x fa-desktop">
      <img src="assets/img/one_square.svg" alt="" (click)="updateLayout(GridLayout.ONESQUARE)" class="fas">
      <img src="assets/img/four_squares.svg" alt="" (click)="updateLayout(GridLayout.FOURSQUARE)" class="fas">
      <img src="assets/img/nine_squares.svg" alt="" (click)="updateLayout(GridLayout.NINESQUARE)" class="fas">
      <i *ngIf="!this.gridViewService.checkFullscreen()" (click)="this.gridViewService.openFullscreen()"
        class="fas fa-2x fa-expand"></i>
      <i *ngIf="this.gridViewService.checkFullscreen()" (click)="this.gridViewService.closeFullscreen()"
        class="fas fa-2x fa-compress"></i>
    </div>
  </div>
</div>
  <hr>
  <img src="../../../assets/img/sun-img.svg" *ngIf="darkTheme == false" class="fa-sun" (click)="switchLightMode()">
  <hr *ngIf="darkTheme == false">
  <i *ngIf="darkTheme == true" class="fas fa-2x fa-moon" (click)="switchLightMode()"></i>
  <hr *ngIf="darkTheme == true">
  <img src="../../../assets/img/share.svg" class="fa-share" (click)="socialShare()">
  <hr>
  <img src="../../../assets/img/comment.svg" (click)="toggleFeedback()" class="fa fa-2x fa-comments">
  <!-- <hr>
  <img src="../../../assets/img/star.svg"> -->
  <i *ngIf="isSidebarOpen" class="fas fa-2x fa-chevron-right toggleSidebarNone" (click)="toggleSidebar()"></i>
  <i *ngIf="!isSidebarOpen" class="fas fa-2x fa-chevron-left toggleSidebarNone" (click)="toggleSidebar()"></i>
</div>

<!-- <div *ngIf="isSidebarOpen == false" class="toggleSidebar" (click)="toggleSidebar()">
  <i class="fas fa-2x fa-chevron-left" ></i>
</div> -->
