import { keyframes, style,animate } from '@angular/animations';

export const SlideinUp = [
  style({ transform: 'translate3d(0, 150%, 0)', opacity:0}),
  style({transform: 'translate3d(0, 0, 0)', opacity:1})
]
export const swiperight = [
  style({ opacity: 1 }),
  style({ transform: 'translate3d(350%, 0, 0) rotate3d(0, 0, 1, 20deg)', opacity: 0 }),
]

export const swipeleft = [
  style({ opacity: 1 }),
  style({ transform: 'translate3d(-350%, 0, 0) rotate3d(0, 0, 1, -20deg)', opacity: 0 }),
]
export const swipeup = [
  style({ opacity: 1 }),
  style({
    transform: 'translate3d(0, -100%, 0) rotate3d(0, 0, 1, 0deg)',
    opacity: 0
  })
];

export const swipedown = [
  style({ opacity: 1 }),
  style({
    transform: 'translate3d(0, 100%, 0) rotate3d(0, 0, 1, 0deg)',
    opacity: 0
  })
];
