import { Component, Input, OnInit, HostListener } from '@angular/core';
import { FootersService } from '../../services/footers.service';
import { SocialshareService } from '../../services/socialshare.service';
import { Tile } from '../../types';
import { FullPage } from '../../backoffice/models/full-page';
import { CartService } from '../../services/cart.service';
import { NavbarService } from 'src/app/services/navbar.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  show = 'none';
  @Input() public selectedPage: FullPage;
  @Input() public isUserMenuScroll: boolean;
  isMobile = false;
  private timer: any;
  constructor(
    private footer: FootersService,
    public socialshareService: SocialshareService,
    private cartService: CartService,
    private navbar: NavbarService
  ) {
    this.tiles = this.cartService.tiles.reverse();
  }

  public tiles: Tile[] = [];

  ngOnInit(): void {
    this.isMobile = window.innerWidth < 900;
  }
  @HostListener('body:resize')
  public resize(): void {
    this.isMobile = window.innerWidth < 900;
  }
  toggleCart(): void {
    this.navbar.heartClick();
  }

  socialShare(): void {
    this.socialshareService.nextMessage({
      showModal: true,
      page: this.selectedPage,
    });
  }

  toggleDiv() {
    this.show = 'true';
  }
  // @HostListener('footer:mouseleave', ['$event'])
  onBlur() {
    if (this.timer) return;
    this.timer = setTimeout(() => {
      this.show = 'none';
      this.timer = undefined;
    }, 3000);
  }
  onEnter() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }
}
