<footer (mouseleave)="onBlur()" (mouseenter)="onEnter()">
  <button *ngIf="show == 'none' && !isMobile" class="legal" type="button" (click)="toggleDiv()">Informations légales</button>
  <span *ngIf="show == 'none' && !isMobile" class=" btn-copyright">©Copyright 2022 - MEDIASHOW. All rights reserved.</span>
  <div class="section" id="footer">
    <div class="level-left has-text-centered">
      <div style="scale(selectedPage)" class="level-item">
        <div class = 'heart-container'>
          <fa-icon (click)="toggleCart()" class="fas fa-3x fa-heart" style="color:red"></fa-icon>
          <span class="numeroCoeur" *ngIf="tiles.length < 10" (click)="toggleCart()">{{ tiles.length }}</span>
          <span class="numeroCoeur2" *ngIf="tiles.length >=10" (click)="toggleCart()">{{ tiles.length }}</span>
        </div>
        <fa-icon class="fas fa-2x fa-share-alt" style="color:gray" (click)="socialShare()"></fa-icon>

      </div>
    </div>
  </div>
  <img *ngIf="show == 'true'" class="imgmediashow" src="./../../../assets/img/logo.svg" alt="logo Mediashow">
  <div *ngIf="show == 'true'" class="footerLinks">
    <div class="lineTop">
      <a href="https://mediashow.fr/corporate" target="_blank">Qui sommes nous ? |</a>
      <a href="https://beta1.mediashow.fr/page/d0dcc7b5-39f5-437f-b168-e5af41e18bd6/informations-legales" target="_blank">Mentions légales |</a>
      <a href="https://beta1.mediashow.fr/page/d0dcc7b5-39f5-437f-b168-e5af41e18bd6/informations-legales" target="_blank">Données & Cookies |</a>
      <a href="https://beta1.mediashow.fr/page/d0dcc7b5-39f5-437f-b168-e5af41e18bd6/informations-legales" target="_blank">C.G.U</a>
    </div>
    <div class="lineBottom">
      <!-- <p>Jobs |</p> -->
      <a href="https://mediashow.fr/corporate/#copyright" target="_blank">Nous Contacter</a>
    </div>
  </div>
  <p *ngIf="show == 'true'" class="copyright">
    &copy;2022 Mediashow
  </p>

</footer>
