export const environment = {
  production: true,
  apiUrl: 'https://edgarapi.mediashow.fr',
  endpoint: 'https://preprod-api.mediashow.fr',
  firebase: {
    apiKey: "AIzaSyAu7DyF7qAECt81yqoO_Uu8YytO6Ruh5bI",
    authDomain: "mediashow-8a81c.firebaseapp.com",
    projectId: "mediashow-8a81c",
    storageBucket: "mediashow-8a81c.appspot.com",
    messagingSenderId: "801749077197",
    appId: "1:801749077197:web:1a37b431ed928b62526d84",
    measurementId: "G-Q8FTD6ZLZJ"
  }
};
