import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, ...args: any[]): unknown {
    if (!args && args.length < 2) {
      return value;
    } else {
      const from = new RegExp(args[0], 'gi');
      const v = value.replace(from, args[1]);
      return v;
    }
  }
}
