import { RESTFulObject } from '../models/restful';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-type': 'application/json'
      }
    )
  };

  constructor(protected http: HttpClient,
    protected config: ConfigService) {

  }

  protected get<T>(item: string): Observable<T> {
    return this.http.get<RESTFulObject>(`${this.config.endpoint}/${this.config.extension(item)}`, this.httpOptions).pipe(
      map((object: RESTFulObject) => {
        return object.data as T;
      })
    );
  }

  protected upload<T>(body: any): Observable<any> {
    const options = { ...this.httpOptions };
    options.headers = options.headers.set('Content-Type', 'multipart/form-data');
    return this.http.post<string>(`${this.config.endpoint}/file`, body, options);
  }

  protected post<T>(item: string, body: any, header?: HttpHeaders): Observable<any> {
    return this.http.post(`${this.config.endpoint}/${item}`, body, this.httpOptions);
  }

  protected delete<T>(item: string): Observable<any> {
    return this.http.delete(`${this.config.endpoint}/${item}`, this.httpOptions);
  }
}
