import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Feedback {
  constructor(origin: string) {
    this.origin = origin;
    this.message = '';
    this.actualpage = '';
    this.timepassed = 0;
  }

  public message !: string;
  public actualpage !: string;
  public timepassed !: number;
  public origin !: string;
}
@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(private httpClient: HttpClient) { }

  private endpoint = environment.endpoint + '/feedbacks';
  private options = {
    headers: {
      accept: 'application/json'
    }
  };

  public addFeedbackData(Feedback: Feedback) {
    return this.httpClient.put<Feedback>(this.endpoint, Feedback, this.options);
  }

  public getFeedbackData(): Observable<Feedback[]> {
    return this.httpClient.get<Feedback[]>(this.endpoint, this.options);
  }
}
