import { TileActionName } from '../../enums/tile-action';
import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TileActionEvent } from 'src/app/backoffice/models/tile-action-event';
import { Tile } from 'src/app/types';
import { CompteurService } from 'src/app/services/compteur.service';

declare global {
  interface Window {
    dataLayer: any;
  }
}
@Component({
  selector: 'app-tile-action',
  templateUrl: './tile-action.component.html',
  styleUrls: ['./tile-action.component.scss']
})
// [inCart]="true" [enabledActions]="enabledActions" [tile]="tile" [zoom]="60" (action)="doAction($event)"
export class TileActionComponent implements OnInit {
  @Output() action = new EventEmitter<TileActionEvent>();
  @Input() enabledActions: TileActionName[] = [];
  @Input() tile: Tile;
  @Input() show = false;
  @Input() waveTitle: string;
  @Input() inCart = false;

  inside = false;
  TileActionName = TileActionName;
  flagSize = 38;

  private _zoom: number;
  public pip = false;

  @Input()
  get zoom(): number {
    return this._zoom;
  }

  set zoom(value: number) {
    this._zoom = value / 100;
    // this.el.nativeElement.style.setProperty('--action-scale', this._zoom);
    this.el.nativeElement.style.setProperty('--icon-size', `${(this.flagSize * this._zoom)}px`);
    this.el.nativeElement.style.setProperty('--ticket-width', `${((this.flagSize + 10) * this._zoom)}px`);
  }

  @HostBinding('class.toogled') get shown(): boolean { return this.show || this.inside; }
  @HostListener('mouseenter', ['$event'])
  checkInside(event: MouseEvent): void {
    this.inside = true;
  }

  @HostListener('mouseleave', ['$event'])
  checkOutside(event: MouseEvent): void {
    setTimeout(() => {
      this.inside = false;
    }, 200);
  }

  togglePip(value?: boolean): void {
    if (this.pip === false) {
      this.el.nativeElement.style.setProperty('bottom', '50px');
      this.el.nativeElement.style.setProperty('--right', '50px');
      this.el.nativeElement.style.setProperty('--position', 'fixed');
      this.pip = true;
    } else {
      this.el.nativeElement.style.removeProperty('bottom');
      this.el.nativeElement.style.setProperty('top', 0);
      this.el.nativeElement.style.setProperty('--right', 0);
      this.el.nativeElement.style.setProperty('--position', 'absolute');
      this.pip = false;
    }
  }

  isEnabled(action: TileActionName): boolean {
    let isEnabled = true;
    if (this.tile.type !== 'video' && action === TileActionName.PIP) {
      isEnabled = false
    }
    if (this.enabledActions.length > 0) {
      isEnabled = this.enabledActions.find(a => a === action) !== undefined;
    }
    // if (action !== TileActionName.CLOSE && this.tile.customID.length == 5) {
    // isEnabled = false;
    // }
    return isEnabled;
  }

  emit(actionName: TileActionName): void {
    this.cptrService.NewTileAction(actionName, this.tile.customID)
    // if (this. == true) actionName = TileActionName.CANCEL_EXPAND;
    const action = new TileActionEvent();
    action.action = actionName;
    action.tile = this.tile;
    this.action.emit(action);
    this.emitDataLayer(actionName);
  }

  emitDataLayer(actionName: TileActionName): void {
    if (actionName === TileActionName.ADDCART || actionName === TileActionName.PIP) {
      return;
    }
    if (typeof window.dataLayer !== 'undefined' && this.waveTitle) {
      switch (actionName) {
        case TileActionName.CLOSE:
          window.dataLayer.push({
            event: actionName,
            ecommerce: {
              close: {
                products: [{
                  name: this.tile.title,
                  id: this.tile.customID,
                  wave: this.waveTitle
                }]
              }
            }
          });
          break;
        case TileActionName.WATCH:
          window.dataLayer.push({
            event: actionName,
            ecommerce: {
              watch: {
                products: [{
                  name: this.tile.title,
                  id: this.tile.customID,
                  wave: this.waveTitle
                }]
              }
            }
          });
          break;
        case TileActionName.EXTERNAL_LINK:
          window.dataLayer.push({
            event: actionName,
            ecommerce: {
              external: {
                products: [{
                  name: this.tile.title,
                  id: this.tile.customID,
                  wave: this.waveTitle
                }]
              }
            }
          });
          break;
        case TileActionName.SOCIAL_SHARE:
          window.dataLayer.push({
            event: actionName,
            ecommerce: {
              share: {
                products: [{
                  name: this.tile.title,
                  id: this.tile.customID,
                  wave: this.waveTitle
                }]
              }
            }
          });
          break;
        default:
      }
    }
  }

  constructor(private el: ElementRef, private cptrService: CompteurService) {

  }

  ngOnInit(): void {
    // this.el.nativeElement.style.setProperty('--tmpwidth', `${this.tile!.size.width * 0.7}px`);

    // this.el.nativeElement.style.setProperty('--width',this.tile.size.width);
  }
}
