import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { VideoNode } from 'src/app/models/videoview';

@Injectable({
  providedIn: 'root'
})
export class VideoviewService {
  public lastNode = new Subject<VideoNode>();
  public closedNode = new Subject<VideoNode>();

  constructor() {}

  view(node: VideoNode): void {
    this.lastNode.next(node);
  }

  close(node: VideoNode): void {
    this.closedNode.next(node);
  }
}
