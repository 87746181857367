<div [@openClose]="showMenu?'open':'closed'" id="menu" class="container">
  <i class="fas fa-2x fa-times" (click)="toogleMenu()"></i>
  <div class="main">
    <div id="list">
      <a class="listItem active" (click)="sendLog(1)">General</a>
      <a class="listItem" (click)="sendLog(2)">Tendances</a>
      <a class="listItem" (click)="sendLog(3)">Pages</a>
    </div>
    <div *ngIf="displayedCategories == 1" class="categories">
      <div *ngFor="let page of generalPages" class="icon" style="background: linear-gradient({{page.menuColor}}, {{page.menuColorGradient}});"
        routerLink="/page/{{ page.customID }}/{{ page.title.replaceAll(' ', '-').toLowerCase() }}" (click)="toogleMenu()">{{page.title}}</div>
    </div>
    <div *ngIf="displayedCategories == 2" class="categories">
      <div *ngFor="let page of trendingPages" class="icon" style="background: linear-gradient({{page.menuColor}}, {{page.menuColorGradient}});"
        routerLink="/page/{{ page.customID }}/{{ page.title.replaceAll(' ', '-').toLowerCase() }}" (click)="toogleMenu()">{{page.title}}</div>
    </div>
    <div *ngIf="displayedCategories == 3" class="categories">
      <div *ngFor="let page of pages">
        <div *ngIf="page.categoriePage != 'Invisible'" class="icon" style="background: linear-gradient({{page.menuColor}}, {{page.menuColorGradient}});"
        routerLink="/page/{{ page.customID }}/{{ page.title.replaceAll(' ', '-').toLowerCase() }}" (click)="toogleMenu()">
          {{page.title}}
        </div>
        </div>

    </div>
  </div>
</div>

<div *ngIf="!mobile" [@hide]="showMenu?'hidden':'visible'" class="container">
  <i class="fas fa-2x fa-bars" (click)="toogleMenu()"></i>
</div>
