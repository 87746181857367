<div class="widget_bg hide" id="widget-moreinformation">
  <div class="move-down">
    <div class="widget">
      <div class="div_close" (click)="hide()" (click)="help(true)">
        <img src="../../../assets/img/times-solid 1.svg" alt="close" class="close">
      </div>
      <div class="div_img_header">
        <img src="../../../assets/img/duo.svg" alt="duo" class="img_header">
      </div>

      <div *ngIf="concept === true" class="concept">
        <h3 class="titleWidget">Comprendre le concept Mediashow</h3>
        <div class="pWidget_texte">
          <p class="pWidget">Mediashow vous permet de conserver votre bloqueur de publicité et de refuser les cookies
            sur vos sites habituels</p>
          <p class="pWidget">Tout en les finançant en affichant, ici, les publicités que vous ne voulez pas voir, la
            bas. Nous leur reversons les revenus réalisés ici.</p>
          <p class="pWidget">Cette plateforme est aussi un site de découverte des médias et des réseaux sociaux :
            essayez, vous comprendrez.</p>
          <p class="pWidget underline">Pour essayer maintenant</p>
          <p class="pWidget">Désactivez votre bloqueur de publicités, uniquement pour Mediashow.</p>
        </div>

        <div class="buttons">
          <a class="plus" (click)="help(false)">
            <p class="p_discover">Comment faire ?</p>
          </a>

          <a class="discover" (click)="hide()" (click)="help(true)">
            <p class="p_discover">Ok, c'est fait !</p>
          </a>
        </div>
      </div>

      <div *ngIf="concept === false" class="how_to_do">
        <div class="blockerlist">
          <p class="askChoice"> Quel bloqueur de pub utilisez vous ?</p>
          <div class="buttonList">
            <div class="buttonAdblocker" [ngClass]="{'buttonClicked': adBlockPlus, 'unclicked': !adBlockPlus}"
              (click)="eventAdBlock('adBlockPlus')">
              <img src="../../../assets/img/adblockpluslogo.svg" alt="" class="adblockpluslogo">
              <p>AdBlock Plus</p>
            </div>
            <div class="buttonAdblocker" [ngClass]="{'buttonClicked': adBlock, 'unclicked': !adBlock}"
              (click)="eventAdBlock('adBlock')">
              <img src="../../../assets/img/adblocklogo.svg" alt="" class="adblocklogo">
              <p>AdBlock</p>
            </div>
            <div class="buttonAdblocker" [ngClass]="{'buttonClicked': uBlock, 'unclicked': !uBlock}"
              (click)="eventAdBlock('uBlock')">
              <img src="../../../assets/img/ublocklogo.svg" alt="" class="ublocklogo">
              <p>Ublock Origin</p>
            </div>
            <div class="buttonAdblocker" [ngClass]="{'buttonClicked': ghostery, 'unclicked': !ghostery}"
              (click)="eventAdBlock('ghostery')">
              <img src="../../../assets/img/ghosterylogo.svg" alt="" class="ghosterylogo">
              <p>Ghostery</p>
            </div>
          </div>
        </div>
        <div class="gifad">
          <div class="tutoArea">
            <div *ngIf="adBlockPlus" class="gif">
              <img src="../../../assets/img/adblockplus.gif" alt="">
            </div>

            <div *ngIf="adBlock" class="gif">
              <img src="../../../assets/img/adblock.gif" alt="">
            </div>

            <div *ngIf="uBlock" class="gif">
              <img src="../../../assets/img/ublock.gif" alt="">
            </div>

            <div *ngIf="ghostery" class="gif">
              <img src="../../../assets/img/ghostery.gif" alt="">
            </div>
          </div>
          <a class="done" (click)="hide()" (click)="help(true)">
            <p class="p_discover">Ok, c'est fait !</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
