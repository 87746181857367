import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { DarkModeService } from 'angular-dark-mode';
import { GridViewService } from 'src/app/services/gridView.service';
import { CartService } from 'src/app/services/cart.service'
import { GridLayout } from 'src/app/enums/grid-layout';
import { SocialshareService } from 'src/app/services/socialshare.service';
import { faShareAlt as shareIcon } from '@fortawesome/free-solid-svg-icons';
import { trigger,state,style,transition,animate } from '@angular/animations';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state(
        'open',
        style({
          transform:'translateX(0)'
        })
      ),
      state(
        'closed',
        style({
          transform: 'translateX(120%)'
        })
      ),
      transition('open => closed', [animate('0.5s 0s ease-in')]),
      transition('closed => open', [animate('0.5s 0s ease-out')]),
    ])
  ]
})
export class SidebarComponent implements OnInit, OnDestroy {
  darkMode$ = this.darkModeService.darkMode$;
  darkTheme: boolean;
  selectionDisplayFormat: boolean = false;
  gridLayout: GridLayout;
  GridLayout = GridLayout;
  tiles;
  @Output() updateGrid = new EventEmitter<any>();
  @Output() showFeedback = new EventEmitter<any>();
  @Output() showNotification = new EventEmitter<any>();
  @Output() showInfoMobile = new EventEmitter<any>();
  @Output() showFastCart = new EventEmitter<any>();
  @Input() selectedPage;
  isMobile : boolean = false;
  timeoutSideBar;
  showFeedbackBool: boolean = false;
  showNotificationBool: boolean = false;
  showInfoMobileBool : boolean = false;
  showSocials: boolean = false;
  isSidebarOpen = true;
  isIopen = false;
  public shareIcon = shareIcon;

  constructor(
    private darkModeService: DarkModeService,
    private gridViewService: GridViewService,
    private cartService: CartService,
    private socialShareService: SocialshareService
  ) {}

  async ngOnInit() {
    this.darkModeService.darkMode$.subscribe((data) => {
      this.darkTheme = data;
      if (this.darkTheme === true) {
        document.documentElement.style.setProperty('background', '#2d3436');
      }
    });

    if (window.innerWidth < 1024){
      this.isMobile = true;
    }

    this.timeoutSideBar = setTimeout(() => {
      this.isSidebarOpen = false;
    }, 6 * 1000);
    
    this.tiles = this.cartService.tiles.reverse();
    this.cartService.cart.subscribe((tiles) => {
      this.tiles = tiles.reverse();
    });

    this.gridViewService.gridLayout.subscribe((gridLayout: GridLayout) => {
      this.gridLayout = gridLayout;
    });
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.selectionDisplayFormat = false;
  }
  mouseOverSidebar(){
    clearTimeout(this.timeoutSideBar);
  }

  mouseLeaveSidebar(){
    this.timeoutSideBar = setTimeout(() => {
        this.isSidebarOpen = false;
        this.selectionDisplayFormat = false;
      }, 6 * 1000);
  }

  switchLightMode() {
    this.darkModeService.toggle();
    if (this.darkTheme === true) {
      document.documentElement.style.setProperty('background', '#2d3436');
    }
    if (this.darkTheme === false) {
      document.documentElement.style.setProperty('background', 'white');
    }
  }

  openFormats() {
    this.selectionDisplayFormat = !this.selectionDisplayFormat;
  }

  updateLayout(gridLayout): void {
    this.gridViewService.gridLayout.next(gridLayout);
    this.updateGrid.emit(gridLayout);
  }

  toggleFeedback() {
    this.showFeedbackBool = !this.showFeedbackBool;
    this.showFeedback.emit(this.showFeedbackBool);
  }

  socialShare(): void {
    if (this.showSocials === true) {
      this.socialShareService.nextMessage({
        showModal: false,
        tile: undefined,
      });
    } else {
      this.socialShareService.nextMessage({
        showModal: true,
        page: this.selectedPage,
      });
    }
    this.showSocials = !this.showSocials;
  }

  toggleNotifiaction() {
    this.showNotification.emit(this.showNotificationBool);
  }
  toggleInfoMobile() {
    this.showInfoMobile.emit(this.showInfoMobileBool);
  }

  toggleFastCart() {
    this.showFastCart.emit(1);
  }

  async ngOnDestroy() {}
}
