import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-chiffre',
  templateUrl: './chiffre.component.html',
  styleUrls: ['./chiffre.component.scss']
})
export class ChiffreComponent {
  constructor(private el: ElementRef) { }

  @ViewChild('chiffre') chiffre!: ElementRef;
  @Input('placement') placement: number = 0;
  @Output() Sender: EventEmitter<DataCptr> = new EventEmitter<DataCptr>()
  Nb: number = 0;
  CptrNb: number = 0;
  Sens: boolean = true;

  Init(Nb: number, Sens: boolean = true) {
    if (Nb > 0) {
      this.Sens = Sens;
      this.Nb = Nb - 1;
      this.CleanAnimation();
      if (this.Sens) {
        this.chiffre.nativeElement.classList.add('up');
      } else {
        this.chiffre.nativeElement.classList.add('down2');
      }
    }
  }

  CleanAnimation() {
    this.chiffre.nativeElement.classList.remove('up');
    this.chiffre.nativeElement.classList.remove('down');
    this.chiffre.nativeElement.classList.remove('up2');
    this.chiffre.nativeElement.classList.remove('down2');
    this.el.nativeElement.style.setProperty('--display', 'block');
  }

  SetTimeRotation(value: number) {
    if (this.placement !== 0) {
      value = this.placement * 5 * value
    }
    this.el.nativeElement.style.setProperty('--time', value + 's');
  }

  End(event: any) {
    if (this.Sens) {
      if (event.animationName === 'up') {
        this.el.nativeElement.style.setProperty('--display', 'none');
        let nextNumber = Number(this.chiffre.nativeElement.innerHTML) + 1;
        if (nextNumber === 10) {
          nextNumber = 0;
          this.Sender.emit({ Id: this.placement, Sens: this.Sens })
        }
        this.CptrNb = nextNumber;
        this.chiffre.nativeElement.innerHTML = nextNumber;
        this.chiffre.nativeElement.classList.remove('up')
        setTimeout(() => this.chiffre.nativeElement.classList.add('down'), 10)
      } else {
        this.el.nativeElement.style.setProperty('--display', 'block');
        this.chiffre.nativeElement.classList.remove('down');
        if (this.Nb > 0) {
          this.Nb -= 1;
          this.chiffre.nativeElement.classList.add('up');
        }
      }
    } else {
      if (event.animationName === 'down2') {
        this.el.nativeElement.style.setProperty('--display', 'none');
        let nextNumber = Number(this.chiffre.nativeElement.innerHTML) - 1;
        if (nextNumber === -1) {
          nextNumber = 9;
          this.Sender.emit({ Id: this.placement, Sens: this.Sens })
        }
        this.CptrNb = nextNumber;
        this.chiffre.nativeElement.innerHTML = nextNumber;
        this.chiffre.nativeElement.classList.remove('down2')
        setTimeout(() => this.chiffre.nativeElement.classList.add('up2'), 10)
      } else {
        this.el.nativeElement.style.setProperty('--display', 'block');
        this.chiffre.nativeElement.classList.remove('up2');
        if (this.Nb > 0) {
          this.Nb -= 1;
          this.chiffre.nativeElement.classList.add('down2');
        }
      }
    }
  }
}

export interface DataCptr {
  Sens: boolean,
  Id: number,
}
