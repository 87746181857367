import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-popin',
  templateUrl: './demo-popin.component.html',
  styleUrls: ['./demo-popin.component.scss']
})

export class DemoPopinComponent implements OnInit {
  constructor() {}

  public concept : boolean = true;

  ngOnInit(): void {
  }

  public help(value: boolean): void {
    this.concept = value;
  }

  adBlockPlus:boolean = false;
  adBlock:boolean = false;
  uBlock:boolean = false;
  ghostery:boolean = false;
  text: boolean = true;

  eventAdBlock(blockerName: string) {
    this.adBlockPlus = (blockerName === 'adBlockPlus');
    this.adBlock = (blockerName === 'adBlock');
    this.uBlock = (blockerName === 'uBlock');
    this.ghostery = (blockerName === 'ghostery');
    this.text = false;
  }

  public hide(): void {
    document.getElementById('widget-demopopin').classList.add('hide')
  }
}
