<div class="box">
  <div class="box-login">
    <p class="login">
      Mot de passe oublié
    </p>
  </div>
  <form>
    <div class="text">
      <label *ngIf="success">Un Email de reinitialisation du mot de passe a été envoyé a cette adresse</label>
      <label *ngIf="error">Une erreur s'est produite veuillez réessayer plus tard</label>
      <input class="text-area" placeholder="Email" type="email" name="email" [(ngModel)]="email"/>
    </div>
  </form>

  <button type="submit" class="continue" (click)="sendReset()">Continuer</button>
</div>
