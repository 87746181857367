import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Tile } from '../types';
import { FullPage } from '../backoffice/models/full-page';

interface SocialShareModalData {
  showModal: boolean;
  tile?: Tile;
  page?: FullPage;
}

@Injectable({
  providedIn: 'root'
})
export class SocialshareService {
  private message = new BehaviorSubject<SocialShareModalData>({ showModal: false, tile: undefined });
  sharedMessage = this.message.asObservable();

  constructor() { }

  nextMessage(message: SocialShareModalData): void {
    this.message.next(message);
  }
}
