<div class="divLogo">
  <img class="logo" src="./../../../assets/img/logo.svg" alt="logo_mediashow" />
</div>
<div class="divButton">
  <button class="btn btn-icon btn-close" (click)="close()">
    Retour
    <img src="../../../assets/img/close_button.svg" alt="close_button">
  </button>
</div>
<div class="divButtonScreen">
  <i *ngIf="!this.gridViewService.checkFullscreen()" (click)="this.gridViewService.openFullscreen()"
  class="fas fa-4x fa-expand"></i>
  <i *ngIf="this.gridViewService.checkFullscreen()" (click)="this.gridViewService.closeFullscreen()"
  class="fas fa-4x fa-compress"></i>
</div>
<div class="details">
  <app-display
    #mainview
    [playVideo]="isOpen"
    [tile]="tile"
    [resumePosition]="resumePosition"
    [resumeAction]="resumeAction"
  ></app-display>
</div>
