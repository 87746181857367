<!-- <div *ngIf="size == 'big'" class="box-big" cdkDrag (click)="changeToLittle()">
  <div class="middlebox">
    <h1 class="mediapass">Media Pass</h1>
    <div class="chiffre">
      <app-chiffre (Sender)="Update($event)" [placement]="0"></app-chiffre>
      <app-chiffre (Sender)="Update($event)" [placement]="1"></app-chiffre>
      <app-chiffre (Sender)="Update($event)" [placement]="2"></app-chiffre>
      <span>&nbsp;&nbsp;</span>
      <app-chiffre (Sender)="Update($event)" [placement]="3"></app-chiffre>
      <app-chiffre (Sender)="Update($event)" [placement]="4"></app-chiffre>
    </div>
    <span class="credit">crédits</span>
    <span class="mediashow">mediashow</span>
  </div>
</div>

<div *ngIf="size == 'little'" class="box-little" cdkDrag (click)="changeToBig()">
  <div class="middlebox">
    <h1 class="mediapass">Media Pass</h1>
    <div class="chiffre">
      <app-chiffre (Sender)="Update($event)" [placement]="0"></app-chiffre>
      <app-chiffre (Sender)="Update($event)" [placement]="1"></app-chiffre>
      <app-chiffre (Sender)="Update($event)" [placement]="2"></app-chiffre>
      <span>&nbsp;&nbsp;</span>
      <app-chiffre (Sender)="Update($event)" [placement]="3"></app-chiffre>
      <app-chiffre (Sender)="Update($event)" [placement]="4"></app-chiffre>
    </div>
    <span class="credit">crédits</span>
    <span class="mediashow">mediashow</span>
  </div>
</div> -->
<div *ngIf="size == 'big'" class="box-big" cdkDrag (click)="changeToLittle()">
  <div class="middlebox">
    <h1 class="mediapass">version</h1>
    <div class="chiffre">
      <p class="mediapass_title_beta">Beta</p>
    </div>
    <!-- <span class="mediashow">mediashow</span> -->
  </div>
</div>

<div *ngIf="size == 'little'" class="box-little" cdkDrag (click)="changeToBig()">
  <div class="middlebox">
    <h1 class="mediapass_petit">version</h1>
    <div class="chiffre">
      <p class="mediapass_title_beta_petit">Beta</p>
    </div>
    <!-- <span class="mediashow">mediashow</span> -->
  </div>
</div>
