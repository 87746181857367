import { Component, OnInit, ViewChildren, QueryList, AfterViewInit, OnDestroy } from '@angular/core';
import { VideoNode } from 'src/app/models/videoview';
import { VideoNodeComponent } from '../videonode/videonode.component';
import { PlaybackContext } from 'src/app/models/playback';
import { VideoviewService } from 'src/app/services/videoview.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-videocontainer',
  templateUrl: './videocontainer.component.html',
  styleUrls: ['./videocontainer.component.scss']
})
export class VideoContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  private MAX_NUMBER_OF_PIP = 3;
  public nodeList: VideoNode[] = [];
  private lastNodeSub: Subscription;
  private closedNodeSub: Subscription;
  PlaybackContext = PlaybackContext;
  @ViewChildren('nodelem') elemList!: QueryList<VideoNodeComponent>;

  constructor(private videoview: VideoviewService) {
    this.lastNodeSub = this.videoview.lastNode.subscribe((last) => {
      // Expériemental
      if (last.tile === undefined) {
        return;
      }
      this.nodeList = this.nodeList.filter((node) => node.tile.customID !== last.tile.customID);

      if (last.playback.context === PlaybackContext.POPIN) {
        console.log(last)
        const oldpopin = this.nodeList.find((node) => node.playback.context === PlaybackContext.POPIN);
        if (oldpopin) {
          this.nodeList = this.nodeList.filter((node) => node.playback.context === PlaybackContext.PIP);
          oldpopin.playback.context = PlaybackContext.PIP;
          this.nodeList.push(oldpopin);
        }
      }

      this.nodeList.push(last);
      this.refreshList();
    });

    this.closedNodeSub = this.videoview.closedNode.subscribe((closed) => {
      // console.log({closed})
      if (closed.playback.context === PlaybackContext.GRID) {
        this.nodeList = this.nodeList.filter((node) => node.tile.customID !== closed.tile.customID);
      } else if (closed.playback.context === PlaybackContext.PIP) {
        const old = this.nodeList.find((node) => node.tile.customID === closed.tile.customID);
        if (old) {
          this.nodeList = this.nodeList.filter((node) => node.tile.customID !== closed.tile.customID);
          old.playback.context = PlaybackContext.PIP;
          this.nodeList.push(old);
          this.elemList.last.mute = true;
        }
      }
    })
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.lastNodeSub) {
      this.lastNodeSub.unsubscribe();
    }
    if (this.closedNodeSub) {
      this.closedNodeSub.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
  }

  private refreshList(): void {
    const pipList = this.nodeList.filter((node) => node.playback.context === PlaybackContext.PIP);
    const overflow = pipList.length - this.MAX_NUMBER_OF_PIP;
    if (overflow > 0) {
      this.closeExpiredPip(this.nodeList, overflow);
      const regularPipList = pipList.slice(overflow);
      const popin = this.nodeList.find((node) => node.playback.context === PlaybackContext.POPIN);
      this.nodeList = regularPipList;
      if (popin) {
        this.nodeList.push(popin);
      }
    }
  }

  private closeExpiredPip(list: VideoNode[], overflow: number): void {
    for (let i = 0; i < overflow; i++) {
      this.videoview.close(list[i]);
    }
  }
}
