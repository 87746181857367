<div id="userMenu" [ngClass]="isScrolling ? 'relative' : 'absolute' ">
  <i class="fas fa-times" id="btnClose" (click)="closeUserMenu();lockScroll()"></i>
  <h2 id="username">{{user?.username}}</h2>
  <p id="email">{{user?.email}}
    <div id="verticalNav">
      <ul>
        <li *ngFor="let link of verticalLinks">
          <a href="{{link.link}}" (click)="link.onclickk">
            <img src="{{link.img}}" alt="{{link.title}}">
            <span>{{link.title}}</span>
          </a>
        </li>
        <li *ngIf="!isAuth" >
          <a (click)="toggleRegister()">
            <img src="./../../../assets/img/userMenu/user.svg" alt="Compte">
            <span>Connection/Inscription</span>
          </a>
        </li>
        <li *ngIf="!isAuth" >
          <a href="https://back-beta1.mediashow.fr/register">
            <img src="./../../../assets/img/userMenu/user.svg" alt="Compte">
            <span>Inscription(Editeur/Annonceurs)</span>
          </a>
        </li>
        <li *ngIf="isAuth" >
          <a (click)="logout()">
            <img src="./../../../assets/img/userMenu/user.svg" alt="Compte">
            <span>Déconnexion</span>
          </a>
        </li>
        <!-- <li (click)="toggleRegister">s'inscrire</li> -->
        <li>
        </li>
      </ul>
    </div>
    <hr>
    <div id="horizontalNav">
      <ul>
        <li *ngFor="let link of horizontalLinks"><a href="{{link.link}}">{{link.title}}</a></li>
      </ul>
    </div>
</div>


