<div class="divLogo">
  <img class="logo" src="./../../../assets/img/logo.svg" alt="logo_mediashow" />
</div>
<div class="divButton">
  <button class="btn btn-icon btn-close" (click)="close()">
    <span>Fermer mon panier</span>
    <img src="../../../assets/img/close_button.svg" alt="close_button">
  </button>
</div>
<div class="divButtonScreen">
  <i *ngIf="!this.gridViewService.checkFullscreen()" (click)="this.gridViewService.openFullscreen()"
        class="fas fa-1x fa-expand fullScreen"></i>
  <i *ngIf="this.gridViewService.checkFullscreen()" (click)="this.gridViewService.closeFullscreen()"
        class="fas fa-1x fa-compress fullScreen"></i>
</div>

<!-- flèche gauche du carousel -->
<i class="fas fa-chevron-left back" (click)="selectItem(NavDirection.PREV)"></i>

<!-- contenu -->
<div *ngFor="let t of tiles" [ngClass]="{'content-block': t === current}">
  <div *ngIf="t === current" class="focus-container">
    <app-display [cart]=true [playVideo]=true [tile]="current" [isCart]="true"
      (isVideoEnded)="selectItem(NavDirection.NEXT)" class="tile"></app-display>
  </div>
</div>

<!-- flèche droite du carousel -->
<i class="fas fa-chevron-right forward" (click)="selectItem(NavDirection.NEXT)"></i>

<!-- carré Sélection -->
<div class="mouseOver" (click)="toggleCart()">
  <p>Ma Sélection</p>
</div>

<app-carousel *ngIf="cartStatus.isOpen" (selectionChange)="selectItem($event)"
  [@openCloseCart]="isOpenCart ? 'openCart' : 'closedCart'">
  <div class="cell" *ngFor="let tile of tiles">
    <!-- <app-tile [ngClass]="{'selected': this.current && this.current.id === tile.id}" [lockSize]="true" [tile]="tile" (click)="select(tile)"></app-tile> -->
    <app-newtile [context]="PlaybackContext.CART"
      [ngClass]="{'selected': this.current && this.current.customID === tile.customID}" [tile]="tile" [lockSize]="120"
      (click)="select(tile)"></app-newtile>
  </div>
</app-carousel>
