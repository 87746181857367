import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-moreinformation',
  templateUrl: './moreinformation.component.html',
  styleUrls: ['./moreinformation.component.scss']
})
export class MoreinformationComponent implements OnInit {
  public concept: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  public help(value: boolean): void {
    this.concept = value;
    sessionStorage.setItem('tutoHowto', String(true));
  }

  adBlockPlus: boolean = false;
  adBlock: boolean = false;
  uBlock: boolean = false;
  ghostery: boolean = false;

  eventAdBlock(blockerName: string) {
    this.adBlockPlus = (blockerName === 'adBlockPlus');
    this.adBlock = (blockerName === 'adBlock');
    this.uBlock = (blockerName === 'uBlock');
    this.ghostery = (blockerName === 'ghostery');
  }

  public hide(): void {
    document.getElementById('widget-moreinformation').classList.add('hide')
    sessionStorage.setItem('TutoOkisDone', String(true));
  }
}
