<div class="modal" [ngClass]="{'is-active': showModal}" *ngIf="url && page">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="modal-card">
      <header class="modal-card-head">
        <button class="delete" aria-label="close" (click)="cancel()"></button>
      </header>
      <section class="modal-card-body">
        <p class="modal-card-title">Partager sur les réseaux sociaux</p>
        <div class="columns is-centered">
          <div class="column is-2 is-flex is-justify-content-center">
            <a target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url={{url}}" id="linkedin">
              <i class="fab fa-linkedin-in"></i>
            </a>
          </div>
          <div class="column is-2 is-flex is-justify-content-center">
            <a target="_blank" href="https://api.whatsapp.com/send/?text={{url}}" id="whatsapp">
              <i class="fab fa-whatsapp"></i>
            </a>
          </div>
          <div class="column is-2 is-flex is-justify-content-center">
            <a target="_blank" href="https://www.facebook.com/sharer.php?u={{url}}" id="facebook">
              <i class="fab fa-facebook-f"></i>
            </a>
          </div>
          <div class="column is-2 is-flex is-justify-content-center">
            <a target="_blank" href="https://twitter.com/share?url={{url}}&text={{shareText}}" id="twitter">
              <i class="fab fa-twitter"></i>
            </a>
          </div>
        </div>
        <div class="container">
          <div class="pWidget">
            <p>Ou copier le lien du site juste en dessous</p>
          </div>
          <div [ngClass]="copied === true ? 'copy-text copied-text' : 'copy-text'" (click)="copyToClipboard()">
            <input type="text" class="text" id="copyText"
              value="{{urlToShare}}" />
            <button><i class="fa fa-clone"></i></button>
          </div>
        </div>
        <div class="logoMS">
          <img src="../../../assets/img/logo.svg">
        </div>
        <script>
          let copyText = document.querySelector(".copy-text");
          copyText.querySelector("button").addEventListener("click", function () {
            let input = copyText.querySelector("input.text");
            input.select();
            document.execCommand("copy");
            copyText.classList.add("active");
            window.getSelection().removeAllRanges();
            setTimeout(function () {
              copyText.classList.remove("active");
            }, 2500);
          });
        </script>
      </section>
    </div>
  </div>
</div>
