import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { CompteurService } from 'src/app/services/compteur.service';
import { ChiffreComponent, DataCptr } from '../chiffre/chiffre.component';

@Component({
  selector: 'app-cptr',
  templateUrl: './cptr.component.html',
  styleUrls: ['./cptr.component.scss']
})
export class CptrComponent implements AfterViewInit {
  size='big';

  constructor(private el: ElementRef, private cptrService: CompteurService) {
  }

  @ViewChildren(ChiffreComponent) ChiffresManager!: QueryList<ChiffreComponent>
  ChiffreManagerList: Array<ChiffreComponent> = []

  ngAfterViewInit(): void {
    this.ChiffreManagerList = this.ChiffresManager.toArray()
    this.SetTimeRotation(0.1)
    this.cptrService.MediaPassObservable.subscribe(data => {
      this.NewVal(data)
    })
  }

  Update(event: DataCptr) {
    if (event.Id < this.ChiffreManagerList.length - 1) {
      if (event.Sens) {
        this.ChiffreManagerList[event.Id + 1].Init(1)
      } else {
        this.ChiffreManagerList[event.Id + 1].Init(1, false)
      }
    }
  }

  DispCptr() {
    let total: string = '';
    this.ChiffreManagerList.map(x => total = x.CptrNb + total)
    return Number(total)
  }

  SetTimeRotation(value: number) {
    this.ChiffreManagerList.map(x => x.SetTimeRotation(value))
  }

  NewVal(value: number) {
    const tamp: number = value - this.DispCptr()
    if (tamp > 0) {
      this.ChiffreManagerList[0].Init(tamp)
    } else {
      this.ChiffreManagerList[0].Init(Math.abs(tamp), false)
    }
  }

  changeToLittle() {
    this.size = 'little';
  }

  changeToBig() {
    this.size = 'big';
  }
}
